import { gql } from '@apollo/client';

export const QUERY_CONVERSATIONS = gql`
  query QUERY_CONVERSATIONS($address: String!, $tags: [TagFilter!], $first: Int, $after: String) {
    transactions(tags: $tags, owners: [$address], sort: HEIGHT_DESC, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          owner { address }
          tags { name value }
        }
      }
      pageInfo { hasNextPage }
    }
  }
`;

export const QUERY_CONVERSATIONS_EO = gql`
  query QUERY_CONVERSATIONS_EO($ids: [ID!], $address: String!, $tags: [TagFilter!], $first: Int, $after: String) {
    transactions(ids: $ids, tags: $tags, owners: [$address], sort: HEIGHT_DESC, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          owner { address }
          tags { name value }
        }
      }
      pageInfo { hasNextPage }
    }
  }
`;

export const GET_LATEST_MODEL_ATTACHMENTS = gql`
  query GET_MODEL_ATTACHMENTS($tags: [TagFilter!]) {
    transactions(first: 100, tags: $tags, sort: HEIGHT_DESC) {
      edges {
        node {
          id
          tags { name value }
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS_FROM_IDS = gql`
  query GET_TRANSACTIONS_FROM_IDS($ids: [ID!], $first: Int!) {
    transactions(ids: $ids, first: $first) {
      edges {
        cursor
        node {
          id
          owner { address }
          tags { name value }
        }
      }
      pageInfo { hasNextPage }
    }
  }
`;
