import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pushIfUnique, spliceIfExists } from 'utils/arrays';
import { findTag } from 'utils/tags';

export interface FairState {
  // -- ById --
  modelsById: { [key: TxId]: TxNode };
  scriptsById: { [key: TxId]: TxNode };
  operatorsById: { [key: TxId]: TxNode };
  conversationsById: { [key: TxId]: TxNode };
  promptsById: { [key: TxId]: TxNode };
  responsesById: { [key: TxId]: TxNode };
  // -- ByQuery ---
  promptsByQuery: {
    ids: { [queryKey: string]: TxId[] };
    cursor: { [queryKey: string]: string | null | undefined }; // null = no more pages
  };
  // -- "Selector Cache" --
  scriptsForModelId: { [modelId: TxId]: TxNode[] };
  operatorsForScriptId: { [scriptId: TxId]: TxNode[] };
  // -- Status --
  fetching: {
    models?: boolean;
    scripts?: boolean;
    operators?: boolean;
    conversations?: boolean;
    sendingPrompt?: boolean;
  };
  // --- Misc ---
  pendingPromptIds: TxId[];
}

const initialState: FairState = {
  modelsById: {},
  scriptsById: {},
  operatorsById: {},
  conversationsById: {},
  promptsById: {},
  responsesById: {},
  scriptsForModelId: {},
  operatorsForScriptId: {},
  promptsByQuery: { ids: {}, cursor: {} },
  fetching: {},
  pendingPromptIds: [],
};

const fairSlice = createSlice({
  name: 'fair',
  initialState: initialState,
  reducers: {
    clearFairCache: (state) => {
      // Clear states that we are persisting:
      state.modelsById = {};
      state.scriptsById = {};
      state.operatorsById = {};
      state.scriptsForModelId = {};
      state.operatorsForScriptId = {};
    },
    clearWalletTiedData: (state) => {
      state.conversationsById = {};
      state.promptsById = {};
      state.responsesById = {};
    },
    fetchStarted: (state, action: PayloadAction<keyof FairState['fetching']>) => {
      state.fetching[action.payload] = true;
    },
    fetchDone: (state, action: PayloadAction<keyof FairState['fetching']>) => {
      state.fetching[action.payload] = false;
    },
    modelsFetched: (state, action: PayloadAction<TxEdge[]>) => {
      state.fetching.models = false;
      action.payload.forEach((edge) => (state.modelsById[edge.node.id] = edge.node));
    },
    scriptsFetched: (state, action: PayloadAction<{ scripts: TxEdge[]; modelId: TxId }>) => {
      state.fetching.scripts = false;
      state.scriptsForModelId[action.payload.modelId] = [];
      action.payload.scripts.forEach((s) => {
        state.scriptsById[s.node.id] = s.node;
        state.scriptsForModelId[action.payload.modelId].push(s.node);
      });
    },
    operatorsFetched: (state, action: PayloadAction<{ operators: TxEdge[]; scriptId: TxId }>) => {
      state.fetching.operators = false;
      state.operatorsForScriptId[action.payload.scriptId] = [];
      action.payload.operators.forEach((o) => {
        state.operatorsById[o.node.id] = o.node;
        state.operatorsForScriptId[action.payload.scriptId].push(o.node);
      });
    },
    conversationsFetched: (state, action: PayloadAction<TxEdge[] | null>) => {
      state.fetching.conversations = false;
      action.payload?.forEach((edge) => (state.conversationsById[edge.node.id] = edge.node));
    },
    promptsFetched: (state, action: PayloadAction<TxEdge[]>) => {
      action.payload.forEach((edge) => {
        state.promptsById[edge.node.id] = edge.node;
        if (findTag(edge.node.tags, 'Incomplete')) {
          pushIfUnique(state.pendingPromptIds, edge.node.id);
        } else {
          spliceIfExists(state.pendingPromptIds, edge.node.id);
        }
      });
    },
    responsesFetched: (state, action: PayloadAction<TxEdge[]>) => {
      action.payload.forEach((edge) => (state.responsesById[edge.node.id] = edge.node));
    },
    updatePromptQuery: (
      state,
      action: PayloadAction<{
        queryKey: string;
        ids: TxId[];
        cursor?: string | null;
        op?: 'appendFront' | 'appendBack';
      }>
    ) => {
      const { queryKey, ids, cursor, op = 'appendBack' } = action.payload;
      const existingIds = state.promptsByQuery.ids[queryKey] || [];
      // Update results
      state.promptsByQuery.ids[queryKey] = op === 'appendFront' ? ids.concat(existingIds) : existingIds.concat(ids);
      // Update cursor (if requested)
      cursor !== undefined && (state.promptsByQuery.cursor[queryKey] = cursor);
    },
    clearPromptQuery: (state, action: PayloadAction<string>) => {
      delete state.promptsByQuery.ids[action.payload];
      delete state.promptsByQuery.cursor[action.payload];
    },
  },
});

export default fairSlice;
