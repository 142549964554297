import React from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import Comments from 'modals/comments';
import { getMockUsers } from 'constants/mockdata';
import './style.scss';

import { ReactComponent as ImgHeart } from 'assets/icons/heart.svg';
import { ReactComponent as ImgComments } from 'assets/icons/comments.svg';

const users = getMockUsers();

const PostActions = React.memo((props: any) => {
  const { isVisible, isRemixing } = props;
  const dispatch = useAppDispatch();

  // Mock data
  const user = users[Math.floor(Math.random() * 4)];
  const hasOriginal = Math.random() < 0.5;
  const original = Math.floor(Math.random() * 5 + 1);
  const sourceUser = users[Math.floor(Math.random() * 4)];
  const numberOfHearts = Math.floor(Math.random() * 14 + 2);

  function openCommentsModal() {
    dispatch(openModal(Comments, {}));
  }

  return (
    <div className={`post__actions ${isRemixing ? 'post__actions--remixing' : ''}`}>
      <div className="post__action">
        <img className="post__action__avatar" src={user.avatar} />
      </div>
      <div className="post__action">
        <ImgHeart />
        {isVisible && (
          <div className="hearts">
            {Array.from({ length: numberOfHearts }, (_, index) => (
              <ImgHeart className="heart" key={index} />
            ))}
          </div>
        )}
      </div>
      <div className="post__action" onClick={openCommentsModal}>
        <ImgComments />
      </div>
      {hasOriginal && (
        <>
          <div className="post__action__spacer" />
          <Link to={`/post/${original}`}>
            <div className="post__action">
              <img
                className="post__action__original"
                src={`https://github.com/NewEstablishment/eo-resources/blob/main/discover/${original}.jpeg?raw=true`}
              />
              <img className="post__action__source" src={sourceUser.avatar} />
            </div>
          </Link>
        </>
      )}
    </div>
  );
});

export default PostActions;
