/**
 * Fair SDK instance and helpers.
 */

import FairSDKWeb_Original from '@fair-protocol/sdk/web';
import { createWarpInstance } from 'lib/warp';
import { Warp } from 'warp-contracts';

/**
 * Extension of the FairSDKWeb class.
 * @see https://github.com/FAIR-Protocol/fair-protocol-sdk/blob/main/src/web/index.ts
 */
abstract class FairSDKWeb extends FairSDKWeb_Original {
  public static warp: Warp | undefined;

  public static initFair(gatewayId: GatewayId, arweave: any, warpEnv: WarpEnv) {
    this.init(arweave);
    this.warp = createWarpInstance(gatewayId, arweave, warpEnv);
  }
}

export { FairSDKWeb };
