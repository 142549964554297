export function throwIf(condition: boolean, message: string, cause?: any) {
  if (condition) {
    const error: any = new Error(message, { cause });
    const stackFrames = error.stack.split('\n');
    stackFrames.splice(1, 1); // Remove the current stack frame (throwIf)
    error.stack = stackFrames.join('\n');
    throw error;
  }
}

export function suppressError() {
  // Do nothing
}

export function suppressToConsole(error: any) {
  console.error(error);
}

export function sendAlert(error: any) {
  const msg = error.response?.data || error.message;
  console.error(msg);
  alert(msg);
}
