import { useState } from 'react';
import './style.scss';

const StyleOption = (props: any) => {
  const { title, active } = props;
  const [isActive, setIsActive] = useState(active);

  return (
    <div
      className={`postStyles__option ${isActive ? 'postStyles__option--active' : ''}`}
      style={{
        backgroundImage: `url('https://github.com/NewEstablishment/eo-resources/blob/main/styles/${title.toLowerCase()}.jpeg?raw=true')`,
      }}
      onClick={() => setIsActive(!isActive)}
    >
      <span>{title}</span>
    </div>
  );
};

export default function PostStyles(props: any) {
  return (
    <div className="postStyles">
      <div className="postStyles__title">Choose a style</div>
      <div className="postStyles__options">
        <StyleOption title="Cyberpunk" />
        <StyleOption title="CGI" />
        <StyleOption title="Voxel" />
        <StyleOption title="Pixel Art" />
        <StyleOption title="Photo-Realism" />
        <StyleOption title="Anime" />
        <StyleOption title="Abstract" />
        <StyleOption title="Cartoon" />
        <StyleOption title="Painting" />
      </div>
    </div>
  );
}
