import { EO_WALLET_ADDR } from 'constants/wallets';
import { apolloClient } from 'lib/apollo';
import { dispatchArweaveTx, getMainArweaveInstance } from 'lib/arweave';
import { FairSDKWeb } from 'lib/fair';
import { doWalletBalance } from 'store/arweave/thunks';
import {
  GET_LATEST_MODEL_ATTACHMENTS,
  GET_TRANSACTIONS_FROM_IDS,
  QUERY_CONVERSATIONS,
  QUERY_CONVERSATIONS_EO,
} from 'store/fair/graphql';
import { generatePromptQueryKey, selectConversations, selectPromptsWithNoResponse } from 'store/fair/selectors';
import fairSlice from 'store/fair/slice';
import promptSlice from 'store/prompt/slice';
import { getCurrentTimeMs } from 'utils/dateTime';
import { throwIf } from 'utils/error';
import { balanceStrToNum } from 'utils/number';
import { findTag, getEOTags } from 'utils/tags';
import { GATEWAYS } from 'constants/arweave';
import { earthof } from 'services/earthof';

const FU = FairSDKWeb.utils;
const FUT = FairSDKWeb.utils.TAG_NAMES;

export function doFetchModels() {
  // [] Handle pagination. Right now, we just fetch everything with a large
  //    pageSize. The hassle is that each page contains duplicates
  //    (same model, older version).
  const pageSize = 20;

  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();

    if (state.fair.fetching.models) {
      return;
    }

    try {
      dispatch(fairSlice.actions.fetchStarted('models'));

      if (Object.values(state.fair.modelsById).length > 0) {
        // [] For Web, F5 can purge the cache on demand, but how about Device?
        dispatch(fairSlice.actions.fetchDone('models'));
        return;
      }

      const payload = FairSDKWeb.utils.getModelsQuery(pageSize);
      const query = await apolloClient.query({ query: payload.query, variables: payload.variables });
      throwIf(Boolean(query.error), `doFetchModels: ${query?.error?.message}`);

      if (query.data) {
        const deDuped = await FairSDKWeb.utils.modelsFilter(query.data.transactions.edges);

        // Fetch avatars (might as well do it now while we can batch)
        const avatars = await apolloClient.query({
          query: GET_LATEST_MODEL_ATTACHMENTS,
          variables: {
            tags: [
              { name: FUT.operationName, values: [FU.MODEL_ATTACHMENT] },
              { name: FUT.attachmentRole, values: [FU.AVATAR_ATTACHMENT] },
              { name: FUT.modelTransaction, values: deDuped.map((m) => findTag(m.node.tags, 'Model-Transaction')) },
            ],
          },
        });

        // Inject avatar TxIds into the model objects that we are going to store
        if (avatars.data) {
          avatars.data.transactions.edges.forEach((edge: TxEdge) => {
            const modelTxForAvatar = findTag(edge.node.tags, 'Model-Transaction');
            const modelIndex = deDuped.findIndex((m) => findTag(m.node.tags, 'Model-Transaction') === modelTxForAvatar);
            if (modelIndex > -1) {
              if (deDuped[modelIndex].node?.tags) {
                const model = deDuped[modelIndex];
                const extraTag = { __typename: 'Redux', name: 'Model-Avatar', value: edge.node.id };
                deDuped[modelIndex] = { ...model, node: { ...model.node, tags: [...model.node.tags, extraTag] } }; // apollo data is immutable
              }
            }
          });
        }

        // Store
        dispatch(fairSlice.actions.modelsFetched(deDuped));
      }
    } catch (err) {
      dispatch(fairSlice.actions.fetchDone('models'));
      console.log(err);
      throw err;
    }
  };
}

/**
 * Fetches all Scripts for the currently-selected Model.
 */
export function doFetchScripts() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const modelId = state.prompt.modelId;
    const model = state.fair.modelsById[modelId || ''];

    try {
      dispatch(fairSlice.actions.fetchStarted('scripts'));
      throwIf(!model, 'Invalid model');

      if (state.fair.scriptsForModelId[modelId!] !== undefined) {
        // [] For Web, F5 can purge the cache on demand, but how about Device?
        dispatch(fairSlice.actions.fetchDone('scripts'));
        return;
      }

      const payload = FairSDKWeb.utils.getScriptQueryForModel(
        findTag(model?.tags, 'Model-Transaction') || '',
        findTag(model?.tags, 'Model-Name'),
        findTag(model?.tags, 'Sequencer-Owner')
      );

      const query = await apolloClient.query({ query: payload.query, variables: payload.variables });

      throwIf(Boolean(query.error), `doFetchScripts: ${query?.error?.message}`);

      if (query.data) {
        const deDuped = await FairSDKWeb.utils.scriptsFilter(query.data.transactions.edges);
        dispatch(fairSlice.actions.scriptsFetched({ scripts: deDuped, modelId: modelId! }));
        dispatch(pickScriptIfUnique(deDuped));
      }
    } catch (err) {
      dispatch(fairSlice.actions.fetchDone('scripts'));
      console.log(err);
      throw err;
    }
  };

  function pickScriptIfUnique(scripts: TxEdge[]) {
    return (dispatch: AppDispatch, getState: GetState) => {
      const state = getState();
      const modelId = state.prompt.modelId;
      const scriptId = state.prompt.scriptId;

      if (modelId && scriptId === undefined && scripts.length === 1) {
        dispatch(promptSlice.actions.scriptSelected(scripts[0].node.id));
      }
    };
  }
}

/**
 * Fetches all Operators for the currently-selected Script.
 */
export function doFetchOperators() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const scriptId = state.prompt.scriptId;
    const script = state.fair.scriptsById[scriptId || ''];

    try {
      dispatch(fairSlice.actions.fetchStarted('operators'));

      throwIf(!script, 'Invalid script');

      if (state.fair.operatorsForScriptId[scriptId!] !== undefined) {
        dispatch(fairSlice.actions.fetchDone('operators'));
        return;
      }

      const payload = FairSDKWeb.utils.getOperatorQueryForScript(
        findTag(script.tags, 'Script-Transaction') || '',
        findTag(script.tags, 'Script-Name'),
        findTag(script.tags, 'Sequencer-Owner')
      );

      const query = await apolloClient.query({
        query: payload.query,
        variables: payload.variables,
        fetchPolicy: 'no-cache',
      });

      throwIf(Boolean(query.error), `doFetchOperators: ${query?.error?.message}`);

      if (query.data) {
        const deDuped = await FairSDKWeb.utils.operatorsFilter(query.data.transactions.edges);
        const sorted = sortOperators(deDuped);
        dispatch(fairSlice.actions.operatorsFetched({ operators: sorted, scriptId: scriptId! }));
        dispatch(pickOperatorIfUnique(sorted));
      }
    } catch (err) {
      dispatch(fairSlice.actions.fetchDone('operators'));
      console.log(err);
      throw err;
    }
  };

  function sortOperators(operators: TxEdge[]) {
    return operators.slice().sort((a, b) => {
      const aFee = findTag(a.node.tags, 'Operator-Fee') || '0';
      const bFee = findTag(b.node.tags, 'Operator-Fee') || '0';
      return parseInt(aFee) - parseInt(bFee);
    });
  }

  function pickOperatorIfUnique(operators: TxEdge[]) {
    return (dispatch: AppDispatch, getState: GetState) => {
      const state = getState();
      const modelId = state.prompt.modelId;
      const scriptId = state.prompt.scriptId;
      const operatorId = state.prompt.operatorId;

      if (modelId && scriptId && operatorId === undefined && operators.length === 1) {
        dispatch(promptSlice.actions.operatorSelected(operators[0].node.id));
      }
    };
  }
}

/**
 * Fetches Conversation meta for the currently-selected Script.
 */
export function doFetchConversations() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(fairSlice.actions.fetchStarted('conversations'));

    try {
      const state = getState();
      const walletAddress = state.arweave.walletAddress;
      const script = state.fair.scriptsById[state.prompt.scriptId || ''];
      const scriptTags = script?.tags;

      throwIf(!walletAddress, 'Wallet not set');
      throwIf(!script, 'Script not selected');

      const query = await apolloClient.query({
        query: QUERY_CONVERSATIONS,
        variables: {
          address: walletAddress,
          tags: [
            ...FU.DEFAULT_TAGS,
            { name: FUT.operationName, values: [FU.CONVERSATION_START] },
            { name: FUT.scriptTransaction, values: [findTag(scriptTags, 'Script-Transaction')] },
            { name: FUT.scriptName, values: [findTag(scriptTags, 'Script-Name')] },
            { name: FUT.scriptCurator, values: [findTag(scriptTags, 'Sequencer-Owner')] },
          ],
        },
        fetchPolicy: 'no-cache',
      });

      throwIf(Boolean(query.error), `Fetch conversations: ${query?.error?.message}`);

      dispatch(fairSlice.actions.conversationsFetched(query.data.transactions.edges));
      dispatch(pickConversationIfUnique(query.data.transactions.edges));
    } catch (err) {
      dispatch(fairSlice.actions.fetchDone('conversations'));
      console.log(err);
      throw err;
    }
  };

  function pickConversationIfUnique(conversations: TxEdge[]) {
    return (dispatch: AppDispatch, getState: GetState) => {
      const state = getState();
      const modelId = state.prompt.modelId;
      const scriptId = state.prompt.scriptId;
      const operatorId = state.prompt.operatorId;
      const conversationId = state.prompt.conversationId;

      if (modelId && scriptId && operatorId && conversationId === undefined && conversations.length === 1) {
        dispatch(promptSlice.actions.conversationSelected(conversations[0].node.id));
      }
    };
  }
}

const eo_cache: { [walletAddress: string]: any } = {};

/**
 * Fetches all Prompts for the currently-selected Conversation.
 */
export function doFetchPrompts(pageSize: number = 3) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const backend = state.settings.backend;
    const walletAddress = state.arweave.walletAddress;
    const scriptId = state.prompt.scriptId;
    const conversationId = backend.startsWith('earthof') ? 'eo' : state.prompt.conversationId;

    throwIf(!walletAddress, 'Wallet not connected');
    throwIf(!scriptId, 'Script not selected');
    throwIf(!conversationId, 'Conversation not selected');

    const queryKey = generatePromptQueryKey(backend, walletAddress!, scriptId!, conversationId!);

    // prettier-ignore
    const query = backend === 'fair-sdk'
      ? await dispatch(viaFairSDK())
      : await dispatch(viaEarthOf());

    throwIf(Boolean(query.error), `doFetchPrompts: ${query?.error?.message}`);

    const edges = query.data?.transactions.edges || [];
    const newEdges = [];

    // Fetch the data from the gateway, and inject it as the 'Prompt' tag
    // for easier access in the UI.
    if (query.data) {
      const promptsById = state.fair.promptsById;
      const gatewayId = state.arweave.gatewayId;
      const gateway = GATEWAYS[gatewayId || ''];

      for (let i = 0; i < edges.length; ++i) {
        const edge = edges[i];

        const existing = promptsById[edge.node.id];
        const promptFetched: boolean = Boolean(findTag(existing?.tags, 'Prompt'));
        const isPartial: boolean = Boolean(findTag(existing?.tags, 'Incomplete'));

        if (Boolean(existing) && promptFetched && !isPartial) {
          continue;
        }

        try {
          const response = await fetch(`${gateway.protocol}://${gateway.host}:${gateway.port}/${edge.node.id}`);
          const extraTags = [];

          if (response.status === 404) {
            const text = promptFetched ? findTag(existing.tags, 'Prompt') : '<pending>'; // use partial data if exists
            extraTags.push({ __typename: 'Redux', name: 'Prompt', value: text });
            extraTags.push({ __typename: 'Redux', name: 'Incomplete', value: 'true' });
          } else {
            extraTags.push({ __typename: 'Redux', name: 'Prompt', value: await response.text() });
          }

          // apollo data is immutable, hence the spread.
          newEdges.push({ ...edge, node: { ...edge.node, tags: [...edge.node.tags, ...extraTags] } });
        } catch (err: any) {
          console.log('doFetchPrompts:', err.message);
        }
      }
    }

    if (newEdges.length > 0) {
      dispatch(fairSlice.actions.promptsFetched(newEdges));
    }

    dispatch(
      fairSlice.actions.updatePromptQuery({
        queryKey,
        ids: edges.map((e: TxEdge) => e.node.id),
        cursor: query.data?.transactions.pageInfo?.hasNextPage ? edges[edges.length - 1].cursor : null,
      })
    );

    return queryKey;
  };

  function viaFairSDK() {
    return async (dispatch: AppDispatch, getState: GetState): Promise<any> => {
      const state = getState();
      const backend = state.settings.backend;
      const walletAddress = state.arweave.walletAddress;
      const scriptId = state.prompt.scriptId;
      const script = state.fair.scriptsById[scriptId || ''];
      const conversationId = state.prompt.conversationId;

      const queryKey = generatePromptQueryKey(backend, walletAddress!, scriptId!, conversationId!);

      throwIf(!conversationId, 'Conversation not selected');
      throwIf(!script || !script.tags, 'Invalid script');

      const conversation = state.fair.conversationsById[conversationId || ''];
      const conversationIdentifier = findTag(conversation?.tags, 'Conversation-Identifier');

      return apolloClient.query({
        query: QUERY_CONVERSATIONS,
        variables: {
          address: walletAddress,
          first: pageSize,
          after: state.fair.promptsByQuery.cursor[queryKey],
          tags: [
            ...FU.DEFAULT_TAGS,
            { name: FUT.operationName, values: [FU.SCRIPT_INFERENCE_REQUEST] },
            { name: FUT.contentType, values: ['text/plain'] },
            { name: FUT.conversationIdentifier, values: [conversationIdentifier] },
            { name: FUT.scriptTransaction, values: [findTag(script.tags, 'Script-Transaction')] },
            { name: FUT.scriptName, values: [findTag(script.tags, 'Script-Name')] },
            { name: FUT.scriptCurator, values: [findTag(script.tags, 'Sequencer-Owner')] },
          ],
        },
        fetchPolicy: 'no-cache',
      });
    };
  }

  function viaEarthOf() {
    return async (dispatch: AppDispatch, getState: GetState) => {
      const state = getState();
      const backend = state.settings.backend;
      const walletAddress = state.arweave.walletAddress;
      const scriptId = state.prompt.scriptId;
      const script = state.fair.scriptsById[scriptId || ''];
      const conversationId = 'eo';

      const queryKey = generatePromptQueryKey(backend, walletAddress!, scriptId!, conversationId!);

      let ids, queryName;

      switch (backend) {
        case 'earthof_v0.0':
          const cursor = state.fair.promptsByQuery.cursor[queryKey];
          const response = await readEarthOfAll(cursor, walletAddress!);
          const results: AllResponse[] = response.data;
          ids = results.map((r) => r.RequestID);
          queryName = QUERY_CONVERSATIONS_EO;
          break;
        case 'earthof_v0.1':
        case 'earthof_v0.2':
          ids = undefined; // Nothing to do, entirely graphQL
          queryName = QUERY_CONVERSATIONS_EO;
          break;
        case 'fair-sdk':
          throw new Error('Does not handle FairSDK');
        default:
          throw new Error(`Unhandled version: ${backend}`);
      }

      return apolloClient.query({
        query: queryName,
        variables: {
          ids: ids,
          address: EO_WALLET_ADDR,
          first: pageSize,
          after: state.fair.promptsByQuery.cursor[queryKey],
          tags: [
            ...FU.DEFAULT_TAGS,
            { name: FUT.operationName, values: [FU.SCRIPT_INFERENCE_REQUEST] },
            { name: FUT.contentType, values: ['text/plain'] },
            { name: FUT.scriptTransaction, values: [findTag(script.tags, 'Script-Transaction')] },
            { name: FUT.scriptName, values: [findTag(script.tags, 'Script-Name')] },
            { name: FUT.scriptCurator, values: [findTag(script.tags, 'Sequencer-Owner')] },
            { name: 'User-Custom-Tags', values: JSON.stringify(getEOTags(backend, walletAddress!)) },
          ],
        },
        fetchPolicy: 'no-cache',
      });
    };

    async function readEarthOfAll(cursor: any, walletAddress: string) {
      if (cursor && eo_cache[walletAddress]) {
        return eo_cache[walletAddress];
      } else {
        const response = await earthof.all(walletAddress);
        eo_cache[walletAddress] = response;
        return response;
      }
    }
  }
}

/**
 * Fetches Prompts for the given IDs.
 */
export function doFetchPromptsForId(promptIds: TxId[]) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();

    const query = await apolloClient.query({
      query: GET_TRANSACTIONS_FROM_IDS,
      variables: { ids: promptIds, first: promptIds.length },
      fetchPolicy: 'no-cache',
    });

    console.assert(promptIds.length < 100, 'GQL limit is 100; I think pagination is required');
    throwIf(Boolean(query.error), `doFetchPrompts: ${query?.error?.message}`);

    const edges = query.data?.transactions.edges || [];
    const newEdges = [];

    // Fetch the text from the gateway, and inject it as the 'Prompt' tag for easier access in the UI.
    if (query.data) {
      for (let i = 0; i < edges.length; ++i) {
        const edge = edges[i];

        const existingPrompt = state.fair.promptsById[edge.node.id];
        const textFetched = Boolean(findTag(existingPrompt?.tags, 'Prompt'));
        const isPartialObject = Boolean(findTag(existingPrompt?.tags, 'Incomplete'));

        if (Boolean(existingPrompt) && textFetched && !isPartialObject) {
          continue;
        }

        try {
          const gateway = GATEWAYS[state.arweave.gatewayId || ''];
          const response = await fetch(`${gateway.protocol}://${gateway.host}:${gateway.port}/${edge.node.id}`);
          const extraTags = [];

          if (response.status === 404) {
            const text = textFetched ? findTag(existingPrompt.tags, 'Prompt') : '<pending>'; // use partial data if exists
            extraTags.push({ __typename: 'Redux', name: 'Prompt', value: text });
            extraTags.push({ __typename: 'Redux', name: 'Incomplete', value: 'true' });
          } else {
            extraTags.push({ __typename: 'Redux', name: 'Prompt', value: await response.text() });
          }

          // apollo data is immutable, hence the spread.
          newEdges.push({ ...edge, node: { ...edge.node, tags: [...edge.node.tags, ...extraTags] } });
        } catch (err: any) {
          console.log('doFetchPrompts:', err.message);
        }
      }
    }

    if (newEdges.length > 0) {
      dispatch(fairSlice.actions.promptsFetched(newEdges));
    }
  };
}

/**
 * Fetches Responses for all Prompts in the current Conversation, unless
 * specific promptIds are specified.
 */
export function doFetchPromptResponses(promptIds?: TxId[]) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const script = state.fair.scriptsById[state.prompt.scriptId || ''];
    const conversationId = state.prompt.conversationId;
    const responsesById = state.fair.responsesById;
    const backend = state.settings.backend;

    throwIf(backend === 'fair-sdk' && !conversationId, 'Conversation not selected');

    const conversation = state.fair.conversationsById[conversationId || ''];
    const conversationIdentifier = findTag(conversation?.tags, 'Conversation-Identifier');

    const payload = FairSDKWeb.utils.getResponsesQuery(
      promptIds || selectPromptsWithNoResponse(state).map((p) => p.id),
      undefined, // backend === 'earthof' ? EO_WALLET_ADDR : walletAddress || undefined,
      findTag(script?.tags, 'Script-Name'),
      findTag(script?.tags, 'Sequencer-Owner'),
      undefined,
      Number(conversationIdentifier),
      200 // [] restore to 20 when pagination is implemented
    );

    const query = await apolloClient.query({
      query: payload.query,
      variables: payload.variables,
      fetchPolicy: 'no-cache',
    });

    const newEdges = query.data?.transactions.edges.filter((edge: TxEdge) => !responsesById[edge.node.id]) || [];
    if (newEdges.length > 0) {
      dispatch(fairSlice.actions.responsesFetched(newEdges));
    }

    throwIf(Boolean(query.error), `Conversation "${conversationId}": ${query?.error?.message}`);
  };
}

export function doCreateConversation() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();

    const walletAddress = state.arweave.walletAddress;
    const scriptId = state.prompt.scriptId;
    const script = state.fair.scriptsById[scriptId || ''];

    throwIf(!walletAddress, 'Wallet not connected');
    throwIf(!scriptId, 'No script  selected');
    throwIf(!script, `Script not found: ${scriptId}`);

    const scriptName = findTag(script.tags, 'Script-Name') || '';
    const scriptCurator = findTag(script.tags, 'Sequencer-Owner') || '';
    const scriptTransaction = findTag(script.tags, 'Script-Transaction') || '';
    throwIf(!scriptName || !scriptCurator || !scriptTransaction, `Invalid SDK input (missing tags): ${scriptId}`);

    // [??] The Fair Site (and maybe the SDK too?) assumes an incremental
    // Conversation-Identifier. I think we should use a UUID instead.
    // For now, just replicate the "incremental" logic with a simple count.
    const conversations = selectConversations(state);
    const conversationCount = conversations.length;

    const tags = [
      { name: FUT.protocolName, value: FU.PROTOCOL_NAME },
      { name: FUT.protocolVersion, value: FU.PROTOCOL_VERSION },
      { name: FUT.operationName, value: FU.CONVERSATION_START },
      { name: FUT.scriptName, value: scriptName },
      { name: FUT.scriptCurator, value: scriptCurator },
      { name: FUT.scriptTransaction, value: scriptTransaction },
      { name: FUT.unixTime, value: getCurrentTimeMs().toString() },
      { name: FUT.conversationIdentifier, value: `${conversationCount + 1}` },
    ];

    const arweave = getMainArweaveInstance();
    const tx = await arweave.createTransaction({ data: FU.CONVERSATION_START });
    tags.forEach((tag) => tx.addTag(tag.name, tag.value));
    const txid = await dispatchArweaveTx(tx, arweave, state);

    // -- Store conversation for immediate feedback
    // @ts-ignore - partial data
    const newEdge: TxEdge = { node: { id: txid, tags: tags, owner: { address: walletAddress! } } };

    dispatch(fairSlice.actions.fetchStarted('conversations'));
    dispatch(fairSlice.actions.conversationsFetched([newEdge]));
    dispatch(promptSlice.actions.conversationSelected(txid));
  };
}

export function doSendTextPrompt() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const backend = state.settings.backend;
    return dispatch(backend === 'fair-sdk' ? viaFairSDK() : viaEarthOf());
  };

  function viaFairSDK() {
    return async (dispatch: AppDispatch, getState: GetState) => {
      try {
        dispatch(fairSlice.actions.fetchStarted('sendingPrompt'));

        await dispatch(doWalletBalance());

        const state = getState();
        const text = state.prompt.text;
        const script = state.fair.scriptsById[state.prompt.scriptId || ''];
        const operator = state.fair.operatorsById[state.prompt.operatorId || ''];
        const conversation = state.fair.conversationsById[state.prompt.conversationId || ''];
        const walletAddress = state.arweave.walletAddress;
        const uBalance: number = balanceStrToNum(state.arweave.walletBalance.u);
        const contentType = 'text/plain';
        // [] Should be a state. Hardcoded for now:
        const configuration = {
          nImages: state.prompt.nImages,
          generateAssets: 'fair-protocol',
        };

        throwIf(!text, 'Empty prompt');
        throwIf(!script, 'Invalid script');
        throwIf(!operator || !operator.tags, 'Invalid operator');
        throwIf(!walletAddress, 'Wallet not set');
        throwIf(!conversation || !conversation.tags, 'Invalid conversation');
        throwIf(!FairSDKWeb.warp, 'Warp instance for Fair not initialized');

        const operatorWalletAddress = findTag(operator.tags, 'Sequencer-Owner')!;
        const operatorFee = findTag(operator.tags, 'Operator-Fee');
        const operatorFeeNum: number = balanceStrToNum(operatorFee) / 1000000;
        const conversationIdentifier = findTag(conversation.tags, 'Conversation-Identifier')!;
        const isStableDiffusion = findTag(script.tags, 'Output-Configuration') === 'stable-diffusion';

        throwIf(!operatorFee, `Invalid operator fee: ${operatorFee}`);
        throwIf(operatorFeeNum <= 0, `Invalid operator fee value: ${operatorFeeNum}`);
        throwIf(new TextEncoder().encode(text).length > FU.MAX_MESSAGE_SIZE, 'Prompt too long');

        const nImages = configuration.nImages;
        const actualFee = nImages && isStableDiffusion ? operatorFeeNum * nImages : operatorFeeNum;
        throwIf(uBalance < actualFee, 'Not Enough $U tokens to pay Operator');

        // -- Create data transaction
        const arweave = getMainArweaveInstance();
        const tx = await arweave.createTransaction({ data: text });

        // -- Set up "Fair Protocol" contract tags
        await FairSDKWeb.use('script', { node: script } as any);

        const tags = FairSDKWeb.utils.getUploadTags(
          FairSDKWeb.script,
          operatorWalletAddress,
          walletAddress!,
          Number(conversationIdentifier),
          contentType,
          // @ts-ignore
          configuration,
          'web'
        );

        /// addLicenseConfigTags(tags, licenseControl._formValues, licenseRef.current?.value);

        // -- Dispatch transaction (data + contract tags)
        tags.forEach((tag) => tx.addTag(tag.name, tag.value));
        const txid = await dispatchArweaveTx(tx, arweave, state);

        // -- Store prompt for immediate feedback
        dispatch(storePendingPrompt(txid, walletAddress!, tags));

        // -- Register transaction as a contract
        await FairSDKWeb.warp!.register(txid, 'node2');

        // -- Pay Operator
        await FairSDKWeb.connectWallet();
        const paymentInfo = await FairSDKWeb.utils.handlePayment(
          txid,
          operatorFee!,
          contentType,
          FairSDKWeb.script,
          Number(conversationIdentifier),
          findTag(script.tags, 'Model-Creator')!,
          operatorWalletAddress,
          configuration.nImages,
          'web'
        );

        console.log(paymentInfo);

        alert('Payment successful');
        dispatch(doWalletBalance());
        return txid;
      } catch (err: any) {
        // alert(err.message);
        throw err;
      } finally {
        dispatch(fairSlice.actions.fetchDone('sendingPrompt'));
      }
    };
  }

  function viaEarthOf() {
    return async (dispatch: AppDispatch, getState: GetState) => {
      try {
        dispatch(fairSlice.actions.fetchStarted('sendingPrompt'));

        await dispatch(doWalletBalance());

        const state = getState();
        const walletAddress = state.arweave.walletAddress;
        const backend = state.settings.backend;
        const { text, modelId, scriptId, operatorId } = state.prompt;

        throwIf(!text, 'Empty prompt');
        throwIf(!modelId, 'Model not selected');
        throwIf(!scriptId, 'Script not selected');
        throwIf(!operatorId, 'Operator not selected');
        throwIf(!walletAddress, 'Wallet not set');
        throwIf(new TextEncoder().encode(text).length > FU.MAX_MESSAGE_SIZE, 'Prompt too long');

        const response = await earthof.prompt(walletAddress!, {
          prompt: text,
          model: modelId,
          script: scriptId,
          operator: operatorId,
          tags: getEOTags(backend, walletAddress!),
          number_of_images: state.prompt.nImages,
        });

        const paymentInfo: PromptResponse = response.data;
        console.log(paymentInfo);
        alert('Payment successful');

        dispatch(storePendingPrompt(paymentInfo.requestId, EO_WALLET_ADDR));
        return paymentInfo.requestId;
      } catch (err: any) {
        // alert(err.response?.data || err.message);
        throw err;
      } finally {
        dispatch(fairSlice.actions.fetchDone('sendingPrompt'));
      }
    };
  }

  function storePendingPrompt(txid: TxId, txOwnerAddress: string, tags: TxTag[] = []) {
    return (dispatch: AppDispatch, getState: GetState) => {
      const state = getState();
      const text = state.prompt.text;
      const scriptId = state.prompt.scriptId;
      const script = state.fair.scriptsById[scriptId || ''];
      const backend = state.settings.backend;
      const walletAddress = state.arweave.walletAddress;
      const conversationId = state.prompt.conversationId;
      const conversation = state.fair.conversationsById[conversationId || ''];
      const conversationIdentifier =
        backend === 'fair-sdk' ? findTag(conversation?.tags, 'Conversation-Identifier') : '1';
      const queryKey = generatePromptQueryKey(backend, walletAddress, scriptId, conversationId!);

      const baseTags = [
        { __typename: 'Tag', name: 'Unix-Time', value: getCurrentTimeMs().toString() },
        { __typename: 'Tag', name: 'Script-Transaction', value: findTag(script.tags, 'Script-Transaction') || '' },
        { __typename: 'Tag', name: 'Conversation-Identifier', value: conversationIdentifier! },
        { __typename: 'Tag', name: 'N-Images', value: state.prompt.nImages.toString() },
        { __typename: 'Redux', name: 'Prompt', value: text },
        { __typename: 'Redux', name: 'Incomplete', value: 'true' },
      ];

      // @ts-ignore - partial object
      const newPrompt: TxEdge = {
        node: {
          id: txid,
          tags: [...baseTags, ...tags],
          owner: { address: txOwnerAddress },
        },
      };

      dispatch(fairSlice.actions.promptsFetched([newPrompt]));
      dispatch(fairSlice.actions.updatePromptQuery({ queryKey, ids: [txid], op: 'appendFront' }));
    };
  }
}
