type Entry = {
  id: TxId;
  evolvesFrom: TxId | null;
  comment?: string | null;
};

type ContractSrcHistory = {
  MAINNET: { blocklist: Entry[]; profile: Entry[]; post: Entry[] };
  ZEPHYR: { blocklist: Entry[]; profile: Entry[]; post: Entry[] };
  LOCAL: { blocklist: Entry[]; profile: Entry[]; post: Entry[] };
};

export const CONTRACT_SRC_IDS: ContractSrcHistory = {
  // --- MAINNET -------------------------------------------------------------
  MAINNET: {
    blocklist: [],
    profile: [],
    post: [],
  },
  // --- ZEPHYR --------------------------------------------------------------
  ZEPHYR: {
    blocklist: [
      {
        id: 'X9Agp1LVQnCFNKr0JiIbJ7bmdAGpEKvVRmykEcqL6K4',
        evolvesFrom: null,
        comment: 'Initial blocklist src',
      },
    ],
    profile: [
      {
        id: 'pRH-nJ4Fnj6Bz1qF1yYd_hlqVh76EnukrRe2yJquTkI',
        evolvesFrom: null,
        comment: 'Initial profile src',
      },
    ],
    post: [
      {
        id: 'isVP-nd3DwWLIkhm7Xg-rUaKx6suVGyksmf0pfffiVs',
        evolvesFrom: null,
        comment: 'Initial post src',
      },
      {
        id: 'oWbi2KyB-R_WH2sUEwbT4X8woS1K4RmE8ZPtFpznfSI',
        evolvesFrom: 'isVP-nd3DwWLIkhm7Xg-rUaKx6suVGyksmf0pfffiVs',
        comment: 'Adds viewComment, among others.',
      },
    ],
  },
  // --- LOCAL ---------------------------------------------------------------
  LOCAL: {
    blocklist: [],
    profile: [],
    post: [],
  },
};
