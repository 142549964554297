/**
 * General-purpose UI states.
 *
 * Use it for things like:
 * - Quickly push a state out from any component for global sharing.
 * - When there is no existing suitable slice to use.
 * - Replacement for `React.context`.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommentInput } from 'social-sdk/dist/interfaces';
import { MakeObject } from 'utils/objects';

interface UiState {
  fetchingBalance: boolean;
  activeProfileId: TxId | undefined;
  activePostId: TxId | undefined; // temp
  profileForm: {
    handle?: string;
    title?: string;
    description?: string;
    topics?: string[];
    customTags?: TxTag[];
  };
  postForm: {
    title?: string;
    description?: string;
    longDescription?: string;
    thumbnailUrl?: string; // SDK supposedly supports file; ignore that for now
    customTags?: TxTag[];
    contentTxids?: TxId[];
  };
  commentForm: Partial<ICommentInput['value']> & {
    postId?: TxId;
  };
}

const uiSlice = createSlice({
  name: 'ui',
  initialState: MakeObject<UiState>({
    fetchingBalance: false,
    activeProfileId: undefined,
    activePostId: undefined,
    profileForm: {},
    postForm: {},
    commentForm: {},
  }),
  reducers: {
    setFetchingBalance: (state, action: PayloadAction<boolean>) => {
      state.fetchingBalance = action.payload;
    },
    setActiveProfileId: (state, action: PayloadAction<TxId | undefined>) => {
      state.activeProfileId = action.payload;
    },
    setActivePostId: (state, action: PayloadAction<TxId | undefined>) => {
      state.activePostId = action.payload;
    },
    updateProfileForm: (state, action: PayloadAction<Partial<UiState['profileForm']>>) => {
      Object.assign(state.profileForm, action.payload);
    },
    updatePostForm: (state, action: PayloadAction<Partial<UiState['postForm']>>) => {
      Object.assign(state.postForm, action.payload);
    },
    updateCommentForm: (state, action: PayloadAction<Partial<UiState['commentForm']>>) => {
      Object.assign(state.commentForm, action.payload);
    },
  },
});

export default uiSlice;
