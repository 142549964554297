import React, { useState } from 'react';
import PostTypes from 'components/cards/postTypes';
import PostStyles from 'components/cards/postStyles';
import PostFilters from 'components/cards/postFilters';
import PostPreviews from 'components/cards/postPreviews';
import PostFinalPreview from 'components/cards/postFinalPreview';
import './style.scss';

import ImgRemix from 'assets/icons/remix.png';
import ImgDice from 'assets/icons/dice.png';

function Remix(props: any) {
  const { isRemixing, setIsRemixing, post } = props;
  const [page, setPage] = useState(0);
  const [activePreview, setActivePreview] = useState(0);

  return (
    <div className={`remix${isRemixing ? ' open' : ''}`}>
      <div className="remix__header">
        <div className="icon" onClick={() => setIsRemixing(!isRemixing)}>
          <img src={ImgRemix} />
        </div>
        {isRemixing && <h2>Remix</h2>}
      </div>
      <div className="remix__content">
        <div className={`remix__content__prompt ${page === 2 ? 'remix__content__prompt--hidden' : ''}`}>
          <div className="prompt">{post.prompt.slice(0, 130)}</div>
        </div>
        {isRemixing && (
          <>
            <div className={`remix__content__page ${page !== 0 ? 'remix__content__page--closed' : ''}`}>
              <div className="remix__content__row">
                <PostTypes />
              </div>
              <div className="remix__content__row">
                <PostStyles />
              </div>
              <div className="remix__content__row remix__content__row--next">
                <input type="button" value="Next" onClick={() => setPage(page + 1)} />
              </div>
            </div>
            <div className={`remix__content__page ${page !== 1 ? 'remix__content__page--closed' : ''}`}>
              <PostPreviews activePreview={activePreview} setActivePreview={setActivePreview} />
              <div className="remix__content__row">
                <div className="remix__content__retry">
                  <img src={ImgDice} /> Retry
                </div>
              </div>
              <div className="remix__content__row remix__content__row--next">
                <input type="button" value="Back" onClick={() => setPage(page - 1)} />
                <input
                  type="button"
                  value="Next"
                  onClick={() => setPage(page + 1)}
                  className={activePreview === 0 ? 'button--disabled' : ''}
                />
              </div>
            </div>
            <div className={`remix__content__page ${page !== 2 ? 'remix__content__page--closed' : ''}`}>
              <PostFinalPreview promptId={'?'} responseIndex={activePreview - 1} />

              <div className="remix__content__row">
                <PostFilters />
              </div>

              <div className="remix__content__row remix__content__row--next">
                <input type="button" value="Back" onClick={() => setPage(page - 1)} />
                <input
                  type="button"
                  value="Post!"
                  onClick={() => {
                    setIsRemixing(false);
                    setPage(0);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Remix;
