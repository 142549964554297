import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'lib/apollo';
import { doInitArweaveLibs } from 'store/arweave/thunks';
import { persistor, store } from 'store/store';
import Modal from 'components/base/modal';
import Navigation from 'components/navigation';
import Routes from 'routes';
import 'scss/style.scss';

function AppWrapper() {
  const Rehydrating = () => <div className="rehydrating" />;

  function onReduxReady() {
    store.dispatch(doInitArweaveLibs());
  }

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={<Rehydrating />} persistor={persistor} onBeforeLift={onReduxReady}>
          <ApolloProvider client={apolloClient}>
            <HashRouter>
              <Routes />
              <Navigation />
              <Modal />
            </HashRouter>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement as HTMLElement);
root.render(<AppWrapper />);
