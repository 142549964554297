import { Action } from '@reduxjs/toolkit';
import { updateApolloClient } from 'lib/apollo';
import { fetchAccountInfo } from 'lib/arweaveAccount';
import arweaveSlice from 'store/arweave/slice';
import { doInitArweaveLibs, doWalletBalance } from 'store/arweave/thunks';
import fairSlice from 'store/fair/slice';
import promptSlice from 'store/prompt/slice';
import { doPostFetchMine, doProfileFetchMine, doInitSocialSdk, doResetSocialSdk } from 'store/social/thunks';
import uiSlice from 'store/ui/slice';
import { suppressToConsole } from 'utils/error';

// Note: effects are broken off to separate file to address circular dependency.

export const effArweaveConfigChanged = {
  predicate: (action: Action, state: AppState, prevState: AppState) => {
    return (
      state.arweave.gatewayId !== prevState.arweave.gatewayId ||
      state.arweave.warpEnvironment !== prevState.arweave.warpEnvironment ||
      state.arweave.socialGatewayId !== prevState.arweave.socialGatewayId
    );
  },
  effect: async (action: Action, listenerApi: any) => {
    const { dispatch, getState } = listenerApi;
    const state: AppState = getState();

    dispatch(doInitArweaveLibs());
    dispatch(doWalletBalance());
    await updateApolloClient(state.arweave.gatewayId);
  },
};

export const effWalletAddressChanged = {
  predicate: (action: Action, state: AppState, prevState: AppState) => {
    return state.arweave.walletAddress !== prevState.arweave.walletAddress;
  },
  effect: async (action: Action, listenerApi: any) => {
    const { dispatch, getState } = listenerApi;
    const state: AppState = getState();

    dispatch(doWalletBalance());
    dispatch(uiSlice.actions.setActiveProfileId(undefined));

    if (state.arweave.walletAddress) {
      const account = await fetchAccountInfo(state.arweave.walletAddress);
      if (account) dispatch(arweaveSlice.actions.setAccountInfo(account));

      dispatch(doInitSocialSdk());
      dispatch(doProfileFetchMine()).catch(suppressToConsole);
    } else {
      dispatch(arweaveSlice.actions.clearAccountInfo());
      dispatch(promptSlice.actions.reset());
      dispatch(fairSlice.actions.clearWalletTiedData());
      dispatch(doResetSocialSdk());
    }
  },
};

export const effAutoFetchMyPosts = {
  predicate: (action: Action, state: AppState, prevState: AppState) => {
    return state.ui.activeProfileId !== prevState.ui.activeProfileId;
  },
  effect: async (action: Action, listenerApi: any) => {
    const { dispatch, getState } = listenerApi;

    dispatch(uiSlice.actions.setActivePostId(undefined));

    if (getState().ui.activeProfileId) {
      dispatch(doPostFetchMine()).catch(suppressToConsole);
    }
  },
};
