import { selectPromptForId, selectResponsesForPromptId } from 'store/fair/selectors';
import { useAppSelector } from 'store/hooks';

import { txidToHttp } from 'utils/url';
import { findTag } from 'utils/tags';
import './style.scss';

interface Props {
  promptId?: TxId;
  loading?: boolean;
  activePreview: number;
  setActivePreview: (n: number) => void;
}

export default function PostPreviews(props: Props) {
  const { promptId, activePreview, setActivePreview, loading } = props;

  const gatewayId = useAppSelector((state) => state.arweave.gatewayId);
  const prompt = useAppSelector((state) => selectPromptForId(state, promptId));
  const requestedImageCount = Number(findTag(prompt?.tags, 'N-Images') || null);
  const responsesForPrompt = useAppSelector((state) => selectResponsesForPromptId(state, promptId));
  const hasPendingRequests = responsesForPrompt.length < requestedImageCount;

  // Temp Mock Fallback
  const mock = promptId && promptId.length > 1 ? false : true;
  const uris =
    promptId && promptId.length > 1
      ? [
          txidToHttp(responsesForPrompt[0]?.id, gatewayId),
          txidToHttp(responsesForPrompt[1]?.id, gatewayId),
          txidToHttp(responsesForPrompt[2]?.id, gatewayId),
          txidToHttp(responsesForPrompt[3]?.id, gatewayId),
        ]
      : [
          `https://github.com/NewEstablishment/eo-resources/blob/main/remix/1.jpeg?raw=true`,
          `https://github.com/NewEstablishment/eo-resources/blob/main/remix/2.jpeg?raw=true`,
          `https://github.com/NewEstablishment/eo-resources/blob/main/remix/3.jpeg?raw=true`,
          `https://github.com/NewEstablishment/eo-resources/blob/main/remix/4.jpeg?raw=true`,
        ];

  return (
    <div className="postPreviews">
      <div
        className={`postPreviews__preview${activePreview === 1 ? ' postPreviews__preview--active' : ''}`}
        onClick={() => setActivePreview(1)}
        style={{
          backgroundImage: `url('${uris[0]}')`,
        }}
      >
        {mock || (!hasPendingRequests && !loading) ? (
          <div className="postPreviews__preview__background">
            <img src={uris[0]} />
          </div>
        ) : (
          <div className="postPreviews__preview__noise" />
        )}
      </div>
      <div
        className={`postPreviews__preview${activePreview === 2 ? ' postPreviews__preview--active' : ''}`}
        onClick={() => setActivePreview(2)}
        style={{
          backgroundImage: `url('${uris[1]}')`,
        }}
      >
        {mock || (!hasPendingRequests && !loading && false) ? (
          <div className="postPreviews__preview__background">
            <img src={uris[1]} />
          </div>
        ) : (
          <div className="postPreviews__preview__noise" />
        )}
      </div>
      <div
        className={`postPreviews__preview ${activePreview === 3 ? 'postPreviews__preview--active' : ''}`}
        onClick={() => setActivePreview(3)}
        style={{
          backgroundImage: `url('${uris[2]}')`,
        }}
      >
        {mock || (!hasPendingRequests && !loading && false) ? (
          <div className="postPreviews__preview__background">
            <img src={uris[2]} />
          </div>
        ) : (
          <div className="postPreviews__preview__noise" />
        )}
      </div>
      <div
        className={`postPreviews__preview ${activePreview === 4 ? 'postPreviews__preview--active' : ''}`}
        onClick={() => setActivePreview(4)}
        style={{
          backgroundImage: `url('${uris[3]}')`,
        }}
      >
        {mock || (!hasPendingRequests && !loading && false) ? (
          <div className="postPreviews__preview__background">
            <img src={uris[3]} />
          </div>
        ) : (
          <div className="postPreviews__preview__noise" />
        )}
      </div>
    </div>
  );
}
