import React from 'react';
import classnames from 'classnames';
import Button from 'components/base/button';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { doPostCreate } from 'store/social/thunks';
import uiSlice from 'store/ui/slice';
import './style.scss';

const { updatePostForm } = uiSlice.actions;

export default function CreatePost() {
  const dispatch = useAppDispatch();
  const form = useAppSelector((state) => state.ui.postForm);
  const busy = useAppSelector((state) => state.social.flags.creatingPost);
  const [result, setResult] = React.useState({ value: '', errorMsg: '' });
  const modsWrapper = { 'createProfile--busy': busy };
  const modsCreateBtn = { 'createProfile__create--busy': busy };

  const updateForm = (e: any) => {
    switch (e.target.name) {
      case 'contentTxids':
        dispatch(updatePostForm({ [e.target.name]: e.target.value.split('\n') }));
        break;
      default:
        dispatch(updatePostForm({ [e.target.name]: e.target.value }));
        break;
    }
  };

  const updateResult = (key: string, value: string) => {
    setResult((prev) => ({ ...prev, [key]: value }));
  };

  const create = async () => {
    Promise.resolve()
      .then(() => updateResult('value', ''))
      .then(() => updateResult('errorMsg', ''))
      .then(() => dispatch(doPostCreate()))
      .then((post: any) => updateResult('value', Object.keys(post)[0]))
      .catch((err) => updateResult('errorMsg', err.message));
  };

  return (
    <div className={classnames('arson', 'createPost', modsWrapper)}>
      <input name="title" defaultValue={form.title} onChange={updateForm} placeholder="Title" />
      <input name="description" defaultValue={form.description} onChange={updateForm} placeholder="Description" />
      <textarea
        name="longDescription"
        defaultValue={form.longDescription}
        onChange={updateForm}
        placeholder="Long Description"
      />
      <input name="thumbnailUrl" defaultValue={form.thumbnailUrl} onChange={updateForm} placeholder="Thumbnail URL" />
      <textarea
        name="contentTxids"
        defaultValue={form.contentTxids?.join('\n') || ''}
        onChange={updateForm}
        placeholder="Atomic Asset TXID (one per line)"
      />
      <Button className={classnames('createPost__submit', modsCreateBtn)} label="Create Post" onClick={create} />
      {result.errorMsg && <div className="createPost__error">{result.errorMsg}</div>}
      {result.value && <div className="createProfile__result">{result.value}</div>}
    </div>
  );
}
