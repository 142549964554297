import ActivityRow from './activityRow';
import './style.scss';

export default function ActivityPage() {
  return (
    <div className="activityPage">
      <div className="activityPage__header">
        <h2>Activity</h2>
      </div>
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
      <ActivityRow />
    </div>
  );
}
