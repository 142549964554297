import axios from 'axios';
import { throwIf } from 'utils/error';

// ***************************************************************************
// earthof API
// ***************************************************************************

export const earthof = {
  register: async (walletAddress: string, walletPubKey: string) => {
    return eoPublic.post('register', { address: walletAddress, pubkey: walletPubKey });
  },

  getNonce: async (walletAddress: string) => {
    return eoPublic.get(`users/${walletAddress}/nonce`);
  },

  signIn: async (walletAddress: string, nonce: string, nonceSigned: string) => {
    const sigInHex = Array.from(nonceSigned, (byte: any) => ('0' + (byte & 0xff).toString(16)).slice(-2)).join('');
    return eoPublic.post('signin', { address: walletAddress, nonce: nonce, sig: sigInHex }).then((response) => {
      const { access_token } = response.data.payload;
      throwIf(!access_token, 'Access token not found in response');
      setCookie(COOKIES.ACCESS_TOKEN, response.data.payload.access_token, 1);
      setCookie(COOKIES.REFRESH_TOKEN, 'hello kitty!', 30);
      return response;
    });
  },

  refreshToken: async () => {
    const refreshTokenValue = getCookie(COOKIES.ACCESS_TOKEN);
    throwIf(!refreshTokenValue, 'Refresh token not found');
    return eoPublic.post('/refresh-token', { refreshToken: refreshTokenValue }).then((response) => {
      const { access_token, refresh_token } = response.data.payload;
      throwIf(!access_token, 'Access token not found in response');
      throwIf(!refresh_token, 'Refresh token not found in response');
      setCookie(COOKIES.ACCESS_TOKEN, access_token, 1);
      setCookie(COOKIES.REFRESH_TOKEN, refresh_token, 30);
    });
  },

  all: async (walletAddress: string) => {
    return eoPrivate.get(`all?user=${walletAddress}`);
  },

  prompt: async (walletAddress: string, body: {}) => {
    return eoPrivate.post(`prompt?user=${walletAddress}&wait=true`, body);
  },
};

// ***************************************************************************
// Internal (cookies ... nom nom ...)
// ***************************************************************************

const COOKIES = {
  ACCESS_TOKEN: 'eoAccessToken',
  REFRESH_TOKEN: 'eoRefreshToken',
};

const setCookie = (name: string, value: string, days: number) => {
  const cookieParts = [];
  cookieParts.push(`${name}=${value}`);
  cookieParts.push(`path=/`);
  cookieParts.push(`expires=${new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()}`);

  if (process.env.NODE_ENV === 'production') {
    cookieParts.push(`HttpOnly`);
    cookieParts.push(`Secure`);
    cookieParts.push(`SameSite=None`);
  }

  document.cookie = cookieParts.join('; ');
};

const getCookie = (name: string): string | null => {
  const cookies = document.cookie;
  const cookie = cookies.split(';').find((row) => row.startsWith(`${name}=`));
  return cookie?.split('=')[1] || null;
};

// ***************************************************************************
// Internal (eoPublic)
// ***************************************************************************

const eoPublic = axios.create({
  baseURL: 'http://localhost:8080', // 'https://fairproxy.earthof.com',
  headers: { 'Content-Type': 'application/json' },
});

// ***************************************************************************
// Internal (eoPrivate)
// ***************************************************************************

const eoPrivate = axios.create({
  baseURL: 'https://fairproxy.earthof.com',
  headers: { 'Content-Type': 'application/json' },
  // withCredentials: true, [] see below
});

// [] The service doesn't expect access tokens yet, so comment out interceptors for now

// eoPrivate.interceptors.request.use(
//   (config) => {
//     const accessToken = getCookie(COOKIES.ACCESS_TOKEN);
//     accessToken && (config.headers['Authorization'] = `Bearer ${accessToken}`);
//     return config;
//   },
//   (error) => Promise.reject(error)
// );
//
// eoPrivate.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const TOKEN_EXPIRED = 401;
//     const originalRequest = error.config;
//
//     if (error.response.status === TOKEN_EXPIRED && !originalRequest._retry) {
//       const refreshToken = getCookie(COOKIES.REFRESH_TOKEN);
//       if (refreshToken) {
//         originalRequest._retry = true;
//         await earthof.refreshToken().catch((error: any) => console.log(error)); // Ignore errors (silent refresh)
//         return eoPrivate(originalRequest); // Retry the original request with the new token
//       }
//     }
//
//     return Promise.reject(error);
//   }
// );

// ***************************************************************************
// Internal (log errors)
// ***************************************************************************

const handleGlobalError = (error: any) => {
  console.error('EO:', error.response?.data);
  return Promise.reject(error);
};

eoPublic.interceptors.response.use(
  (response) => response,
  (error) => handleGlobalError(error)
);

eoPrivate.interceptors.response.use(
  (response) => response,
  (error) => handleGlobalError(error)
);
