import React from 'react';
import classnames from 'classnames';
import { selectModels } from 'store/fair/selectors';
import { doFetchModels } from 'store/fair/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import promptSlice from 'store/prompt/slice';
import './style.scss';

const { modelSelected } = promptSlice.actions;

interface Props {
  className?: string;
}

function ModelSelect(props: Props) {
  const { className } = props;
  const dispatch = useAppDispatch();
  const modelId = useAppSelector((state) => state.prompt.modelId);
  const models = useAppSelector(selectModels);
  const fetching = useAppSelector((state) => state.fair.fetching.models);

  React.useEffect(() => {
    dispatch(doFetchModels());
    // eslint-disable-next-line react-hooks/exhaustive-deps --  mount
  }, []);

  const Select = () => (
    <select name="models" value={modelId} onChange={(e) => dispatch(modelSelected(e.target.value))}>
      <option key="none" value="">
        -- Select --
      </option>
      {models.map((model) => (
        <option key={model.id} value={model.id}>
          {model.tags.find((tag: TxTag) => tag.name === 'Model-Name')?.value || '?'}
        </option>
      ))}
    </select>
  );

  return (
    <div className={classnames(className, 'models')}>
      {fetching && <span className="blink">Loading...</span>}
      {!fetching && <Select />}
    </div>
  );
}

export default ModelSelect;
