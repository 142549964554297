import React from 'react';
import ImgArrowRight from 'assets/icons/arrow_right.svg';
import DialogConfirm, { DialogConfirmProps } from 'components/dialogs/dialogConfirm';
import { doWalletDisconnect } from 'store/arweave/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import { sendAlert } from 'utils/error';
import './style.scss';

export default function UserSettings() {
  return (
    <div className="userSettings">
      <div className="userSettings__header">Settings</div>

      <div className="userSettings__options">
        <div className="userSettings__option">
          Change Password <img src={ImgArrowRight} />
        </div>
        <div className="userSettings__option">
          Change email address <img src={ImgArrowRight} />
        </div>
        <div className="userSettings__option">
          Muted Accounts <img src={ImgArrowRight} />
        </div>
        <div className="userSettings__option">
          Blocked Accounts <img src={ImgArrowRight} />
        </div>
        <SignOut className="userSettings__option userSettings__option--warn" />
      </div>
    </div>
  );
}

const SignOut = (props: any) => {
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector((state: AppState) => state.arweave.walletAddress);

  const dialogProps: DialogConfirmProps = {
    okText: 'OK',
    cancelText: 'Cancel',
    title: 'Sign Out?',
    onOk: () => dispatch(doWalletDisconnect()).catch(sendAlert),
  };

  return walletAddress ? (
    <div className={props.className} onClick={() => dispatch(openModal(DialogConfirm, dialogProps))}>
      Sign Out <img src={ImgArrowRight} />
    </div>
  ) : null;
};
