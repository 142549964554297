// import React, { useState } from 'react';
import TilePreview from 'components/tilePreview';
import './style.scss';

import { getMockEntries, shuffle } from 'constants/mockdata';

function DiscoverGrid() {
  const MOCK_ENTRIES = shuffle(getMockEntries(50));

  return (
    <div className="discoverGrid">
      <div className="discoverGrid__row">
        <TilePreview tile={MOCK_ENTRIES[0]} />
        <TilePreview tile={MOCK_ENTRIES[1]} />
        <TilePreview tile={MOCK_ENTRIES[2]} />
      </div>
      <div className="discoverGrid__row">
        <TilePreview tile={MOCK_ENTRIES[3]} large />
        <div className="discoverGrid__column">
          <TilePreview tile={MOCK_ENTRIES[4]} height />
        </div>
      </div>
      <div className="discoverGrid__row">
        <TilePreview tile={MOCK_ENTRIES[5]} />
        <TilePreview tile={MOCK_ENTRIES[6]} />
        <TilePreview tile={MOCK_ENTRIES[7]} />
      </div>
      <div className="discoverGrid__row">
        <div className="discoverGrid__column">
          <TilePreview tile={MOCK_ENTRIES[8]} height />
        </div>
        <TilePreview tile={MOCK_ENTRIES[9]} large />
      </div>
      <div className="discoverGrid__row">
        <TilePreview tile={MOCK_ENTRIES[10]} />
        <TilePreview tile={MOCK_ENTRIES[11]} />
        <TilePreview tile={MOCK_ENTRIES[12]} />
      </div>
      <div className="discoverGrid__row">
        <TilePreview tile={MOCK_ENTRIES[13]} large />
        <div className="discoverGrid__column">
          <TilePreview tile={MOCK_ENTRIES[14]} height />
        </div>
      </div>
      <div className="discoverGrid__row">
        <TilePreview tile={MOCK_ENTRIES[15]} />
        <TilePreview tile={MOCK_ENTRIES[16]} />
        <TilePreview tile={MOCK_ENTRIES[17]} />
      </div>
      <div className="discoverGrid__row">
        <div className="discoverGrid__column">
          <TilePreview tile={MOCK_ENTRIES[19]} height />
        </div>
        <TilePreview tile={MOCK_ENTRIES[18]} large />
      </div>
    </div>
  );
}

export default DiscoverGrid;
