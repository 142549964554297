const entries = [
  {
    id: '1',
    prompt: 'Mechanical spider on building',
  },
  {
    id: '2',
    prompt: 'Steampunk raccoon',
  },
  {
    id: '3',
    prompt: 'Smiling pumpkins',
  },
  {
    id: '4',
    prompt: 'Fox in the clouds',
  },
  {
    id: '5',
    prompt: 'Cabin covered in snow',
  },
  {
    id: '6',
    prompt: 'A cat playing banjo in space',
  },
  {
    id: '7',
    prompt: 'A fish smoking a cigarette ',
  },
  {
    id: '8',
    prompt: 'A panda reading the newspaper',
  },
  {
    id: '9',
    prompt: 'dreamlike forest, neon colors',
  },
  {
    id: '10',
    prompt: 'mushroom house in a foggy forest',
  },
  {
    id: '11',
    prompt: 'Steampunk airship',
  },
  {
    id: '12',
    prompt: 'Marmot drinking a cocktail on the moon',
  },
  {
    id: '13',
    prompt: 'Seal pirate on his ship',
  },
  {
    id: '14',
    prompt: 'Jellyfish flying in space, neon colors',
  },
  {
    id: '15',
    prompt: 'a groundhog with a top hat puffing a cigar',
  },
  {
    id: '16',
    prompt: 'lake in the snowy mountains',
  },
  {
    id: '17',
    prompt: 'swiss countryside',
  },
  {
    id: '18',
    prompt: 'sphinx covered in ice in Antarctica, temple in background, snowing, photo',
  },
  {
    id: '19',
    prompt: 'maya temple buried in sand, desert',
  },
  {
    id: '20',
    prompt: 'sunken Phoenician city, under water',
  },
];

const posts = [
  {
    id: '1',
    prompt: '1girl, guweiz, in library, reading book, wizard hat, fantasy setting',
    uri: 'https://github.com/NewEstablishment/eo-resources/blob/main/posts/1.jpeg?raw=true',
  },
  {
    id: '2',
    prompt: '1girl, guweiz, in library, reading book, wizard hat, fantasy setting',
    uri: 'https://github.com/NewEstablishment/eo-resources/blob/main/posts/2.jpeg?raw=true',
  },
  {
    id: '3',
    prompt: '1girl, guweiz, in library, reading book, wizard hat, fantasy setting',
    uri: 'https://github.com/NewEstablishment/eo-resources/blob/main/posts/3.jpeg?raw=true',
  },
  {
    id: '4',
    prompt: '1girl, guweiz, in library, reading book, wizard hat, fantasy setting, animeline',
    uri: 'https://github.com/NewEstablishment/eo-resources/blob/main/posts/4.jpeg?raw=true',
  },
  {
    id: '5',
    prompt: '1girl, guweiz, in library, reading book, wizard hat, fantasy setting',
    uri: 'https://github.com/NewEstablishment/eo-resources/blob/main/posts/5.jpeg?raw=true',
  },
  {
    id: '6',
    prompt: '1girl, guweiz, in forest, reading book, wizard hat, fantasy setting',
    uri: 'https://github.com/NewEstablishment/eo-resources/blob/main/posts/6.jpeg?raw=true',
  },
  {
    id: '7',
    prompt: '1girl, guweiz, rice field, katanas, wearing kimono',
    uri: 'https://github.com/NewEstablishment/eo-resources/blob/main/posts/7.jpeg?raw=true',
  },
  {
    id: '8',
    prompt: '1girl, guweiz, rice field, katanas, wearing kimono, animeliner',
    uri: 'https://github.com/NewEstablishment/eo-resources/blob/main/posts/8.jpeg?raw=true',
  },
];

const users = [
  {
    id: '1',
    handle: 'rave',
    name: 'Rave',
    avatar: 'https://avatars.githubusercontent.com/u/94839278?v=4',
  },
  {
    id: '2',
    handle: 'kp',
    name: 'KP',
    avatar: 'https://avatars.githubusercontent.com/u/64950861?v=4',
  },
  {
    id: '3',
    handle: 'tom',
    name: 'Tom',
    avatar: 'https://avatars.githubusercontent.com/u/8120721?v=4',
  },
  {
    id: '4',
    handle: 'jessop',
    name: 'Jessop',
    avatar: 'https://avatars.githubusercontent.com/u/36554050?v=4',
  },
];

export function getMockEntries(number: number = 5) {
  return entries.slice(0, number);
}

export function getMockPosts(number: number = 8) {
  return posts.slice(0, number);
}

export function getMockUsers(number: number = 4) {
  return users.slice(0, number);
}

export function shuffle(array: any) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
