import React from 'react';
import { useNavigate } from 'react-router-dom';
import defaultProfilePic from 'assets/images/favicon.png'; // TODO: fix file
import Button from 'components/base/button';
import { Menu, MenuButton, MenuItem, MenuList } from 'components/base/menu';
import Image from 'components/base/image';
import { str } from 'constants/strings';
import { getUserHandle } from 'lib/arweaveAccount';
import { doWalletDisconnect } from 'store/arweave/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './style.scss';

function UserMenu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const walletAddress = useAppSelector((state: AppState) => state.arweave.walletAddress);
  const arProfile = useAppSelector((state) => state.arweave.arProfile);
  const handle = getUserHandle(arProfile);

  const handleViewTransaction = () => {
    window.open(`https://viewblock.io/arweave/address/${walletAddress}`, '_blank');
  };

  const MenuSectionHeader = (props: { title?: string }) => {
    return <div className="menu-section-header">{props.title || <hr />}</div>;
  };

  return walletAddress ? (
    <Menu>
      <MenuButton className="user-in">
        <span>{handle}</span>
        <Image src={arProfile?.profile.avatar || defaultProfilePic} alt="profile" />
      </MenuButton>
      <MenuList>
        {/*<MenuSectionHeader title="Profile" />*/}
        {/*<MenuItem onClick={() => alert('stub')}>Edit Profile</MenuItem>*/}
        {/*<MenuItem onClick={() => alert('stub')}>Share Profile</MenuItem>*/}
        <MenuSectionHeader title="Wallet" />
        <MenuItem onClick={handleViewTransaction}>View Transactions</MenuItem>
        <MenuSectionHeader />
        <MenuItem onClick={() => dispatch(doWalletDisconnect())}>{str.signOut}</MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <Button className="user-out" label={str.signIn} onClick={() => navigate('/signIn')} />
  );
}

// onClick={() => }

export default UserMenu;
