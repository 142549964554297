import { GATEWAYS } from 'constants/arweave';

export function arToHttp(url: string | undefined, gatewayId: GatewayId) {
  if (url?.startsWith('ar://')) {
    const gateway = GATEWAYS[gatewayId];
    const gatewayBaseUrl = `${gateway.protocol}://${gateway.host}:${gateway.port}`;
    return url.replace('ar://', `${gatewayBaseUrl}/`);
  }
  return url;
}

export function txidToHttp(txid: TxId, gatewayId: GatewayId) {
  if (!txid || txid.length !== 43) {
    return txid;
  }

  return arToHttp(`ar://${txid}`, gatewayId);
}
