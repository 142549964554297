import React from 'react';
import classnames from 'classnames';
import ImgArrowLeft from 'assets/icons/arrowLeft.png';
import Button from 'components/base/button';
import DialogConfirm from 'components/dialogs/dialogConfirm';
import WalletList from 'components/walletList';
import { IMAGE_URLS } from 'constants/imageUrls';
import { getUserHandle } from 'lib/arweaveAccount';
import { doWalletDisconnect } from 'store/arweave/thunks';
import authSlice from 'store/auth/slice';
import { doAuthSignIn, doAuthRegister, doAuthSignOut } from 'store/auth/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import { sendAlert } from 'utils/error';
import { arToHttp } from 'utils/url';
import './style.scss';

export default function SignIn() {
  const [busy, setBusy] = React.useState(false);

  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector((state) => state.arweave.walletAddress);
  const arProfile = useAppSelector((state) => state.arweave.arProfile);
  const signedIn = useAppSelector((state) => state.auth.signedIn);
  const gatewayId = useAppSelector((state) => state.arweave.gatewayId);

  const handle = getUserHandle(arProfile);
  const avatarSrc = arToHttp(arProfile?.profile.avatar, gatewayId) || IMAGE_URLS.SIGNED_OUT;

  const avatarMods = { 'signInPage__avatar--active': walletAddress };
  const walletMods = { 'signIbPage__wallet--disabled': busy };
  const eoMods = {
    'signInPage__eo--disabled': !walletAddress || busy,
    'signInPage__eo--busy': busy,
  };

  function confirmSignIn() {
    dispatch(
      openModal(DialogConfirm, {
        okText: 'OK',
        cancelText: 'Cancel',
        title: 'Sign in?',
        body: 'This will register your wallet if not already.',
        onOk: doSignIn,
      })
    );
  }

  function doSignIn() {
    const MOCK_AUTH_SERVER = true;
    if (MOCK_AUTH_SERVER) {
      Promise.resolve()
        .then(() => setBusy(true))
        .then(() => new Promise((resolve) => setTimeout(resolve, 3000)))
        .then(() => dispatch(authSlice.actions.signedIn()))
        .then(() => alert('Signed in (mock)'))
        .finally(() => setBusy(false));
    } else {
      Promise.resolve()
        .then(() => setBusy(true))
        .then(() => dispatch(doAuthRegister()))
        .catch((err) => {
          if (err.response?.status !== 409) throw err; // 409 = already registered, just proceed
        })
        .then(() => dispatch(doAuthSignIn()))
        .then(() => alert('Signed in!'))
        .catch(sendAlert)
        .finally(() => setBusy(false));
    }
  }

  return (
    <div className="signInPage">
      <div className="signInPage__header">
        <div className="header__back" onClick={() => window.history.go(walletAddress ? -1 : -2)}>
          <img src={ImgArrowLeft} />
        </div>
      </div>

      <div className={classnames('signInPage__avatar', avatarMods)}>
        <img src={avatarSrc} />
        {walletAddress && <div>{handle}</div>}
      </div>

      <div className={classnames('signInPage__wallet', walletMods)}>
        {walletAddress ? (
          <Button className="disconnectBtn" label="Disconnect" onClick={() => dispatch(doWalletDisconnect())} />
        ) : (
          <WalletList title="Connect Arweave Wallet" />
        )}
      </div>

      <hr />

      <div className={classnames('signInPage__eo', eoMods)}>
        <div className="eo__title">EARTHoF.</div>
        {!signedIn && <div className="eo__subtitle">Register your wallet with EARTHoF for subsidized actions.</div>}
        {!signedIn && <Button label="Register / Sign In" onClick={confirmSignIn} />}
        {signedIn && <Button label="Sign Out" onClick={() => dispatch(doAuthSignOut())} />}
      </div>
    </div>
  );
}
