import Arweave from 'arweave';
import { createWarpInstance } from 'lib/warp';
import SocialSDK, { SocialSDKConfig } from 'social-sdk/dist';
import { throwIf } from 'utils/error';
import { GATEWAYS } from 'constants/arweave';
import { CONTRACT_SRC_IDS } from 'constants/contractIds';

let instance: SocialSDK | null = null;

/**
 * Returns the Social SDK instance.
 *
 * A wrapper is needed because the gateways can be changed at runtime and
 * instance be re-initialized.
 */
export function getSocialSdkInstance() {
  throwIf(!instance, 'Social SDK not initialized.');
  return instance;
}

/**
 * initSocialSdk
 *
 * Notes: Uses own arweave instance, at least for now (testing on arlocal).
 *
 * @param walletAddress
 * @param socialGatewayId
 * @param warpEnv
 */
export function initSocialSdk(walletAddress: string, socialGatewayId: GatewayId, warpEnv: WarpEnv) {
  throwIf(!window.arweaveWallet, 'initSocialSdk: wallet not connected');

  const arweaveConfig = {
    host: GATEWAYS[socialGatewayId].host,
    port: GATEWAYS[socialGatewayId].port,
    protocol: GATEWAYS[socialGatewayId].protocol,
    timeout: 20000,
    logging: false,
  };

  const arweave = Arweave.init(arweaveConfig);
  const warp = createWarpInstance(socialGatewayId, arweave, warpEnv);
  const contractSrcIds = getContractSrcIds(socialGatewayId);

  const socialConfig: SocialSDKConfig = {
    arweavePort: arweaveConfig.port,
    arweaveUrl: `${arweaveConfig.protocol}://${arweaveConfig.host}`,
    wallet: window.arweaveWallet,
    walletAddress: walletAddress,
    warp: warp,
    profileSrcTxid: contractSrcIds.profile.slice(-1)[0].id, // assume last entry is the latest
    postSrcTxid: contractSrcIds.post.slice(-1)[0].id, // assume last entry is the latest
    blocklistSrcTxid: contractSrcIds.blocklist.slice(-1)[0].id, // assume last entry is the latest
  };

  instance = new SocialSDK(socialConfig);
  return instance; // for convenience; use getSocialSdkInstance() by default.
}

export function resetSocialSdk() {
  instance = null;
}

// ****************************************************************************
// Helpers
// ****************************************************************************

function getContractSrcIds(gatewayId: GatewayId) {
  switch (gatewayId) {
    case 'arweave.net':
    case 'g8way.io':
      return CONTRACT_SRC_IDS.MAINNET;
    case 'arlocal.zephyrdev.xyz':
      return CONTRACT_SRC_IDS.ZEPHYR;
    case 'arlocal':
      return CONTRACT_SRC_IDS.LOCAL;
    default:
      throw new Error(`Unhandled gateway: ${gatewayId}`);
  }
}
