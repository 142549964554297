import { selectResponsesForPromptId } from 'store/fair/selectors';
import { useAppSelector } from 'store/hooks';
import { txidToHttp } from 'utils/url';
import './style.scss';

interface Props {
  promptId: TxId;
  responseIndex: number;
  // Referencing by index requires the result to be sorted consistently.
  // Should probably just pass the img TxId instead.
}

export default function PostFinalPreview(props: Props) {
  const { promptId, responseIndex } = props;
  const gatewayId = useAppSelector((state) => state.arweave.gatewayId);
  const responses = useAppSelector((state) => selectResponsesForPromptId(state, promptId));

  // Temp Mock Fallback
  const uri =
    txidToHttp(responses[responseIndex]?.id, gatewayId) ||
    `https://github.com/NewEstablishment/eo-resources/blob/main/remix/${responseIndex + 1}.jpeg?raw=true`;

  return (
    <div
      className="postFinalPreview"
      style={{
        backgroundImage: `url('${uri}')`,
      }}
    >
      <div
        className="postFinalPreview__background"
        style={{
          backgroundImage: `url('${uri}')`,
        }}
      ></div>
      <img src={uri} />
      <div className="postFinalPreview__text">
        <p>{responseIndex + 1 || promptId}</p>
      </div>
    </div>
  );
}
