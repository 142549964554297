import { gql } from '@apollo/client';

export const FIND_PROFILES_FOR_WALLET = gql`
  query FIND_PROFILES_FOR_WALLET($address: String!, $tags: [TagFilter!], $first: Int, $after: String) {
    transactions(tags: $tags, owners: [$address], sort: HEIGHT_DESC, first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          owner {
            address
          }
          tags {
            name
            value
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
