import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modal/slice';
import './style.scss';

interface Props {}

interface ModalInstanceProps {
  ModalComponent: React.ElementType;
  props: any;
  i: number;
}

const ModalInstance: React.FC<ModalInstanceProps> = ({ ModalComponent, props, i }) => {
  const dispatch = useAppDispatch();
  const delay = useRef<any>(null);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
    setIsClosing(true);

    delay.current = setInterval(() => {
      setIsClosing(false);
      dispatch(closeModal());
      clearInterval(delay.current);
    }, 400);
  }, [dispatch]);

  return (
    <div className={`modal ${isClosing ? 'modal--closing' : ''}`} onClick={handleClose} key={i}>
      <div className="modal__content" style={{ top: 70 + 30 * i }} onClick={(event) => event.stopPropagation()}>
        <ModalComponent {...props} handleCloseModal={handleClose} />
      </div>
    </div>
  );
};

const MemoizedModalInstance = React.memo(ModalInstance);

const Modal: React.FC<Props> = () => {
  const modalList = useAppSelector((state) => state.modal.modalList);
  const modalPropsList = useAppSelector((state) => state.modal.modalPropsList);
  const [isOpen, setIsOpen] = useState(false);
  const root = document.getElementById('root');

  useEffect(() => {
    if (modalList) {
      setIsOpen(true);
    }
  }, [modalList]);

  if (root && isOpen) {
    return ReactDOM.createPortal(
      <>
        {modalList &&
          modalList.map((ModalComponent, i) => (
            <MemoizedModalInstance ModalComponent={ModalComponent} props={modalPropsList[i]} i={i} key={i} />
          ))}
      </>,
      root
    );
  } else {
    return null;
  }
};

export default Modal;
