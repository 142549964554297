import React from 'react';
import classnames from 'classnames';
import Button from 'components/base/button';
import DialogConfirm, { DialogConfirmProps } from 'components/dialogs/dialogConfirm';
import { selectConversations } from 'store/fair/selectors';
import { doCreateConversation, doFetchConversations } from 'store/fair/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import promptSlice from 'store/prompt/slice';
import './style.scss';
import { sendAlert } from 'utils/error';
import { findTag } from 'utils/tags';

const { conversationSelected } = promptSlice.actions;

interface Props {
  className?: string;
}

function ConversationSelect(props: Props) {
  const { className } = props;
  const dispatch = useAppDispatch();
  const walletAddress = useAppSelector((state) => state.arweave.walletAddress);
  const scriptId = useAppSelector((state) => state.prompt.scriptId);
  const fetching = useAppSelector((state) => state.fair.fetching.conversations);
  const conversations = useAppSelector(selectConversations);
  const conversationId = useAppSelector((state) => state.prompt.conversationId);
  const backend = useAppSelector((state) => state.settings.backend);
  const disabled = backend.startsWith('earthof');

  React.useEffect(() => {
    if (walletAddress && scriptId) {
      dispatch(doFetchConversations());
    }
  }, [walletAddress, scriptId, dispatch]);

  const Select = () => (
    <select name="conversation" value={conversationId} onChange={(e) => dispatch(conversationSelected(e.target.value))}>
      <option key="none" value="">
        -- Select --
      </option>
      {conversations.map((c) => (
        <option key={c.id} value={c.id}>
          {findTag(c.tags, 'Conversation-Identifier')}
        </option>
      ))}
    </select>
  );

  const NewConversation = () => {
    const dialogProps: DialogConfirmProps = {
      okText: 'OK',
      cancelText: 'Cancel',
      title: 'Create new conversation?',
      body: 'Note: conversations cannot be deleted from Arweave.',
      onOk: () => {
        dispatch(doCreateConversation())
          .then(() => alert('Conversation created'))
          .catch(sendAlert);
      },
    };

    return <Button label="+" onClick={() => dispatch(openModal(DialogConfirm, dialogProps))} />;
  };

  return (
    <div className={classnames(className, 'conversations', { 'conversations--disabled': disabled })}>
      {fetching ? (
        <span className="blink">Loading...</span>
      ) : !walletAddress ? (
        <span>Wallet not connected</span>
      ) : (
        <>
          {conversations.length !== 0 && <Select />}
          <NewConversation />
        </>
      )}
    </div>
  );
}

export default ConversationSelect;
