import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PromptState {
  text: string;
  modelId: string | undefined;
  scriptId: string | undefined;
  operatorId: string | undefined;
  conversationId: string | undefined;
  nImages: number;
}

const initialState: PromptState = {
  text: '',
  modelId: undefined,
  scriptId: undefined,
  operatorId: undefined,
  conversationId: undefined,
  nImages: 1,
};

const promptSlice = createSlice({
  name: 'prompt',
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.text = initialState.text;
      state.modelId = initialState.modelId;
      state.scriptId = initialState.scriptId;
      state.operatorId = initialState.operatorId;
      state.conversationId = initialState.conversationId;
    },
    modelSelected: (state, action: PayloadAction<TxId>) => {
      state.modelId = action.payload;
      state.scriptId = initialState.scriptId;
      state.operatorId = initialState.operatorId;
      state.conversationId = initialState.conversationId;
    },
    scriptSelected: (state, action: PayloadAction<TxId>) => {
      state.scriptId = action.payload;
      state.operatorId = initialState.operatorId;
      state.conversationId = initialState.conversationId;
    },
    operatorSelected: (state, action: PayloadAction<TxId>) => {
      state.operatorId = action.payload;
      state.conversationId = initialState.conversationId;
    },
    conversationSelected: (state, action: PayloadAction<string>) => {
      state.conversationId = action.payload;
    },
    textUpdated: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
    nImagesUpdated: (state, action: PayloadAction<number>) => {
      state.nImages = action.payload;
    },
  },
});

export default promptSlice;
