import React from 'react';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import settingsSlice from 'store/settings/slice';
import './style.scss';

interface Props {
  className?: string;
}

function BackendSelect(props: Props) {
  const { className } = props;
  const dispatch = useAppDispatch();
  const backend = useAppSelector((state) => state.settings.backend);

  const changeBackend = (value: any) => dispatch(settingsSlice.actions.setBackend(value));

  return (
    <div className={classnames(className, 'backend')}>
      <select name="backend" value={backend} onChange={(e) => changeBackend(e.target.value)}>
        <option value="earthof_v0.2">earthof.com (v0.2 tags)</option>
        <option value="earthof_v0.1" disabled>
          earthof.com (v0.1 tags)
        </option>
        <option value="earthof_v0.0">earthof.com (v0.0 tags)</option>
        <option key="fair-sdk" value="fair-sdk">
          FairSDK
        </option>
      </select>
    </div>
  );
}

export default BackendSelect;
