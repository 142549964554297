import { earthof } from 'services/earthof';
import { doWalletSignMessage } from 'store/arweave/thunks';
import authSlice from 'store/auth/slice';
import { throwIf } from 'utils/error';

export function doAuthRegister() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const walletAddress = state.arweave.walletAddress;
      const walletType = state.arweave.walletType;

      throwIf(!walletAddress, 'Register: connect an Arweave wallet first');
      const arweaveAppShenanigans = walletType === 'arweave.app' && !window.arweaveWallet;
      throwIf(arweaveAppShenanigans, 'Register: please leave the arweave.app window open');
      throwIf(!window.arweaveWallet, 'Register: wallet instance not found');

      const publicKey = await window.arweaveWallet.getActivePublicKey();
      const res = await earthof.register(walletAddress!, publicKey);

      throwIf(res.status === 409, 'User already registered');
      throwIf(res.status !== 201, 'Unexpected registration status code');
    } catch (error: any) {
      throwIf(error.message.startsWith('Network Error'), 'Failed to connect to EARTHoF Auth server');
      throw error;
    }
  };
}

export function doAuthSignIn() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const state = getState();
      const walletAddress = state.arweave.walletAddress;

      throwIf(!walletAddress, 'Auth: connect an Arweave wallet first');
      throwIf(!window.arweaveWallet, 'Auth: wallet instance not found');

      let res = await earthof.getNonce(walletAddress!);
      throwIf(res.status === 404, 'User not found. Registration required.');
      throwIf(res.status !== 200, 'Unexpected nonce status code');
      throwIf(!res.data?.payload?.nonce, 'Nonce not found in response');

      const nonce = res.data.payload.nonce;
      const nonceSigned = await dispatch(doWalletSignMessage(nonce));

      res = await earthof.signIn(walletAddress!, nonce, nonceSigned);
      throwIf(res.status !== 200, 'SignIn: unexpected status code');

      dispatch(authSlice.actions.signedIn());
    } catch (error: any) {
      throwIf(error.message.startsWith('Network Error'), 'NonceRequest: failed to connect to EARTHoF Auth server');
      throw error;
    }
  };
}

export function doAuthSignOut() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(authSlice.actions.signedOut());
  };
}
