/**
 * `arweave-js` instance and helpers.
 */

import Arweave from 'arweave';
import { GATEWAYS } from 'constants/arweave';

let instance: Arweave | null = null;

/**
 * Returns the main Arweave instance that is tied to `window.arweaveWallet`.
 */
export function getMainArweaveInstance() {
  if (instance) {
    return instance;
  } else {
    throw new Error('Arweave not initialized.');
  }
}

export function initMainArweaveInstance(gatewayId: GatewayId) {
  instance = createArweaveInstance(gatewayId);
}

export function createArweaveInstance(gatewayId: GatewayId) {
  const gateway = GATEWAYS[gatewayId];

  return Arweave.init({
    host: gateway.host,
    port: gateway.port,
    protocol: gateway.protocol,
    timeout: 20000,
    logging: false,
  });
}

export async function dispatchArweaveTx(tx: any, arweave: any, state: AppState) {
  const usingArLocal = state.arweave.gatewayId.startsWith('arlocal');

  if (usingArLocal) {
    await arweave.transactions.sign(tx);
    const result = await arweave.transactions.post(tx);

    if (result?.status === 200) {
      return tx.id;
    } else {
      throw new Error('Arweave TX Post failed', { cause: result });
    }
  } else {
    const result = await window.arweaveWallet.dispatch(tx);

    if (result.id) {
      return result.id;
    } else {
      throw new Error('Arweave TX Dispatch failed', { cause: result });
    }
  }
}
