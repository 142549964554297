// import React, { useState } from 'react';
import DiscoverGrid from './grid';
import './style.scss';

import { ReactComponent as ImgDiscover } from 'assets/icons/discover.svg';

function Discover() {
  return (
    <div className="discoverPage">
      <div className="discoverPage__search">
        <input type="text" placeholder="Discover" />
        <ImgDiscover />
      </div>
      <h1>EARTHoF.</h1>
      <div className="discoverPage__gridWrapper">
        <DiscoverGrid />
      </div>
    </div>
  );
}

export default Discover;
