import Comment from './comment';
import CommentAdd from './commentAdd';
import { ReactComponent as ImgComments } from 'assets/icons/comments.svg';

import './style.scss';

export default function CommentsModal(props: any) {
  const numberOfComments = Math.floor(Math.random() * 30 + 5);
  const { handleCloseModal, i } = props;

  return (
    <div className="comments">
      <div className="comments__header">
        <div className="comments__header__icon">
          <ImgComments />
        </div>
        <div className="comments__header__title">{numberOfComments} comments</div>
        <div className="comments__header__close" onClick={() => handleCloseModal(i)}>
          ✖
        </div>
      </div>
      <div className="comments__container">
        {Array.from({ length: numberOfComments }, (_, index) => (
          <Comment />
        ))}
      </div>
      <CommentAdd />
    </div>
  );
}
