// import React from 'react';
import './style.scss';

import ImgAudio from 'assets/icons/audio.svg';
import ImgGif from 'assets/icons/gif.svg';
import ImgImage from 'assets/icons/image.svg';
import ImgVideo from 'assets/icons/video.svg';

export default function PostTypes(props: any) {
  return (
    <div className="postTypes">
      <div className="postTypes__wrapper">
        <div className={`postTypes__type`}>
          <img src={ImgImage} />
        </div>
        <div className="postTypes__indicator" />
      </div>
      <div className="postTypes__wrapper">
        <div className={`postTypes__type postTypes__type--disabled`}>
          <img src={ImgVideo} />
        </div>
      </div>
      <div className="postTypes__wrapper">
        <div className={`postTypes__type postTypes__type--disabled`}>
          <img src={ImgGif} />
        </div>
      </div>
      <div className="postTypes__wrapper">
        <div className={`postTypes__type postTypes__type--disabled`}>
          <img src={ImgAudio} />
        </div>
      </div>
    </div>
  );
}
