// ****************************************************************************
// FAIR_TAGS
// ****************************************************************************

export const FAIR_TAGS = {
  // --- Injected tags ---
  // Not actual tags used in Tx, but injected into Redux store objects for convenience.
  modelAvatar: 'Model-Avatar',
  incomplete: 'Incomplete',
  // --- Fair SDK tags (from FairSDKWeb.utils.TAG_NAMES) ---
  // Spread operator can't be used as TS won't be able to use the values.
  protocolName: 'Protocol-Name',
  protocolVersion: 'Protocol-Version',
  appName: 'App-Name',
  customAppName: 'Custom-App-Name',
  appVersion: 'App-Version',
  customAppVersion: 'Custom-App-Version',
  contentType: 'Content-Type',
  unixTime: 'Unix-Time',
  modelName: 'Model-Name',
  modelCreator: 'Model-Creator',
  modelOperator: 'Model-Operator',
  modelTransaction: 'Model-Transaction',
  modelUser: 'Model-User',
  operationName: 'Operation-Name',
  notes: 'Notes',
  category: 'Category',
  avatarUrl: 'AvatarUrl',
  description: 'Description',
  operatorName: 'Operator-Name',
  operatorFee: 'Operator-Fee',
  conversationIdentifier: 'Conversation-Identifier',
  inferenceTransaction: 'Inference-Transaction',
  requestTransaction: 'Request-Transaction',
  responseTransaction: 'Response-Transaction',
  attachmentName: 'Attachment-Name',
  attachmentRole: 'Attachment-Role',
  saveTransaction: 'Save-Transaction',
  paymentQuantity: 'Payment-Quantity',
  paymentTarget: 'Payment-Target',
  scriptTransaction: 'Script-Transaction',
  scriptName: 'Script-Name',
  scriptCurator: 'Script-Curator',
  scriptOperator: 'Script-Operator',
  scriptUser: 'Script-User',
  voteFor: 'Vote-For',
  votedTransaction: 'Voted-Transaction',
  fileName: 'File-Name',
  allowFiles: 'Allow-Files',
  allowText: 'Allow-Text',
  registrationTransaction: 'Registration-Transaction',
  registrationFee: 'Registration-Fee',
  input: 'Input',
  contract: 'Contract',
  sequencerOwner: 'Sequencer-Owner',
  updateFor: 'Update-For',
  previousVersions: 'Previous-Versions',
  txOrigin: 'Transaction-Origin',
  assetNames: 'Asset-Names',
  prompt: 'Prompt',
  negativePrompt: 'Negative-Prompt',
  userCustomTags: 'User-Custom-Tags',
  nImages: 'N-Images',
  output: 'Output',
  outputConfiguration: 'Output-Configuration',
  contractSrc: 'Contract-Src',
  contractManifest: 'Contract-Manifest',
  initState: 'Init-State',
  license: 'License',
  derivation: 'Derivation',
  commercialUse: 'Commercial-Use',
  licenseFee: 'License-Fee',
  currency: 'Currency',
  expires: 'Expires',
  paymentAddress: 'Payment-Address',
  paymentMode: 'Payment-Mode',
  licenseConfig: 'License-Config',
  // --- ?? ---
} as const;

type FairTagKeys = keyof typeof FAIR_TAGS;
export type FairTag = (typeof FAIR_TAGS)[FairTagKeys];

// ****************************************************************************
// Social SDK tags
// ****************************************************************************

export const ARSON_TAGS = {
  protocolName: 'Protocol-Name',
  protocolVersion: 'Protocol-Version',
  appName: 'App-Name',
  appVersion: 'App-Version',
  contentType: 'Content-Type',
  unixTime: 'Unix-Time',
  notes: 'Notes',
  description: 'Description',
  input: 'Input',
  contractSrc: 'Contract-Src',
  contractManifest: 'Contract-Manifest',
  license: 'License',
  derivation: 'Derivation',
  commercialUse: 'Commercial-Use',
  profileHandle: 'Profile-Handle',
  profileTitle: 'Profile-Title',
  profileTxid: 'Profile-Txid',
  title: 'Title',
} as const;

type ArsonTagKeys = keyof typeof ARSON_TAGS;
export type ArsonTag = (typeof ARSON_TAGS)[ArsonTagKeys];

// ****************************************************************************
// findTag()
// ****************************************************************************

type All = FairTag | ArsonTag;

/**
 * Find a tag's value from a TxTag array.
 * Generics is optional, but helps to narrow-down the tag name autocompletion.
 *
 * Usage:
 *  - findTag(tags, 'Model-Transaction')
 *  - findTag(tags, TX_TAGS.modelTransaction)
 *  - findTag<FairTag>(tags, TX_TAGS.modelTransaction)
 *
 * @param tags
 * @param tagName
 */
export function findTag<T extends All>(tags: TxTag[] | undefined, tagName: T) {
  if (tags) {
    return tags.find((tag) => tag.name === tagName)?.value;
  }
  return undefined;
}

// ***************************************************************************
// earthof API tags
// ***************************************************************************

export const getEOTags = (backend: AppBackend, walletAddress: TxId) => {
  const EO_TAGS = {
    'earthof_v0.0': [{ name: 'myKey1', value: 'myValue1' }],
    'earthof_v0.1': [
      { name: 'Subsidizer-Name', value: 'earthof' },
      { name: 'Subsidizer-Version', value: '0.1' },
    ],
    'earthof_v0.2': [
      { name: 'Subsidized-For', value: `${walletAddress}` },
      { name: 'Subsidizer-Name', value: 'earthof' },
      { name: 'Subsidizer-Version', value: '0.2' },
    ],
  };

  // @ts-ignore
  return EO_TAGS[backend] || [];
};
