import { getMockUsers } from 'constants/mockdata';
import './style.scss';

export default function ActivityRow() {
  const users = getMockUsers();
  const user = users[Math.floor(Math.random() * 4)];
  const activities = [
    'commented on your transform.',
    'commented on your remix.',
    'replied to your comment.',
    'remixed your transformation.',
    'started following you.',
    'liked your comment.',
    'liked your transformation.',
    'liked your remix.',
    'and x others liked your remix.',
    'and x others liked your transformation.',
  ];
  const activity = Math.floor(Math.random() * 10);
  const preview = Math.floor(Math.random() * 12 + 1);

  return (
    <div className="activityRow">
      <div className="activityRow__avatar">
        <img src={user.avatar} />
      </div>
      <div className="activityRow__text">
        <span>{user.name}</span>
        <span>{activities[activity]}</span>
      </div>
      <div className="activityRow__timestamp">
        <span>1d</span>
      </div>
      <div className="activityRow__preview">
        <img src={`https://github.com/NewEstablishment/eo-resources/blob/main/discover/${preview}.jpeg?raw=true`} />
      </div>
    </div>
  );
}
