import React from 'react';
import './style.scss';

import { ReactComponent as ImgTransform } from 'assets/icons/transform.svg';
import ImgMessage from 'assets/icons/message.svg';

export default function CommentAdd(props: any) {
  // const [comment, setComment] = React.useState('');
  // const [confirming, setConfirming] = React.useState(false);

  function setConfirming(props: any) {}

  return (
    <>
      <div className="commentAdd">
        <div className="commentAdd__avatar">
          <img src={'https://avatars.githubusercontent.com/u/94839278?v=4'} />
        </div>
        <div className="commentAdd__input">
          <input
            type="text"
            //onChange={setComment}
            // value={comment}
            placeholder="Comment..."
          />
          <div className="commentAdd__send" onClick={() => setConfirming(true)}>
            <ImgTransform />
            <img src={ImgMessage} />
          </div>
        </div>
      </div>
    </>
  );
}
