import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style.scss';

import { ReactComponent as ImgPosts } from 'assets/icons/posts.svg';
import { ReactComponent as ImgDiscover } from 'assets/icons/discover.svg';
import { ReactComponent as ImgTransform } from 'assets/icons/transform.svg';
import { ReactComponent as ImgActivity } from 'assets/icons/activity.svg';
import { ReactComponent as ImgProfile } from 'assets/icons/profile.svg';

function Navigation() {
  const location = useLocation();
  const [activePage, setActivePage] = useState('posts');

  useEffect(() => {
    switch (true) {
      case location.pathname.includes('/post'):
        setActivePage('posts');
        break;
      case location.pathname.includes('/discover'):
        setActivePage('discover');
        break;
      case location.pathname.includes('/transform'):
        setActivePage('transform');
        break;
      case location.pathname.includes('/activity'):
        setActivePage('activity');
        break;
      case location.pathname.includes('/profile'):
        setActivePage('profile');
        break;
    }
  }, [location]);

  return (
    <div className="navigation">
      <Link to="/posts">
        <div className={`navigation__entry${activePage === 'posts' ? ' navigation__entry--active' : ''}`}>
          <ImgPosts />
        </div>
      </Link>
      <Link to="/discover">
        <div className={`navigation__entry${activePage === 'discover' ? ' navigation__entry--active' : ''}`}>
          <ImgDiscover />
        </div>
      </Link>
      <Link to="/transform">
        <div className={`navigation__entry${activePage === 'transform' ? ' navigation__entry--active' : ''}`}>
          <ImgTransform />
        </div>
      </Link>
      <Link to="/activity">
        <div className={`navigation__entry${activePage === 'activity' ? ' navigation__entry--active' : ''}`}>
          <ImgActivity />
        </div>
      </Link>
      <Link to="/profile">
        <div className={`navigation__entry${activePage === 'profile' ? ' navigation__entry--active' : ''}`}>
          <ImgProfile />
        </div>
      </Link>
    </div>
  );
}

export default Navigation;
