import React, { useState, useEffect, useRef } from 'react';
import PostActions from './actions';
import Remix from 'modals/remix';
import './style.scss';

const Post = React.memo((props: any) => {
  const { post } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isRemixing, setIsRemixing] = useState(false);
  const postRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const postElement = postRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (postElement) {
      observer.observe(postElement);
    }

    const cleanup = () => {
      if (postElement) {
        observer.unobserve(postElement);
      }
    };

    return cleanup;
  }, [post.id]);

  return (
    <div className="post" ref={postRef}>
      <div className="post__background" style={{ backgroundImage: `url(${post.uri})` }}>
        <Remix post={post} isRemixing={isRemixing} setIsRemixing={setIsRemixing} />
        <PostActions isRemixing={isRemixing} isVisible={isVisible} />
      </div>
    </div>
  );
});

export default Post;
