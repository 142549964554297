import { Route, Routes as Switch } from 'react-router-dom';
import Posts from 'pages/posts';
import DiscoverPage from 'pages/discover';
import TransformPage from 'pages/transform';
import ActivityPage from 'pages/activity';
import ProfilePage from 'pages/profile';
import SignIn from 'pages/signIn';
import TestArson from 'components/testArson';
import TestSequence from 'components/testSequence';

function Routes() {
  return (
    <Switch>
      <Route path="/" element={<Posts />} />
      <Route path="/posts" element={<Posts />} />
      <Route path="/post/:postId" element={<Posts />} />
      <Route path="/discover" element={<DiscoverPage />} />
      <Route path="/transform/tx" element={<TransformPage step="tx" />} />
      <Route path="/transform/tx/:promptId" element={<TransformPage step="tx" />} />
      <Route path="/transform/preview/:promptId/:responseIndex" element={<TransformPage step="preview" />} />
      <Route path="/transform/*" element={<TransformPage />} />
      <Route path="/activity" element={<ActivityPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/test" element={<TestSequence />} />
      <Route path="/arson" element={<TestArson />} />
      <Route path="/signIn" element={<SignIn />} />
    </Switch>
  );
}

export default Routes;
