import React from 'react';
import './style.scss';

interface Props {
  text: string;
}

export default function Details(props: Props) {
  const { text } = props;

  return (
    <div className="arsonDetails">
      <div className="arsonDetails__text">{text}</div>
    </div>
  );
}
