/**
 * `arweave-account` instance and helpers.
 */

import Account from 'arweave-account';
import { GATEWAYS } from 'constants/arweave';

let instance: Account | null = null;

export function getArweaveAccountInstance() {
  if (instance) {
    return instance;
  } else {
    throw new Error('ArweaveAccount not initialized.');
  }
}

export function initArweaveAccount(gatewayId: GatewayId) {
  const gateway = GATEWAYS[gatewayId];

  instance = new Account({
    gateway: {
      host: gateway.host,
      port: gateway.port,
      protocol: gateway.protocol,
      timeout: 20000,
      logging: false,
    },
    cacheIsActivated: false,
    cacheSize: 100,
    cacheTime: 60000,
  });
}

export async function fetchAccountInfo(address: string) {
  if (instance) {
    await instance.connect();
    return instance.get(address);
  } else {
    return null;
  }
}

export function getUserHandle(account: ArAccount | null) {
  if (account) {
    const { handle, profile } = account;
    const { handleName, name } = profile;
    return handleName || name || handle;
  }
  return null;
}
