import './style.scss';
import { Dropdown } from '@mui/base';
import { useMenu, MenuProvider } from '@mui/base/useMenu';
import { useMenuItem } from '@mui/base/useMenuItem';
import { Popper } from '@mui/base/Popper';
import { useMenuButton } from '@mui/base/useMenuButton';
import classnames from 'classnames';
import React, { HTMLProps } from 'react';

// ***************************************************************************
// Menu
// ***************************************************************************

interface MenuProps {
  children: React.ReactNode; // Should just be MenuButton and MenuList as children
}

const Menu = (props: MenuProps) => {
  return <Dropdown>{props.children}</Dropdown>;
};

// ***************************************************************************
// MenuButton
// ***************************************************************************

interface MenuButtonProps extends HTMLProps<HTMLButtonElement> {
  className?: string;
}

const MenuButton = React.forwardRef(function MenuButton(props: MenuButtonProps, ref: React.Ref<any>) {
  const { getRootProps: getButtonProps } = useMenuButton({ rootRef: ref });

  return (
    <button {...props} {...getButtonProps()} className={classnames(props.className, 'menu-button')} type="button" />
  );
});

// ***************************************************************************
// MenuList
// ***************************************************************************

interface MenuListProps {
  className?: string;
  children: React.ReactNode;
}

const MenuList = React.forwardRef(function MenuList(props: MenuListProps, ref: React.Ref<any>) {
  const { className, children, ...other } = props;
  const { open, triggerElement, contextValue, getListboxProps } = useMenu({ listboxRef: ref });

  return (
    <Popper open={open} anchorEl={triggerElement}>
      <ul className={classnames(className, 'menu-list')} {...other} {...getListboxProps()}>
        <MenuProvider value={contextValue}>{children}</MenuProvider>
      </ul>
    </Popper>
  );
});

// ***************************************************************************
// MenuItem
// ***************************************************************************

interface MenuItemProps {
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
}

const MenuItem = React.forwardRef(function MenuItem(props: MenuItemProps, ref: React.Ref<any>) {
  const { className, children, onClick, ...other } = props;

  const { getRootProps, disabled, focusVisible } = useMenuItem({ rootRef: ref });

  const classes = {
    'focus-visible': focusVisible,
    'menu-item': true,
    disabled,
  };

  return (
    <li {...other} {...getRootProps({ onClick: onClick ?? (() => {}) })} className={classnames(className, classes)}>
      {children}
    </li>
  );
});

// ***************************************************************************
// ***************************************************************************

export { Menu, MenuButton, MenuList, MenuItem };
