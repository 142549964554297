import { createSelector } from '@reduxjs/toolkit';
import { IComment } from 'social-sdk/dist/interfaces';

export const selectMyProfileIds = createSelector(
  (state: AppState) => state.social.profilesById,
  (state: AppState) => state.arweave.walletAddress,
  (profilesById, walletAddress) => {
    return Object.keys(profilesById).filter((id) => profilesById[id].state?.owner === walletAddress);
  }
);

export const selectProfileForId = createSelector(
  (state: AppState) => state.social.profilesById,
  (state: AppState, profileId: TxId) => profileId,
  (profilesById, profileId) => {
    return profilesById[profileId];
  }
);

export const selectPostIdsForProfileId = createSelector(
  (state: AppState) => state.social.postsById,
  (state: AppState, profileId: TxId) => profileId,
  (postsById, profileId) => {
    return Object.keys(postsById).filter((id) => postsById[id].state?.profileTxid === profileId);
  }
);

export const selectPostForId = createSelector(
  (state: AppState) => state.social.postsById,
  (state: AppState, postId: TxId) => postId,
  (postsById, postId) => {
    return postsById[postId];
  }
);

export const selectCommentsForPostId = createSelector(
  (state: AppState) => state.social.postsById,
  (state: AppState, postId: TxId | undefined) => postId,
  (postsById, postId = ''): IComment[] => {
    // [] Still figuring out how best to store comments in Store.
    //    Meanwhile, just grab from the Post's state since we are
    //    still storing everything there.
    const post = postsById[postId];
    const comments = post?.state.comments || [];
    return comments;
  }
);
