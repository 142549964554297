import { balanceStrToNum } from 'utils/number';
import { findTag } from 'utils/tags';

export function doTestPreviewInfo() {
  return (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();

    const modelId = state.prompt.modelId || '';
    const model = state.fair.modelsById[modelId];

    const scriptId = state.prompt.scriptId || '';
    const script = state.fair.scriptsById[scriptId];

    const operatorId = state.prompt.operatorId || '';
    const operator = state.fair.operatorsById[operatorId];

    const cost = balanceStrToNum(operator ? findTag(operator.tags, 'Operator-Fee') : null);
    const costStr = cost ? `${cost / 1000000} $U/item` : '';

    const previewInfo = {
      Prompt: state.prompt.text,
      Model: findTag(model?.tags, 'Model-Name') || '',
      Script: findTag(script?.tags, 'Script-Name') || '',
      Operator: findTag(operator?.tags, 'Operator-Name') || '',
      Cost: costStr,
      'Number of images': state.prompt.nImages,
    };

    return JSON.stringify(previewInfo, null, 2)
      .replace(/^\s+/gm, '') // Remove leading spaces
      .replace(/"([^"]+)":/g, '$1:\n ') // Remove surrounding quotes around keys and add newline
      .replace(/"([^"]+)"/g, '$1') // Remove quotes around string values
      .replace(/,\s*$/gm, '\n') // Remove trailing commas
      .replace(/[{}]/g, ''); // Remove braces
  };
}
