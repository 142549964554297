import React from 'react';
import classnames from 'classnames';
import Button from 'components/base/button';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { doProfileCreate, doProfileFetchMine } from 'store/social/thunks';
import uiSlice from 'store/ui/slice';
import './style.scss';

const { updateProfileForm } = uiSlice.actions;

export default function CreateProfile() {
  const dispatch = useAppDispatch();
  const form = useAppSelector((state) => state.ui.profileForm);
  const busy = useAppSelector((state) => state.social.flags.creatingProfile);
  const [output, setOutput] = React.useState({ profileId: '', errorMsg: '' });
  const modsWrapper = { 'createProfile--busy': busy };
  const modsCreateBtn = { 'createProfile__create--busy': busy };

  const updateForm = (e: any) => {
    switch (e.target.name) {
      case 'topics':
        dispatch(updateProfileForm({ [e.target.name]: e.target.value.split('\n') }));
        break;
      default:
        dispatch(updateProfileForm({ [e.target.name]: e.target.value }));
        break;
    }
  };

  const updateOutput = (key: string, value: string) => setOutput({ ...output, [key]: value });

  const create = async () => {
    Promise.resolve()
      .then(() => updateOutput('profileId', ''))
      .then(() => updateOutput('errorMsg', ''))
      .then(() => dispatch(doProfileCreate()))
      .then((res) => updateOutput('profileId', Object.values(res.profiles)[0].txid))
      .then(() => dispatch(doProfileFetchMine())) // optional, but might as well get actual contract state into redux
      .catch((err) => updateOutput('errorMsg', err.message));
  };

  return (
    <div className={classnames('arson', 'createProfile', modsWrapper)}>
      <input type="text" name="handle" defaultValue={form.handle} onChange={updateForm} placeholder="Handle" />

      <input type="text" name="title" defaultValue={form.title} onChange={updateForm} placeholder="Title" />

      <textarea
        name="topics"
        defaultValue={form.topics?.join('\n') || ''}
        onChange={updateForm}
        placeholder="Topics (one per line)"
      />

      <textarea name="description" value={form.description} onChange={updateForm} placeholder="Description" />

      <Button
        label="🧓 Create Profile"
        onClick={create}
        className={classnames('createProfile__create', modsCreateBtn)}
      />

      {output.errorMsg && <div className="createProfile__error">{output.errorMsg}</div>}

      {output.profileId && (
        <div className="createProfile__result">
          <a href={`https://arlocal.zephyrdev.xyz/${output.profileId}`} target="_blank" rel="noreferrer">
            https://arlocal.zephyrdev.xyz/{output.profileId.slice(0, 4)}~{output.profileId.slice(-4)}
          </a>
        </div>
      )}
    </div>
  );
}
