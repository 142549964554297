import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Post from './post';
import './style.scss';
import { getMockPosts, getMockEntries, shuffle } from 'constants/mockdata';

const MOCK_POSTS = getMockPosts(8);
var MOCK_ENTRIES = shuffle(getMockEntries(50));

function Posts() {
  const { postId } = useParams();
  const [activeTab, setActiveTab] = useState('earthof');
  const followingList = useRef<HTMLDivElement>(null);
  const earthofList = useRef<HTMLDivElement>(null);

  if (!window.location.href.includes('posts') && postId) {
    if (activeTab !== 'earthof') setActiveTab('earthof');
    const postPosition = MOCK_ENTRIES.findIndex((post: any) => post.id === postId);

    if (postPosition > -1) {
      const element = MOCK_ENTRIES.splice(postPosition, 1)[0];
      MOCK_ENTRIES.unshift(element);
    }

    if (earthofList && earthofList.current) {
      earthofList.current.scrollTop = 0;
    }
  }

  useEffect(() => {
    if (activeTab === 'following') {
    }
  }, [activeTab]);

  const RenderItem = React.memo(({ type, item }: { type: string; item: any }) => {
    const updatedItem = {
      ...item,
      uri: `https://github.com/NewEstablishment/eo-resources/blob/main/${type === 'following' ? 'posts' : 'discover'}/${
        item.id
      }.jpeg?raw=true`,
    };

    return (
      <div key={item.id} className="postWrapper">
        <Post post={updatedItem} />
      </div>
    );
  });

  return (
    <>
      <div className={`posts ${activeTab === 'earthof' ? 'posts--right' : ''}`}>
        <div className="scrollList" ref={followingList}>
          {MOCK_POSTS.map((entry: any, i: number) => (
            <RenderItem key={entry.id} type="following" item={entry} />
          ))}
        </div>
        <div className="scrollList" ref={earthofList}>
          {MOCK_ENTRIES.map((entry: any, i: number) => (
            <RenderItem key={entry.id} type="earthof" item={entry} />
          ))}
        </div>
        <div className="postsHeader">
          <div className="postsHeader__options">
            <div
              className={activeTab === 'following' ? 'active' : ''}
              onClick={() => {
                window.history.pushState({}, '', '#/posts');
                setActiveTab('following');
              }}
            >
              Following
            </div>
            <span>|</span>
            <div className={activeTab === 'earthof' ? 'active' : ''} onClick={() => setActiveTab('earthof')}>
              EARTHoF.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Posts;
