import React from 'react';
import classnames from 'classnames';
import BackendSelect from 'components/backendSelect';
import Button from 'components/base/button';
import ConfigurationSelect from 'components/configurationSelect';
import Conversation from 'components/conversation';
import ConversationSelect from 'components/conversationSelect';
import DialogConfirm, { DialogConfirmProps } from 'components/dialogs/dialogConfirm';
import GatewaySelect from 'components/gatewaySelect';
import ModelSelect from 'components/modelSelect';
import OperatorSelect from 'components/operatorSelect';
import Prompt from 'components/prompt';
import ScriptSelect from 'components/scriptSelect';
import TestRow from 'components/testRow';
import UserMenu from 'components/userMenu';
import WalletBalance from 'components/walletBalance';
import fairSlice from 'store/fair/slice';
import { doFetchModels, doSendTextPrompt } from 'store/fair/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import promptSlice from 'store/prompt/slice';
import { sendAlert } from 'utils/error';

import './style.scss';
import { doTestPreviewInfo } from './actions';

export default function TestSequence() {
  const dispatch = useAppDispatch();
  const sending = useAppSelector((state) => state.fair.fetching.sendingPrompt);

  const backend = useAppSelector((state) => state.settings.backend);
  const modelId = useAppSelector((state) => state.prompt.modelId);
  const scriptId = useAppSelector((state) => state.prompt.scriptId);
  const operatorId = useAppSelector((state) => state.prompt.operatorId);
  const conversationId = useAppSelector((state) => state.prompt.conversationId);

  const showChat = modelId && scriptId && operatorId && (backend.startsWith('earthof') || conversationId);

  const sendPrompt = () => {
    const dialogProps: DialogConfirmProps = {
      okText: 'OK',
      cancelText: 'Cancel',
      title: 'Send prompt?',
      body: <div className="previewInfo">{dispatch(doTestPreviewInfo())}</div>,
      onOk: () => dispatch(doSendTextPrompt()).catch(sendAlert),
    };

    dispatch(openModal(DialogConfirm, dialogProps));
  };

  const clearCache = () => {
    dispatch(promptSlice.actions.reset());
    dispatch(fairSlice.actions.clearFairCache());
    dispatch(doFetchModels());
  };

  const openConfigDialog = () => {
    const ConfigDialog = () => (
      <div className="testSequence__configDialog">
        <TestRow title="nImages">
          <ConfigurationSelect />
        </TestRow>
        <TestRow title="generateAssets">'fair-protocol'</TestRow>
      </div>
    );
    dispatch(openModal(ConfigDialog, {}));
  };

  return (
    <div className={classnames('testSequence', { 'testSequence--busy': sending })}>
      <div className="testSequence__topSection">
        <h1>Dev</h1>
        <Button className="testSequence__clearCache" label="Clear Cache" onClick={clearCache} />
        <UserMenu />
      </div>

      <TestRow title="Gateway">
        <GatewaySelect type="main" />
        <WalletBalance type="main" />
      </TestRow>

      <TestRow title="Backend">
        <BackendSelect />
      </TestRow>

      <TestRow title="Models">
        <ModelSelect />
      </TestRow>

      <TestRow title="Scripts">
        <ScriptSelect />
      </TestRow>

      <TestRow title="Operators">
        <OperatorSelect />
      </TestRow>

      <TestRow title="Conversations">
        <ConversationSelect />
      </TestRow>

      {showChat && (
        <TestRow title="Chat">
          <div className="testSequence__chat">
            <Prompt />
            <Button label="⚙️" onClick={openConfigDialog} className="configButton" />
            <Button label="Send" onClick={sendPrompt} />
          </div>
          <Conversation />
        </TestRow>
      )}
    </div>
  );
}
