import { createSelector } from '@reduxjs/toolkit';
import { EMPTY } from 'constants/empty';
import { findTag } from 'utils/tags';

// Most selectors here by default will "select" items based on the current
// `state.prompt.xxx` settings.
// To select an arbitrary item, refactor and/or duplicate existing selectors and
// add a "For??" suffix.
// (e.g. duplicate `selectConversations` => `selectConversationsForScriptId`).

export const selectModels = createSelector(
  (state: AppState) => state.fair.modelsById,
  (modelsById) => Object.values(modelsById)
);

/**
 * Returns the Conversation metas for the currently-selected Script.
 */
export const selectConversations = createSelector(
  (state: AppState) => state.arweave.walletAddress,
  (state: AppState) => state.fair.conversationsById,
  (state: AppState) => state.prompt.scriptId,
  (state: AppState) => state.fair.scriptsById,
  (walletAddress, conversationsById, scriptId, scriptsById) => {
    if (scriptId && scriptsById[scriptId]) {
      const scriptTransaction = findTag(scriptsById[scriptId].tags, 'Script-Transaction');
      const allConversations = Object.values(conversationsById);
      return allConversations.filter((c) => {
        return c.owner.address === walletAddress && findTag(c.tags, 'Script-Transaction') === scriptTransaction;
      });
    }
    return EMPTY.ARR;
  }
);

export const generatePromptQueryKey = (
  backend: AppBackend,
  walletAddress: TxId | null,
  scriptId: TxId | undefined,
  conversationId: TxId | undefined
) => {
  if (backend && walletAddress && scriptId) {
    switch (backend) {
      case 'fair-sdk':
        return conversationId
          ? `${backend}.${walletAddress.slice(0, 8)}.${scriptId.slice(0, 8)}.${conversationId.slice(0, 8)}`
          : '';
      case 'earthof_v0.1':
      case 'earthof_v0.0':
        return `${backend}.${walletAddress.slice(0, 8)}.${scriptId.slice(0, 8)}`;
    }
  }

  return '';
};

/**
 * Returns the Prompt metas for the currently-selected Conversation.
 */
export const selectPrompts = createSelector(
  (state: AppState) => state.settings.backend,
  (state: AppState) => state.prompt.scriptId,
  (state: AppState) => state.prompt.conversationId,
  (state: AppState) => state.fair.conversationsById,
  (state: AppState) => state.fair.scriptsById,
  (state: AppState) => state.fair.promptsById,
  (state: AppState) => state.arweave.walletAddress,
  (backend, scriptId, conversationId, conversationsById, scriptsById, promptsById, walletAddress) => {
    if (scriptId && walletAddress) {
      const script = scriptsById[scriptId];
      const conversation = conversationsById[conversationId || ''];
      const scriptTransaction = findTag(script.tags, 'Script-Transaction');
      const conversationIdentifier = findTag(conversation?.tags, 'Conversation-Identifier');

      const conditions: ((p: TxNode) => boolean)[] =
        backend === 'fair-sdk'
          ? [
              (p: TxNode) => p.owner.address === walletAddress,
              (p: TxNode) => findTag(p.tags, 'Script-Transaction') === scriptTransaction,
              (p: TxNode) => findTag(p.tags, 'Conversation-Identifier') === conversationIdentifier,
            ]
          : [
              (p: TxNode) => p.owner.address !== walletAddress, // * NOTE_1
              (p: TxNode) => findTag(p.tags, 'Script-Transaction') === scriptTransaction,
            ];

      // * NOTE_1: The earthof transaction is owned by another wallet. Instead
      // of committing the wallet address, we just do a `!==` against the user's
      // current wallet. This logic will only work if the cache is cleared when
      // the user switches wallets (@see effWalletAddressChanged()). If not,
      // any previously-logged-out prompts will be incorrectly captured.

      const allPrompts = Object.values(promptsById);
      return allPrompts
        .filter((p) => conditions.every((condition) => condition(p)))
        .sort((a, b) => {
          const timeA = Number(findTag(a.tags, 'Unix-Time'));
          const timeB = Number(findTag(b.tags, 'Unix-Time'));
          return timeB - timeA;
        });
    }
    return EMPTY.ARR;
  }
);

export const selectPromptsWithNoResponse = createSelector(
  (state: AppState) => selectPrompts(state),
  (state: AppState) => state.fair.responsesById,
  (prompts, responsesById) => {
    const responses = Object.values(responsesById);
    return prompts.filter((p) => !responses.some((r) => findTag(r.tags, 'Request-Transaction') === p.id));
  }
);

export const selectPromptForId = createSelector(
  (state: AppState) => state.fair.promptsById,
  (state: AppState, promptId: TxId | undefined) => promptId,
  (promptsById, promptId) => {
    return promptsById[promptId || ''];
  }
);

/**
 * Returns all Responses for the currently-selected Conversation.
 *
 * Conversation ID filter is skipped here so that it works for all backends.
 * It is assumed that the client will need to find the matching prompt anyway,
 * so providing more data here is fine at the moment.
 */
export const selectResponses = createSelector(
  (state: AppState) => state.prompt.scriptId,
  (state: AppState) => state.fair.scriptsById,
  (state: AppState) => state.fair.responsesById,
  (scriptId, scriptsById, responsesById) => {
    if (scriptId) {
      const script = scriptsById[scriptId];
      const scriptTransaction = findTag(script.tags, 'Script-Transaction');

      // const conversation = conversationsById[conversationId || ''];
      // const conversationIdentifier = findTag(conversation?.tags, 'Conversation-Identifier');

      const allResponse = Object.values(responsesById);
      return allResponse.filter(
        (r) => findTag(r.tags, 'Script-Transaction') === scriptTransaction
        // findTag(r.tags, 'Conversation-Identifier') === conversationIdentifier
      );
    }

    return EMPTY.ARR;
  }
);

export const selectResponsesForPromptId = createSelector(
  (state: AppState) => state.fair.responsesById,
  (state: AppState, promptId: TxId | undefined) => promptId,
  (responsesById, promptId) => {
    const allResponse = Object.values(responsesById);
    return allResponse.filter((r) => findTag(r.tags, 'Request-Transaction') === promptId);
  }
);
