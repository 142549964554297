import { ArweaveWebWallet } from 'arweave-wallet-connector';
import { WALLET_PERMISSIONS } from 'constants/arweave';
import { str } from 'constants/strings';
import { IMAGE_URLS } from 'constants/imageUrls';
import { createArweaveInstance, getMainArweaveInstance, initMainArweaveInstance } from 'lib/arweave';
import { initArweaveAccount } from 'lib/arweaveAccount';
import { FairSDKWeb } from 'lib/fair';
import arweaveSlice from 'store/arweave/slice';
import uiSlice from 'store/ui/slice';

const gArweaveAppWallet = new ArweaveWebWallet({
  name: process.env.REACT_APP_NAME,
  logo: IMAGE_URLS.APP_ICON,
});

const gFlags = {
  arweaveAppIFrameClosedOrResponded: false,
  arconnectWalletSwitchListenerAdded: false,
};

// ***************************************************************************
// Library initialization
// ***************************************************************************

export function doInitArweaveLibs() {
  return (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    initMainArweaveInstance(state.arweave.gatewayId);

    const arweave = getMainArweaveInstance();
    initArweaveAccount(state.arweave.gatewayId);
    FairSDKWeb.initFair(state.arweave.socialGatewayId, arweave, state.arweave.warpEnvironment);

    dispatch(doReconnectWallet());
  };
}

// ***************************************************************************
// Wallet service
// ***************************************************************************

export function doReconnectWallet() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const { walletType } = getState().arweave;

    if (walletType) {
      await dispatch(doWalletConnect(walletType));
    }
  };
}

// ***************************************************************************
// Wallet connection
// ***************************************************************************

export function doWalletConnect(type: WalletEnum) {
  return async (dispatch: AppDispatch) => {
    switch (type) {
      case 'arconnect':
        return dispatch(doArConnect());
      case 'arweave.app':
        return dispatch(doArweaveApp());
      default:
        throw new Error('Invalid wallet type');
    }
  };
}

export function doWalletDisconnect() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    if (window.arweaveWallet) {
      // arweave.app technically already disconnects when the iframe is closed
      await window.arweaveWallet.disconnect();
    }

    dispatch(arweaveSlice.actions.clearAccountInfo());
    dispatch(arweaveSlice.actions.setWalletInfo({ address: null, type: null }));
  };
}

function doArConnect() {
  return async (dispatch: AppDispatch, getState: GetState): Promise<string | void> => {
    const state = getState();
    const { walletAddress } = state.arweave;

    if (!walletAddress) {
      if (window.arweaveWallet) {
        try {
          await window.arweaveWallet.connect(WALLET_PERMISSIONS as any);

          if (!gFlags.arconnectWalletSwitchListenerAdded) {
            // Attach throughout app-lifetime, no need to clean up.
            window.addEventListener('walletSwitch', handleWalletSwitched);
            gFlags.arconnectWalletSwitchListenerAdded = true;
          }

          const address = await window.arweaveWallet.getActiveAddress();
          dispatch(arweaveSlice.actions.setWalletInfo({ address, type: 'arconnect' }));
          return address;
        } catch (e: any) {
          alert(e);
        }
      } else {
        alert(str.walletConnectNotFound);
      }
    }

    // --- helpers --------------------
    type WalletSwitchedEvent = { detail: { address?: string } };

    function handleWalletSwitched(event: WalletSwitchedEvent) {
      if (event?.detail?.address) {
        dispatch(arweaveSlice.actions.setWalletInfo({ address: event.detail.address, type: 'arconnect' }));
      }
    }
  };
}

function doArweaveApp() {
  return async (dispatch: AppDispatch, getState: GetState): Promise<string | void> => {
    try {
      gArweaveAppWallet.off('change', handleWalletSwitched);
      gArweaveAppWallet.setUrl('arweave.app');
      gFlags.arweaveAppIFrameClosedOrResponded = false;

      // 1. [Promise] resolves with either the wallet address or Error if iframe is closed. Never rejects.
      const userResponsePromise = new Promise<string | Error>(async (resolve) => {
        try {
          const address = await gArweaveAppWallet.connect();
          resolve(address);
        } catch (error: any) {
          const iframeClosedWithoutConnecting = error === undefined && !gArweaveAppWallet.connected;
          resolve(iframeClosedWithoutConnecting ? new Error('User did not connect to wallet') : error);
        } finally {
          gFlags.arweaveAppIFrameClosedOrResponded = true;
        }
      });

      // 2. [Promise] alerts if user did not respond. Always rejects so that it does nothing in Step 3 below.
      const alertPromise = new Promise<void>((_, reject) => {
        setTimeout(() => {
          const failureMessage = '\nPlease respond to the Arweave Wallet popup.';
          !gFlags.arweaveAppIFrameClosedOrResponded && console.log(failureMessage); // alert(failureMessage)
          reject(new Error(failureMessage));
        }, 10000);
      });

      // 3. Captain Planet: by your powers combined...
      const result = await Promise.any([userResponsePromise, alertPromise]);

      if (typeof result === 'string') {
        gArweaveAppWallet.on('change', handleWalletSwitched);
        dispatch(arweaveSlice.actions.setWalletInfo({ address: result, type: 'arweave.app' }));
        return result;
      } else if (result instanceof Error) {
        // User closed without connecting. I think we just be silent about it.
      } else {
        alert(`Unexpected result: ${result}`); // Should never happen
      }
    } catch (e: any) {
      alert(e);
    }

    // --- helpers --------------------
    function handleWalletSwitched(newAddress: string | undefined) {
      if (newAddress) {
        dispatch(arweaveSlice.actions.setWalletInfo({ address: newAddress, type: 'arweave.app' }));
      } else {
        dispatch(doWalletDisconnect());
      }
    }
  };
}

// ***************************************************************************
// ***************************************************************************

export function doWalletBalance() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const { walletAddress } = state.arweave;

    dispatch(uiSlice.actions.setFetchingBalance(true));

    type Balances = { currency: 'winston' | 'winstonArson' | 'u'; value: string | null }[];
    const balances: Balances = [];

    if (walletAddress) {
      const arweave = getMainArweaveInstance();
      try {
        const winston = await arweave.wallets.getBalance(walletAddress);
        balances.push({ currency: 'winston', value: winston });
      } catch (err: any) {
        balances.push({ currency: 'winston', value: null });
      }

      // [] This is throwaway code, so whatever
      const arsonArweave = createArweaveInstance(state.arweave.socialGatewayId);
      try {
        const winstonArson = await arsonArweave.wallets.getBalance(walletAddress);
        balances.push({ currency: 'winstonArson', value: winstonArson });
      } catch (err: any) {
        balances.push({ currency: 'winstonArson', value: null });
      }

      // [] Should create generic U utils, rather than use Fair's?
      await FairSDKWeb.connectWallet();
      const u = await FairSDKWeb.getUBalance();
      balances.push({ currency: 'u', value: u.toString() });
    } else {
      balances.push({ currency: 'winston', value: null });
      balances.push({ currency: 'u', value: null });
    }

    dispatch(arweaveSlice.actions.balanceUpdated(balances));
    dispatch(uiSlice.actions.setFetchingBalance(false));
  };
}

let gSignMessageDone = false;

/**
 * doWalletSignMessage
 *
 * [] It's not the cleanest interface, but at least avoids exposing
 *    gArweaveAppWallet. We should probably follow the same method as
 *    WalletKit's "Strategy" pattern.
 *
 * @param msg
 */
export function doWalletSignMessage(msg: string) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const msgEncoded = new TextEncoder().encode(msg);

    switch (getState().arweave.walletType) {
      case 'arconnect':
        // @ts-ignore
        return window.arweaveWallet.signMessage(msgEncoded);
      case 'arweave.app':
        gSignMessageDone = false;
        const failureMsg = 'Please respond to the Arweave Wallet popup.';
        const promise = gArweaveAppWallet.signMessage(msgEncoded, { hashAlgorithm: 'SHA-256' }).then((result) => {
          gSignMessageDone = true;
          return result;
        });
        const promiseTimeout = new Promise(() =>
          setTimeout(() => {
            if (!gSignMessageDone) {
              alert(failureMsg);
            }
          }, 5000)
        );
        return Promise.race([promise, promiseTimeout]);
      default:
        throw new Error('Invalid wallet type');
    }
  };
}
