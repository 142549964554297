/**
 * Redux effector routines.
 * https://redux-toolkit.js.org/api/createListenerMiddleware
 */

import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';
import { effArweaveConfigChanged, effAutoFetchMyPosts, effWalletAddressChanged } from 'store/arweave/effects';
import { effSendAnalyticsExample } from 'store/example/slice';
import { effFetchIncompletePrompts } from 'store/fair/effects';

type AppStartListening = TypedStartListening<AppState, AppDispatch>;

const effectors = createListenerMiddleware();
const startListening = effectors.startListening as AppStartListening;

startListening(effSendAnalyticsExample);
startListening(effArweaveConfigChanged);
startListening(effWalletAddressChanged);
startListening(effAutoFetchMyPosts);
startListening(effFetchIncompletePrompts);

export default effectors;
