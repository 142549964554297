import ImgAdd from 'assets/icons/add.png';
import ImgLink from 'assets/icons/link.svg';
import Image from 'components/base/image';
import { getUserHandle } from 'lib/arweaveAccount';
import { useAppSelector } from 'store/hooks';
import Gallery from './gallery';
import './style.scss';

const DEFAULT_AVATAR = 'https://avatars.githubusercontent.com/u/94839278?v=4?';

export default function UserProfile() {
  const arProfile = useAppSelector((state) => state.arweave.arProfile);
  const handle = getUserHandle(arProfile);
  const avatarSrc = arProfile?.profile.avatar || DEFAULT_AVATAR;

  console.assert(avatarSrc.startsWith('ar://') || avatarSrc.startsWith('https://'));

  return (
    <div className="userProfile">
      <div className="avatarContainer">
        <Image className="avatarContainer__avatar" src={avatarSrc} alt="profile" />
        <img src={ImgAdd} className="avatarContainer__add" />
      </div>

      <div className="userProfile__handle">{`@${handle}`}</div>
      <div className="userProfile__meta">
        <div className="userProfile__meta__column">
          <div className="userProfile__meta__number">300</div>
          <div className="userProfile__meta__description">Followers</div>
        </div>
        <div className="userProfile__meta__column">
          <div className="userProfile__meta__number">200</div>
          <div className="userProfile__meta__description">Followers</div>
        </div>
        <div className="userProfile__meta__column">
          <div className="userProfile__meta__number">2200</div>
          <div className="userProfile__meta__description">Followers</div>
        </div>
      </div>

      <div>
        <input type="button" value="Edit" />
      </div>

      <div className="userProfile__bio">
        <p>{arProfile?.profile?.bio}</p>
        <div>
          <img src={ImgLink} /> https//:www.linkhere.com
        </div>
      </div>

      <Gallery />
      <div style={{ width: '100%', height: 64, backgroundColor: 'black' }} />
    </div>
  );
}
