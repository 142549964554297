import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPostResult, IProfileResult } from 'social-sdk/dist/interfaces/api';
import { MakeObject } from 'utils/objects';

interface SocialState {
  profilesById: { [key: TxId]: IProfileResult };
  postsById: { [key: TxId]: IPostResult };
  flags: {
    creatingProfile?: boolean;
    creatingPost?: boolean;
    creatingComment?: boolean;
    fetchingMyProfiles?: boolean;
    fetchingMyPosts?: boolean;
    fetchingComments?: boolean;
  };
}

const socialSlice = createSlice({
  name: 'social',
  initialState: MakeObject<SocialState>({
    profilesById: {},
    postsById: {},
    flags: {},
  }),
  reducers: {
    setFlagOn: (state, action: PayloadAction<keyof SocialState['flags']>) => {
      state.flags[action.payload] = true;
    },
    setFlagOff: (state, action: PayloadAction<keyof SocialState['flags']>) => {
      state.flags[action.payload] = false;
    },
    profilesFetched: (state, action: PayloadAction<{ [key: TxId]: IProfileResult }>) => {
      Object.assign(state.profilesById, action.payload);
    },
    postsFetched: (state, action: PayloadAction<{ [key: TxId]: IPostResult }>) => {
      Object.assign(state.postsById, action.payload);
    },
  },
});

export default socialSlice;
