import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames';
import DialogConfirm, { DialogConfirmProps } from 'components/dialogs/dialogConfirm';
import PostTypes from 'components/cards/postTypes';
import PostStyles from 'components/cards/postStyles';
import PostPreviews from 'components/cards/postPreviews';
import PostFinalPreview from 'components/cards/postFinalPreview';
import PostProgress from 'components/cards/postProgress';
import Prompt from 'components/prompt';
import { selectPromptForId, selectResponsesForPromptId } from 'store/fair/selectors';
import { doFetchPromptResponses } from 'store/fair/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import { sendAlert } from 'utils/error';
import { findTag } from 'utils/tags';
import { doFauxInit, doFauxStep, doGetStubPreviewInfo } from './actions';
import './style.scss';

interface TransformProps {
  step?: 'tx' | 'preview';
}

function Transform(props: TransformProps) {
  const { step } = props;
  const { promptId, responseIndex } = useParams<{ promptId: TxId; responseIndex: string }>();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(doFauxInit(promptId));
  }, [promptId, dispatch]);

  return (
    <div className={classnames('transformPage')}>
      {step === undefined && <Step1 />}
      {step === 'tx' && <Step2 promptId={promptId} />}
      {step === 'preview' && <Step3 promptId={promptId} responseIndex={Number(responseIndex)} />}
    </div>
  );
}

// ****************************************************************************
// ****************************************************************************

const PromptSection = (props: { readOnly?: boolean }) => {
  return (
    <>
      <h1 style={{ textAlign: 'center' }}>Transform</h1>
      <div className={classnames('transformPage__prompt', { disabled: props.readOnly })}>
        <Prompt readOnly={props.readOnly} />
      </div>
    </>
  );
};

// ****************************************************************************
// ****************************************************************************

interface NavigateSectionProps {
  onBack?: () => void;
  onNext?: () => void;
  nextDisabled?: boolean;
  extras?: any;
}

const NavigateSection = (props: NavigateSectionProps) => {
  const { onBack, onNext, nextDisabled } = props;
  return (
    <div className="transformPage__navigation">
      {props.extras}
      {onBack && <input type="button" value="Back" onClick={onBack} />}
      {onNext && <input type="button" value="Next" onClick={onNext} disabled={nextDisabled} />}
    </div>
  );
};

// ****************************************************************************
// ****************************************************************************

const Step1 = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ask = async () => {
    const dialogProps: DialogConfirmProps = {
      okText: 'OK',
      cancelText: 'Cancel',
      title: 'Send prompt?',
      body: <pre>{dispatch(doGetStubPreviewInfo())}</pre>,
      onOk: () => run(),
    };
    dispatch(openModal(DialogConfirm, dialogProps));
  };

  const run = async () => {
    Promise.resolve()
      .then(() => navigate(`/transform/tx`))
      .then(() => dispatch(doFauxStep()))
      .then((newPromptId) => navigate(`/transform/tx/${newPromptId}`))
      .catch((err: any) => {
        navigate(`/transform`);
        sendAlert(err);
      });
  };

  const extras = (
    <>
      <input type="button" value="🔥" onClick={() => navigate('/arson')} />
      <input type="button" value="⚙️" onClick={() => navigate('/test')} />
    </>
  );

  return (
    <>
      <PromptSection />
      <div className="transformPage__types">
        <PostTypes />
      </div>
      <div className="transformPage__styles">
        <PostStyles />
      </div>
      <NavigateSection onNext={ask} extras={extras} />
    </>
  );
};

// ****************************************************************************
// ****************************************************************************

const Step2 = (props: { promptId?: TxId }) => {
  const { promptId } = props;
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [activePreview, setActivePreview] = React.useState(0);

  const dispatch = useAppDispatch();
  const prompt = useAppSelector((state) => selectPromptForId(state, promptId));
  const nImages = Number(findTag(prompt?.tags, 'N-Images') || 4);
  const responses = useAppSelector((state) => selectResponsesForPromptId(state, promptId));

  const processing = !promptId;
  const pending = promptId && responses.length < nImages;

  React.useEffect(() => {
    if (pending) {
      const fetchResponses = () => {
        Promise.resolve()
          .then(() => setLoading(true))
          .then(() => dispatch(doFetchPromptResponses([promptId])))
          .catch(sendAlert)
          .finally(() => setLoading(false));
      };

      fetchResponses();
      const interval = setInterval(fetchResponses, 15000);
      return () => clearInterval(interval);
    }
  }, [pending, promptId, dispatch]);

  return (
    <div className={classnames('transformPage')}>
      <PromptSection readOnly key={promptId} />
      <PostProgress stop={processing ? 25 : pending ? 70 : 100} />
      <PostPreviews
        promptId={promptId || 'disable mock'}
        activePreview={activePreview}
        setActivePreview={setActivePreview}
        loading={loading}
      />
      <NavigateSection
        onBack={() => navigate(`/transform/`)}
        onNext={() => navigate(`/transform/preview/${promptId}/${activePreview - 1}`)}
        nextDisabled={activePreview === 0}
      />
    </div>
  );
};

// ****************************************************************************
// ****************************************************************************

const Step3 = (props: { promptId: TxId | undefined; responseIndex: number }) => {
  const { promptId, responseIndex } = props;
  const navigate = useNavigate();

  React.useEffect(() => {
    // For now, go back to previous step if inputs don't make sense.
    // The better solution is to indicate the error on this screen and
    // let the user decide where to go.
    if (!promptId || Number.isNaN(responseIndex)) {
      navigate(`/transform/tx/${promptId}`);
    }
  }, [promptId, responseIndex, navigate]);

  return (
    <div className={classnames('transformPage')}>
      <PromptSection readOnly key={promptId} />
      <PostFinalPreview promptId={promptId!} responseIndex={responseIndex} />
      <NavigateSection onBack={() => navigate(`/transform/tx/${promptId}`)} />
    </div>
  );
};

export default Transform;
