import React, { SyntheticEvent } from 'react';
import { useAppDispatch, useAppSelector, useAppStore } from 'store/hooks';
import promptSlice from 'store/prompt/slice';
import { useDebouncedCallback } from 'use-debounce';
import './style.scss';

interface Props {
  readOnly?: boolean;
}

type ContentEditableEvent = SyntheticEvent<HTMLDivElement, Event> & {
  nativeEvent: { target: { innerHTML: string } };
};

function Prompt() {
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const [initialValue] = React.useState(() => store.getState().prompt.text);

  const syncText = (value: string) => dispatch(promptSlice.actions.textUpdated(value));
  const syncTextDebounced = useDebouncedCallback((value) => syncText(value), 500);
  const handleInput = (e: ContentEditableEvent) => syncTextDebounced(e.nativeEvent.target.innerHTML);

  return (
    <div
      suppressContentEditableWarning={true} // !!
      className="prompt"
      contentEditable="true"
      onInput={handleInput}
    >
      {initialValue}
    </div>
  );

  // (!!) The suppression is only valid while the component does not listen to
  // ANY live states (i.e. no props, no `useAppSelector` subscription).
  //
  // This also coincides with the intention of not re-rendering while typing
  // (i.e. `initialValue` locked to a one-time read), so it's all good for now.
}

export default withSubscriptionSwitch(Prompt);

// ****************************************************************************
// ****************************************************************************

function PromptRO() {
  return <div className="prompt">{useAppSelector((state) => state.prompt.text)}</div>;
}

function withSubscriptionSwitch(Component: React.ComponentType<Props>) {
  return function (props: Props) {
    return props.readOnly ? <PromptRO /> : <Component />;
  };
}
