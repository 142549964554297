import React from 'react';
import classnames from 'classnames';
import Button from 'components/base/button';
import { CommentType, ICreateCommentResult } from 'social-sdk/dist/interfaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectPostForId, selectProfileForId } from 'store/social/selectors';
import { doCommentCreate, doPostFetchMine } from 'store/social/thunks';
import uiSlice from 'store/ui/slice';
import { findTag } from 'utils/tags';
import './style.scss';

const { updateCommentForm } = uiSlice.actions;

interface Props {
  profileId: string;
  postId: TxId;
  parentId: string | undefined;
  type: CommentType;
}

export default function CreateComment(props: Props) {
  const { profileId, postId, parentId, type } = props;

  const dispatch = useAppDispatch();
  const form = useAppSelector((state) => state.ui.commentForm);
  const busy = useAppSelector((state) => state.social.flags.creatingComment);
  const post = useAppSelector((state) => selectPostForId(state, postId));
  const profile = useAppSelector((state) => selectProfileForId(state, profileId));

  const [output, setOutput] = React.useState('');
  const [error, setError] = React.useState('');

  const createCommentMod = { 'createComment--busy': busy };
  const submitMods = { 'createComment__submit--busy': busy };

  const updateForm = (e: any) => {
    switch (e.target.name) {
      default:
        dispatch(updateCommentForm({ [e.target.name]: e.target.value }));
        break;
    }
  };

  const create = async () => {
    Promise.resolve()
      .then(() => setOutput(''))
      .then(() => setError(''))
      .then(() => dispatch(doCommentCreate()))
      .then((res: ICreateCommentResult) => setOutput(JSON.stringify(res?.comment, null, 2)))
      .then(() => dispatch(doPostFetchMine())) // [] Temp, while we are still retrieving comments from Post state
      .catch((err) => setError(err.message));
  };

  React.useEffect(() => {
    dispatch(updateCommentForm({ profileId, postId, parentId, type }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classnames('createComment', createCommentMod)}>
      <div className="createComment__readOnly">
        <label>PostId</label>
        <div>{form.postId}</div>
      </div>

      <div className="createComment__readOnly">
        <label>Parent</label>
        <div>{post.state.comments.find((comment) => comment.id === form.parentId)?.content || '--Top Level--'}</div>
      </div>

      <div className="createComment__readOnly">
        <label>Profile</label>
        <div>@{findTag(profile?.tags, 'Profile-Handle')}</div>
      </div>

      <div className="createComment__readOnly">
        <label>Type</label>
        <div>{form.type}</div>
      </div>

      <textarea name="content" defaultValue={form.content} onChange={updateForm} placeholder="Content" />

      <Button label="➕ Create Comment" onClick={create} className={classnames('createComment__submit', submitMods)} />

      {error && <div className="createComment__error">{error}</div>}

      {output && (
        <div className="createComment__result">
          <pre>{output}</pre>
        </div>
      )}
    </div>
  );
}
