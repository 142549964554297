import { combineReducers } from '@reduxjs/toolkit';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import arweaveSlice from 'store/arweave/slice';
import authSlice from 'store/auth/slice';
import exampleSlice from 'store/example/slice';
import fairSlice from 'store/fair/slice';
import modalSlice from 'store/modal/slice';
import promptSlice from 'store/prompt/slice';
import settingsSlice from 'store/settings/slice';
import socialSlice from 'store/social/slice';
import uiSlice from 'store/ui/slice';
import { createFilter } from 'redux-persist-transform-filter';

// ****************************************************************************
// Persistence configuration
// ****************************************************************************

const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage: localForage, // TODO asyncstorage in mobile?
  stateReconciler: autoMergeLevel2,
  whitelist: ['arweave', 'fair', 'prompt', 'settings', 'ui'],
  transforms: [
    // createFilter('example', ['data']),
    // createBlacklistFilter('example', ['loadedLanguages'])
    createFilter('arweave', ['gatewayId', 'walletType']),
    createFilter('fair', ['modelsById', 'scriptsById', 'operatorsById', 'scriptsForModelId', 'operatorsForScriptId']),
    createFilter('prompt', ['text', 'nImages']),
    createFilter('ui', ['profileForm', 'postForm']),
  ],
};

// ****************************************************************************
// Root reducer
// ****************************************************************************

const rootReducer = combineReducers({
  arweave: arweaveSlice.reducer,
  auth: authSlice.reducer,
  example: exampleSlice.reducer,
  fair: fairSlice.reducer,
  modal: modalSlice.reducer,
  prompt: promptSlice.reducer,
  settings: settingsSlice.reducer,
  social: socialSlice.reducer,
  ui: uiSlice.reducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;

// ****************************************************************************
// ****************************************************************************

const finalReducer = persistReducer<RootReducer>(rootPersistConfig, rootReducer);
export default finalReducer;
