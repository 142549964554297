import './style.scss';

import ImgText from 'assets/icons/text.png';
import ImgAudio from 'assets/icons/audio.svg';
import ImgRandom from 'assets/icons/random.png';
import ImgSticker from 'assets/icons/sticker.png';

export default function PostFilters(props: any) {
  return (
    <div className="postFilters">
      <div className="postFilters__wrapper">
        <div className={`postFilters__type`}>
          <img src={ImgText} />
        </div>
        {/* <div className="postFilters__indicator" /> */}
      </div>
      <div className="postFilters__wrapper">
        <div className={`postFilters__type`}>
          <img src={ImgAudio} />
        </div>
      </div>
      <div className="postFilters__wrapper">
        <div className={`postFilters__type`}>
          <img src={ImgRandom} />
        </div>
      </div>
      <div className="postFilters__wrapper">
        <div className={`postFilters__type`}>
          <img src={ImgSticker} />
        </div>
      </div>
    </div>
  );
}
