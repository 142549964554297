import React from 'react';
import classnames from 'classnames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import promptSlice from 'store/prompt/slice';
import './style.scss';

const { nImagesUpdated } = promptSlice.actions;

interface Props {
  className?: string;
}

function ConfigurationSelect(props: Props) {
  const { className } = props;
  const dispatch = useAppDispatch();
  const nImages = useAppSelector((state) => state.prompt.nImages);

  const Select = () => (
    <select name="nImages" value={nImages} onChange={(e) => dispatch(nImagesUpdated(Number(e.target.value)))}>
      {Array.from({ length: 4 }).map((_, i) => (
        <option key={i} value={i + 1}>
          {i + 1}
        </option>
      ))}
    </select>
  );

  return (
    <div className={classnames(className, 'configuration')}>
      <Select />
    </div>
  );
}

export default ConfigurationSelect;
