import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakeObject } from 'utils/objects';

export interface SettingsState {
  theme: 'dark' | 'light';
  backend: AppBackend;
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState: MakeObject<SettingsState>({
    theme: 'dark',
    backend: 'fair-sdk',
  }),
  reducers: {
    setTheme: (state, action: PayloadAction<'dark' | 'light'>) => {
      state.theme = action.payload;
    },
    setBackend: (state, action: PayloadAction<AppBackend>) => {
      state.backend = action.payload;
    },
  },
});

export default settingsSlice;
