import { selectPromptForId } from 'store/fair/selectors';
import {
  doFetchModels,
  doFetchOperators,
  doFetchPromptsForId,
  doFetchScripts,
  doSendTextPrompt,
} from 'store/fair/thunks';
import promptSlice from 'store/prompt/slice';
import settingsSlice from 'store/settings/slice';
import { suppressToConsole, throwIf } from 'utils/error';
import { findTag } from 'utils/tags';

const MEINA = {
  modelId: '1I1bH43EjHgqgFKnJsGKDI8mok-Le6TIuO5ILAqwK80',
  scriptId: 'CrGd46VKZI25nVN0jGbUKuFPxtp7MjOqsGal4ZigSv8',
  operatorIds: {
    earthof: 'ej5fQorfdYsLR9h2wk45yjaeycoehJgZbEWI6G2RD58',
    fair: 'GqV0c_gUy1eihFFsMc3FwLWCDq5RQq4VEzcrw2VVaOs',
  },
};

/**
 * The Transform Page is now based on urlParams, allowing the user to re-enter
 * any step directly from URL/bookmark. This then means that on entry, the
 * component needs to ensure the states are initialized correctly for ANY step,
 * and that the required data is fetched.
 *
 * @param promptId
 */
export function doFauxInit(promptId: TxId | undefined) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    let state = getState();
    let prompt = selectPromptForId(state, promptId);

    dispatch(settingsSlice.actions.setBackend('earthof_v0.1'));

    if (promptId) {
      if (!prompt) {
        // -- Fetch prompt data
        await dispatch(doFetchPromptsForId([promptId]));
        state = getState();
        prompt = selectPromptForId(state, promptId);
      }

      if (prompt) {
        // -- Restore prompt text if needed
        const text = findTag(prompt?.tags, 'Prompt');
        if (text && text !== state.prompt.text) {
          dispatch(promptSlice.actions.textUpdated(text));
        }
      }
    }
  };
}

export function doFauxStep() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    // --- Validate inputs
    throwIf(!getState().arweave.walletAddress, 'Connect wallet in the profile page.');
    throwIf(!getState().prompt.text, 'Empty prompt');

    // --- Select Earthof
    dispatch(settingsSlice.actions.setBackend('earthof_v0.1'));

    // --- Fetch models (if not already) and pick
    await dispatch(doFetchModels());
    const models = getState().fair.modelsById;
    throwIf(!models || Object.values(models).length === 0, 'No models found');
    throwIf(!models[MEINA.modelId], 'MeinaPastel Anime Illustration model not found');
    dispatch(promptSlice.actions.modelSelected(MEINA.modelId));

    // --- Fetch scripts (if not already) and pick
    await dispatch(doFetchScripts());
    const scripts = getState().fair.scriptsById;
    throwIf(!scripts || Object.values(scripts).length === 0, 'No scripts found');
    throwIf(!scripts[MEINA.scriptId], 'MeinaPastel Anime Illustration script not found');
    dispatch(promptSlice.actions.scriptSelected(MEINA.scriptId));

    // --- Fetch operators (if not already)
    await dispatch(doFetchOperators());
    const operators = getState().fair.operatorsById;
    throwIf(!operators || Object.values(operators).length === 0, 'No operators found');

    // --- Try EO
    try {
      throwIf(!operators[MEINA.operatorIds.earthof], 'EARTHOF operator not found');
      dispatch(promptSlice.actions.operatorSelected(MEINA.operatorIds.earthof));
      return await dispatch(doSendTextPrompt());
    } catch (err) {
      suppressToConsole(err);
    }

    // --- Try FAIR
    throwIf(!operators[MEINA.operatorIds.fair], 'FAIR operator not found');
    dispatch(promptSlice.actions.operatorSelected(MEINA.operatorIds.fair));
    return dispatch(doSendTextPrompt());
  };
}

export function doGetStubPreviewInfo() {
  return (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    return state.prompt.text;
  };
}
