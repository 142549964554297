import { createSlice } from '@reduxjs/toolkit';
import { MakeObject } from 'utils/objects';

export interface AuthState {
  signedIn: boolean; // [] Manual for now; real solution is for redux to subscribe to cookie and find JWT.
}

const authSlice = createSlice({
  name: 'auth',
  initialState: MakeObject<AuthState>({
    signedIn: false,
  }),
  reducers: {
    signedIn: (state) => {
      state.signedIn = true;
    },
    signedOut: (state) => {
      state.signedIn = false;
    },
  },
});

export default authSlice;
