import React from 'react';
import classnames from 'classnames';
import { Menu, MenuButton, MenuItem, MenuList } from 'components/base/menu';
import CreatePost from 'components/testArson/createPost';
import Details from 'components/testArson/details';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import { selectPostIdsForProfileId } from 'store/social/selectors';
import { doPostFetchMine } from 'store/social/thunks';
import uiSlice from 'store/ui/slice';
import { ArsonTag, findTag } from 'utils/tags';
import './style.scss';

export default function MyPosts() {
  const [error, setError] = React.useState<string>('');

  const dispatch = useAppDispatch();
  const fetching = useAppSelector((state) => state.social.flags.fetchingMyPosts);
  const postsById = useAppSelector((state) => state.social.postsById);
  const myPostIds = useAppSelector((state) => selectPostIdsForProfileId(state, state.ui.activeProfileId || ''));
  const activePostId = useAppSelector((state) => state.ui.activePostId);
  const post = postsById[activePostId || ''];
  const mods = { busy: fetching };

  const getDetails = () => {
    if (post) {
      const stateStr = JSON.stringify(post.state, null, 2);
      const tagStr = post.tags.map((tag) => `${tag.name}: ${tag.value}`).join('\n');
      return `Post Id: ${activePostId}\n\n${stateStr}\n\n${tagStr}`;
    } else {
      return 'No post selected';
    }
  };

  const showDetails = () => {
    dispatch(openModal(Details, { text: getDetails() }));
  };

  const fetchPosts = async () => {
    Promise.resolve()
      .then(() => setError(''))
      .then(() => dispatch(doPostFetchMine()))
      .catch((err: any) => setError(err.message));
  };

  const createPost = async () => {
    dispatch(openModal(CreatePost, {}));
  };

  return (
    <div className={classnames('myStuff', mods)}>
      <div className="myStuff__row">
        <select value={activePostId} onChange={(e) => dispatch(uiSlice.actions.setActivePostId(e.target.value))}>
          <option value="">Select ({myPostIds.length})</option>
          {myPostIds.map((id) => (
            <option key={id} value={id}>
              {findTag<ArsonTag>(postsById[id].tags, 'Profile-Title')}
            </option>
          ))}
        </select>

        <div className={classnames('myStuff__viewItem')} title="Show details" onClick={showDetails}>
          ⓘ
        </div>

        <Menu>
          <MenuButton className="myStuff__actionBtn">
            <span>⚙️</span>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={fetchPosts}>🔍 Fetch Posts</MenuItem>
            <MenuItem onClick={createPost}>➕ Create Post</MenuItem>
          </MenuList>
        </Menu>
      </div>

      {error && <div className="myStuff__error">{error}</div>}
    </div>
  );
}
