import React from 'react';
import classnames from 'classnames';
import Button from 'components/base/button';
import Image from 'components/base/image';
import { fetchAccountInfo, getUserHandle } from 'lib/arweaveAccount';
import { generatePromptQueryKey, selectResponsesForPromptId, selectPromptForId } from 'store/fair/selectors';
import fairSlice from 'store/fair/slice';
import { doFetchPromptResponses, doFetchPrompts } from 'store/fair/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { sendAlert } from 'utils/error';
import { findTag } from 'utils/tags';
import './style.scss';

interface Props {
  className?: string;
}

function Conversation(props: Props) {
  const { className } = props;
  const dispatch = useAppDispatch();
  const [status, setStatus] = React.useState<'reload' | 'loadMore' | 'idle'>('idle');
  const bypassStrictMode = React.useRef(false);

  const backend = useAppSelector((state) => state.settings.backend);
  const walletAddress = useAppSelector((state) => state.arweave.walletAddress);
  const scriptId = useAppSelector((state) => state.prompt.scriptId);
  const conversationId = useAppSelector((state) => state.prompt.conversationId);

  const queryKey = generatePromptQueryKey(backend, walletAddress, scriptId, conversationId);
  const cursor = useAppSelector((state) => state.fair.promptsByQuery.cursor[queryKey]);
  const promptIds = useAppSelector((state) => state.fair.promptsByQuery.ids[queryKey]);

  function isFetchAllowed() {
    const conversationValid = backend.startsWith('earthof') || (backend === 'fair-sdk' && conversationId);
    return walletAddress && conversationValid;
  }

  function fetchPrompts() {
    if (isFetchAllowed()) {
      Promise.resolve()
        .then(() => setStatus('loadMore'))
        .then(() => dispatch(doFetchPrompts()))
        .catch(sendAlert)
        .finally(() => setStatus('idle'));
    }
  }

  function reload() {
    if (isFetchAllowed()) {
      Promise.resolve()
        .then(() => setStatus('reload'))
        .then(() => dispatch(fairSlice.actions.clearPromptQuery(queryKey)))
        .then(() => dispatch(doFetchPrompts()))
        .catch(sendAlert)
        .finally(() => setStatus('idle'));
    }
  }

  React.useEffect(() => {
    if (bypassStrictMode.current) {
      bypassStrictMode.current = false;
      return;
    }

    reload();
    bypassStrictMode.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on queryKey change
  }, [queryKey]);

  return (
    <div className={classnames(className, 'chat')}>
      <div className="chat__content">
        <div className="chat__reload">
          {status === 'idle' && <Button label="↻" onClick={reload} />}
          {status !== 'idle' && <span className="blink">Loading...</span>}
        </div>
        {(promptIds || []).map((id) => {
          return (
            <React.Fragment key={id}>
              <div className="chatRow">
                <Request promptId={id} />
                <Results promptId={id} />
              </div>
            </React.Fragment>
          );
        })}
        {cursor && (
          <div className="chat__loadMore">
            {status === 'idle' && <Button label="Load More" onClick={fetchPrompts} />}
            {status !== 'idle' && <span className="blink">Loading...</span>}
          </div>
        )}
      </div>
    </div>
  );
}

export default Conversation;

// ****************************************************************************
// Request
// ****************************************************************************

const getTagString = (node?: TxNode) => {
  return node ? node.id + '\n\n' + node.tags.map((tag) => `  ${tag.name}:  ${tag.value}`).join('\n') : '';
};

const Request = (props: { promptId: TxId }) => {
  const promptsById = useAppSelector((state) => state.fair.promptsById);
  const prompt = promptsById[props.promptId];

  return (
    <div className="chatPrompt" title={getTagString(prompt)}>
      <div className="chatPrompt__text">
        <div className="chatPrompt__timestamp">
          {new Date(Number(findTag(prompt.tags, 'Unix-Time')) * 1000).toLocaleString()}
        </div>
        {findTag(prompt.tags, 'Prompt')}
      </div>
    </div>
  );
};

// ****************************************************************************
// Results
// ****************************************************************************

const Results = (props: { promptId: TxId }) => {
  const dispatch = useAppDispatch();
  const prompt = useAppSelector((state) => selectPromptForId(state, props.promptId));
  const responses = useAppSelector((state) => selectResponsesForPromptId(state, props.promptId));
  const nImages = Number(findTag(prompt.tags, 'N-Images'));
  const pending = responses.length < nImages;
  const stale = Number(findTag(prompt.tags, 'Unix-Time')) < Date.now() / 1000 - 10 * 60;

  const operatorWalletAddress = findTag(prompt?.tags, 'Script-Operator');
  const [operatorHandle, setOperatorHandle] = React.useState('');

  const [fetching, setFetching] = React.useState(false);
  const [lastFetched, setLastFetched] = React.useState<number | string>('-');

  const fetchResponses = () => {
    Promise.resolve()
      .then(() => setFetching(true))
      .then(() => dispatch(doFetchPromptResponses([props.promptId])))
      .then(() => setLastFetched(Date.now()))
      .finally(() => setFetching(false));
  };

  React.useEffect(() => {
    if (pending) {
      fetchResponses();
      if (!stale) {
        const interval = setInterval(fetchResponses, 15000);
        return () => clearInterval(interval);
      }
    }
  }, [pending, stale, props.promptId]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (pending && operatorWalletAddress) {
      Promise.resolve()
        .then(() => fetchAccountInfo(operatorWalletAddress))
        .then((account) => getUserHandle(account))
        .then((handle) => setOperatorHandle(handle || ''))
        .catch(() => setOperatorHandle(''));
    }
  }, [pending, operatorWalletAddress]);

  return (
    <div className="chatResults">
      {responses.map((response) => {
        const tooltip = getTagString(response);
        return <Image src={`ar://${response.id}`} key={response.id} title={tooltip} link />;
      })}
      <div className="chatResults__status" title="click to refresh" onClick={fetchResponses}>
        {fetching && <div className="blink">Fetching</div>}

        {!fetching && pending && (
          <>
            <div>
              Waiting for <span className="chatResults__operator">{operatorHandle || 'operator'}</span>
            </div>
            <div className="chatResults__timestamp">Last checked {`${new Date(lastFetched).toLocaleString()}`}</div>
          </>
        )}
      </div>
    </div>
  );
};
