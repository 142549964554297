import React from 'react';
import classnames from 'classnames';
import { EMPTY } from 'constants/empty';
import { doFetchOperators } from 'store/fair/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import promptSlice from 'store/prompt/slice';
import { balanceStrToNum } from 'utils/number';
import './style.scss';
import { findTag } from 'utils/tags';

const { operatorSelected } = promptSlice.actions;

interface Props {
  className?: string;
}

function OperatorSelect(props: Props) {
  const { className } = props;
  const dispatch = useAppDispatch();
  const scriptId = useAppSelector((state) => state.prompt.scriptId);
  const operatorId = useAppSelector((state) => state.prompt.operatorId);
  const fetching = useAppSelector((state) => state.fair.fetching.operators);
  const operators = useAppSelector((state) => state.fair.operatorsForScriptId[scriptId || ''] || EMPTY.ARR);

  React.useEffect(() => {
    if (scriptId) {
      dispatch(doFetchOperators());
    }
  }, [scriptId, dispatch]);

  const Select = () => (
    <select name="operators" value={operatorId} onChange={(e) => dispatch(operatorSelected(e.target.value))}>
      <option key="none" value="">
        -- Select --
      </option>
      {operators.map((operator) => {
        const cost = balanceStrToNum(operator ? findTag(operator.tags, 'Operator-Fee') : null);
        const costStr = cost ? `${cost / 1000000} $U/item` : '';
        return (
          <option key={operator.id} value={operator.id}>
            {findTag(operator.tags, 'Operator-Name') || '?'} @ {costStr}
          </option>
        );
      })}
    </select>
  );

  // const Cost = (props: any) => {
  //   const operator = operators.find((o) => o.id === operatorId);
  //   const cost = balanceStrToNum(operator ? findTag(operator.tags, 'Operator-Fee') : null);
  //   return cost ? <div className={props.className}>{`Cost: ${cost / 1000000} U/item`}</div> : null;
  // };

  return (
    <div className={classnames(className, 'operators')}>
      {fetching && <span className="blink">Loading...</span>}
      {!fetching && operators.length > 0 && <Select />}
      {/*{!fetching && operators.length > 0 && <Cost className="operators__cost" />}*/}
      {!fetching && operators.length === 0 && <span>- - -</span>}
    </div>
  );
}

export default OperatorSelect;
