import React from 'react';
import classnames from 'classnames';
import { EMPTY } from 'constants/empty';
import { doFetchScripts } from 'store/fair/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import promptSlice from 'store/prompt/slice';
import './style.scss';
import { findTag } from 'utils/tags';

const { scriptSelected } = promptSlice.actions;

interface Props {
  className?: string;
}

function ScriptSelect(props: Props) {
  const { className } = props;
  const dispatch = useAppDispatch();

  const modelId = useAppSelector((state) => state.prompt.modelId);
  const scriptId = useAppSelector((state) => state.prompt.scriptId);
  const loading = useAppSelector((state) => state.fair.fetching.scripts);
  const scripts = useAppSelector((state) => state.fair.scriptsForModelId[modelId || ''] || EMPTY.ARR);

  React.useEffect(() => {
    if (modelId) {
      dispatch(doFetchScripts());
    }
  }, [modelId, dispatch]);

  const Select = () => (
    <select name="scripts" value={scriptId} onChange={(e) => dispatch(scriptSelected(e.target.value))}>
      <option key="none" value="">
        -- Select --
      </option>
      {scripts.map((script) => (
        <option key={script.id} value={script.id}>
          {findTag(script.tags, 'Script-Name') || '?'}
        </option>
      ))}
    </select>
  );

  return (
    <div className={classnames(className, 'scripts')}>
      {loading && <span className="blink">Loading...</span>}
      {!loading && scripts.length > 0 && <Select />}
      {!loading && scripts.length === 0 && <span>- - -</span>}
    </div>
  );
}

export default ScriptSelect;
