import { WarpFactory } from 'warp-contracts';
import { DeployPlugin } from 'warp-contracts-plugin-deploy';

export function createWarpInstance(gatewayId: GatewayId, arweave: any, warpEnv: WarpEnv) {
  switch (gatewayId) {
    case 'arweave.net':
    case 'g8way.io':
      switch (warpEnv) {
        case 'mainnet':
          return WarpFactory.forMainnet().use(new DeployPlugin());
        case 'testnet':
          return WarpFactory.forTestnet().use(new DeployPlugin());
        default:
          throw new Error(`Invalid environment: ${warpEnv}`);
      }
    case 'arlocal.zephyrdev.xyz':
      return WarpFactory.forLocal(443, arweave).use(new DeployPlugin());
    case 'arlocal':
      return WarpFactory.forLocal(1984, arweave).use(new DeployPlugin());
    default:
      throw new Error(`Invalid gateway: ${gatewayId}`);
  }
}
