import React from 'react';
import classnames from 'classnames';
import { Menu, MenuButton, MenuItem, MenuList } from 'components/base/menu';
import CreateProfile from 'components/testArson/createProfile';
import Details from 'components/testArson/details';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import { selectMyProfileIds } from 'store/social/selectors';
import { doProfileFetchMine } from 'store/social/thunks';
import uiSlice from 'store/ui/slice';
import { ArsonTag, findTag } from 'utils/tags';
import { sendAlert } from 'utils/error';
import './style.scss';

const { setActiveProfileId } = uiSlice.actions;

export default function MyProfile() {
  const dispatch = useAppDispatch();
  const fetching = useAppSelector((state) => state.social.flags.fetchingMyProfiles);
  const myProfiles = useAppSelector(selectMyProfileIds);
  const profilesById = useAppSelector((state) => state.social.profilesById);
  const activeProfileId = useAppSelector((state) => state.ui.activeProfileId);
  const activeProfile = useAppSelector((state) => state.social.profilesById[activeProfileId || '']);
  const mods = { busy: fetching };

  const getDetails = () => {
    if (activeProfile) {
      const stateStr = JSON.stringify(activeProfile.state, null, 2);
      const tagStr = activeProfile.tags.map((tag) => `${tag.name}: ${tag.value}`).join('\n');
      return `Profile Id: ${activeProfileId}\n\n${stateStr}\n\n${tagStr}`;
    } else {
      return 'No profile selected';
    }
  };

  const showDetails = () => {
    dispatch(openModal(Details, { text: getDetails() }));
  };

  const fetchProfiles = async () => {
    Promise.resolve()
      .then(() => dispatch(doProfileFetchMine()))
      .catch(sendAlert);
  };

  const createProfile = async () => {
    dispatch(openModal(CreateProfile, {}));
  };

  return (
    <div className={classnames('myStuff', mods)}>
      <div className="myStuff__row">
        <select value={activeProfileId} onChange={(e) => dispatch(setActiveProfileId(e.target.value))}>
          <option value="">Select ({myProfiles.length})</option>
          {myProfiles.map((profileId) => (
            <option key={profileId} value={profileId}>
              {findTag<ArsonTag>(profilesById[profileId].tags, 'Profile-Handle')}
            </option>
          ))}
        </select>

        <div className={classnames('myStuff__viewItem')} title="Show details" onClick={showDetails}>
          ⓘ
        </div>

        <Menu>
          <MenuButton className="myStuff__actionBtn">
            <span>⚙️</span>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={fetchProfiles}>🔍 Find My Profiles</MenuItem>
            <MenuItem onClick={createProfile}>➕ Create Profile</MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
}
