import React from 'react';
import classnames from 'classnames';
import { AR_WALLETS } from 'constants/arweave';
import { IMAGE_URLS } from 'constants/imageUrls';
import { doWalletConnect } from 'store/arweave/thunks';
import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/modal/slice';
import './style.scss';

const LOGO_URLS = {
  arconnect: IMAGE_URLS.WALLET_ARCONNECT,
  'arweave.app': IMAGE_URLS.WALLET_ARWEAVE_APP,
};

interface Props {
  title: string;
}

function WalletList(props: Props) {
  const dispatch = useAppDispatch();
  const [connecting, setConnecting] = React.useState(false);

  // [ ] arweave.app: how to close iframe if user dismisses modal before hitting 'Connect'?
  // [ ] arweave.app: show hint that iframe is up. Sometimes, it might be buried.

  function connectWallet(type: WalletEnum) {
    setConnecting(true);
    dispatch(doWalletConnect(type))
      .then((address) => address && dispatch(closeModal()))
      .finally(() => setConnecting(false));
  }

  function tmp__dismissModal() {
    // Emulate bottom sheet until there is a real component.
    // Clients should need to worry about this.
    dispatch(closeModal());
  }

  return (
    <div className={classnames('wallets', { 'wallets--disabled': connecting })} onClick={tmp__dismissModal}>
      <div className="wallets__header">{props.title}</div>
      <div className="wallets__list">
        {AR_WALLETS.map(({ type }) => (
          <div className="wallets__list-item" key={type} onClick={() => connectWallet(type)}>
            <img src={LOGO_URLS[type]} alt={`${type} wallet logo`} />
            <span>{type}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WalletList;
