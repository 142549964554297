import { Action } from '@reduxjs/toolkit';
import { doFetchPromptsForId } from 'store/fair/thunks';

export const effFetchIncompletePrompts = {
  predicate: (action: Action, state: AppState, prevState: AppState) => {
    return state.fair.pendingPromptIds.length > 0;
  },
  effect: async (action: Action, listenerApi: any) => {
    const { dispatch, getState } = listenerApi;

    listenerApi.unsubscribe();

    while (getState().fair.pendingPromptIds.length > 0) {
      dispatch(doFetchPromptsForId(getState().fair.pendingPromptIds));
      await new Promise((resolve) => setTimeout(resolve, 15000));
    }

    listenerApi.subscribe();
  },
};
