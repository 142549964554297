import React from 'react';
import classnames from 'classnames';
import { Menu, MenuButton, MenuItem, MenuList } from 'components/base/menu';
import CreateComment from 'components/testArson/createComment';
import { IComment } from 'social-sdk/dist/interfaces';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import { selectCommentsForPostId } from 'store/social/selectors';
import { doCommentFetch } from 'store/social/thunks';
import { AppDispatch } from 'store/store';
import { sendAlert } from 'utils/error';
import './style.scss';

const doCreateComment = async (dispatch: AppDispatch, profileId: TxId, postId: TxId, parentId?: TxId) => {
  dispatch(openModal(CreateComment, { profileId, postId, parentId, type: 'text' }));
};

export default function Comments() {
  const dispatch = useAppDispatch();
  const fetchingPosts = useAppSelector((state) => state.social.flags.fetchingMyPosts); // [] tmp; remove when we have a proper state for comments
  const fetching = useAppSelector((state) => state.social.flags.fetchingComments) || fetchingPosts;
  const activeProfileId = useAppSelector((state) => state.ui.activeProfileId);
  const postId = useAppSelector((state) => state.ui.activePostId);
  const comments: IComment[] = useAppSelector((state) => selectCommentsForPostId(state, postId));
  const topLevelComments = React.useMemo(() => comments.filter((comment) => !comment.parentId), [comments]);

  const fetchComments = async () => {
    Promise.resolve()
      .then(() => dispatch(doCommentFetch({ postId: postId! })))
      .catch(sendAlert);
  };

  const createComment = () => doCreateComment(dispatch, activeProfileId!, postId!);

  return (
    <div className={classnames('myStuff', { busy: fetching })}>
      <div className="myStuff__row">
        <div />
        <Menu>
          <MenuButton className="myStuff__actionBtn">
            <span>⚙️</span>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={fetchComments}>🔍 Fetch comments</MenuItem>
            <MenuItem onClick={createComment}>➕ Create comment</MenuItem>
          </MenuList>
        </Menu>
      </div>
      <div>
        {topLevelComments.map((comment) => (
          <Comment postId={postId!} key={comment.id} comment={comment} comments={comments} />
        ))}
      </div>
    </div>
  );
}

interface CommentProps {
  postId: TxId;
  comment: IComment;
  comments: IComment[];
  level?: number;
}

/**
 * Crude implementation.
 * @param props
 * @constructor
 */
const Comment = (props: CommentProps) => {
  const dispatch = useAppDispatch();
  const activeProfileId = useAppSelector((state) => state.ui.activeProfileId);
  const { postId, comment, comments, level = 0 } = props;
  const childComments = React.useMemo(() => comments.filter((c) => c.parentId === comment.id), [comments, comment.id]);
  const createComment = () => doCreateComment(dispatch, activeProfileId!, postId, comment.id);

  return (
    <>
      <div className="cmt">
        {level > 0 && <div className="cmt__indent" style={{ width: `${level * 20}px` }}></div>}
        <div className={classnames('cmd__content', { 'cmd__content--child': level > 0 })} onClick={createComment}>
          {comment.content}
        </div>
      </div>

      {childComments.map((childComment) => (
        <Comment postId={postId} key={childComment.id} comment={childComment} comments={comments} level={level + 1} />
      ))}
    </>
  );
};
