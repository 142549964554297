import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import UserProfile from './userProfile';
import UserSettings from './userSettings';
import './style.scss';

import ImgArrowLeft from 'assets/icons/arrowLeft.png';
import ImgSettings from 'assets/icons/settings.png';

export default function Profile() {
  const [showSettings, setShowSettings] = useState(false);
  const walletAddress = useAppSelector((state: AppState) => state.arweave.walletAddress);

  useSignIn(walletAddress);

  return (
    <>
      <div className="profilePage">
        <div className="profilePage__header">
          <div
            className="profilePage__header__back"
            onClick={() => (!showSettings ? window.history.back() : setShowSettings(false))}
          >
            <img src={ImgArrowLeft} />
          </div>
          {!showSettings && (
            <div className="profilePage__header__settings" onClick={() => setShowSettings(true)}>
              <img src={ImgSettings} />
            </div>
          )}
        </div>
        <div className={`profilePage__pages ${showSettings ? 'profilePage__pages--settings' : ''}`}>
          <div className="profilePage__pages__page">
            <UserProfile />
          </div>
          <div className="profilePage__pages__page">
            <UserSettings />
          </div>
        </div>
      </div>
    </>
  );
}

// [] TMP. We'll need to add authenticated routes, then can remove this.
function useSignIn(walletAddress: string | null) {
  const hasNavigated = React.useRef(false); // negates Strict Mode double render
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!walletAddress && !hasNavigated.current) {
      navigate('/signIn');
      hasNavigated.current = true;
    }
  }, [navigate, walletAddress]);
}
