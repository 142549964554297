import React from 'react';
import Button from 'components/base/button';
import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'store/modal/slice';
import './style.scss';

export interface DialogConfirmProps {
  title: string | React.ReactNode;
  body?: string | React.ReactNode;
  okText: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

const DialogConfirm = (props: DialogConfirmProps) => {
  const noOp = () => {};
  const dispatch = useAppDispatch();
  const { title, body, okText, cancelText, onOk = noOp, onCancel = noOp } = props;

  const handleOk = () => {
    onOk();
    dispatch(closeModal());
  };

  const handleCancel = () => {
    onCancel();
    dispatch(closeModal());
  };

  return (
    <div className="dialogConfirm">
      <div className="dialogConfirm__title">{title}</div>
      {body && <div className="dialogConfirm__body">{body}</div>}
      <div className="dialogConfirm__action">
        {okText && <Button className="dialogConfirm__ok" label={okText} onClick={handleOk} />}
        {cancelText && <Button className="dialogConfirm__cancel" label={cancelText} onClick={handleCancel} />}
      </div>
    </div>
  );
};

export default DialogConfirm;
