export const WALLET_PERMISSIONS = [
  'ACCESS_ADDRESS',
  'ACCESS_ALL_ADDRESSES', // https://github.com/arconnectio/ArConnect/issues/64
  'ACCESS_PUBLIC_KEY',
  'SIGN_TRANSACTION',
  'DISPATCH',
  'SIGNATURE',
  'ENCRYPT',
  'DECRYPT',
];

export const DEFAULT_AR_GATEWAY_HOST = 'g8way.io';

// ***************************************************************************
// ***************************************************************************

type ArWallets = {
  type: WalletEnum;
};

export const AR_WALLETS: ArWallets[] = [{ type: 'arconnect' }, { type: 'arweave.app' }];

// ***************************************************************************
// ***************************************************************************

export const GATEWAYS: Gateways = {
  'arweave.net': {
    host: 'arweave.net',
    port: 443,
    protocol: 'https',
    advanced: false,
  },
  'g8way.io': {
    host: 'g8way.io',
    port: 443,
    protocol: 'https',
    advanced: false,
  },
  'ar-io': {
    host: 'ar-io.net',
    port: 443,
    protocol: 'https',
    advanced: false,
  },
  'arlocal.zephyrdev.xyz': {
    host: 'arlocal.zephyrdev.xyz',
    port: 443,
    protocol: 'https',
    advanced: false,
  },
  arlocal: {
    host: 'localhost',
    port: 1984,
    protocol: 'http',
    advanced: false,
  },
};
