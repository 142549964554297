// import { useState } from 'react';
// import Remix from 'modals/remix';
import { useAppDispatch } from 'store/hooks';
import { openModal } from 'store/modal/slice';
import Remix from 'modals/remix';

import { ReactComponent as ImgHeart } from 'assets/icons/heart.svg';
import { ReactComponent as ImgHeartEmpty } from 'assets/icons/heart_empty.svg';
import { ReactComponent as ImgTransform } from 'assets/icons/transform.svg';
import ImgRemix from 'assets/icons/remix.svg';
import { getMockUsers } from 'constants/mockdata';
import './style.scss';

export default function Comment(props: any) {
  const dispatch = useAppDispatch();
  const users = getMockUsers();
  const user = users[Math.floor(Math.random() * 4)];
  // const [isRemixing, setIsRemixing] = useState(false)

  const length = Math.floor(Math.random() * 200) + 1;
  const comment =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'.substring(
      0,
      length
    );
  const likes = Math.floor(Math.random() * 200) + 1;
  const isImage = Math.random() < 0.5;
  const image = Math.floor(Math.random() * 20 + 1);
  const liked = Math.random() < 0.5;

  function remixComment() {
    dispatch(openModal(Remix, { post: { prompt: comment }, isRemixing: true }));
  }

  return (
    <div className="comment">
      <div className="comment__avatar">
        <img src={user.avatar} />
      </div>
      <div className="comment__text">
        <div className="comment__text__header">
          <div className="comment__text__username">{user.name}</div>
          <div className="comment__text__timestamp">0h ago</div>
        </div>
        <div className="comment__comment">
          {isImage ? (
            comment
          ) : (
            <div
              className="comment__remix__wrapper"
              style={{
                backgroundImage: `url('https://github.com/NewEstablishment/eo-resources/blob/main/discover/${image}.jpeg?raw=true')`,
              }}
            >
              <div
                className="comment__remix"
                style={{
                  backgroundImage: `url('https://github.com/NewEstablishment/eo-resources/blob/main/discover/${image}.jpeg?raw=true')`,
                }}
              ></div>
              <img src={`https://github.com/NewEstablishment/eo-resources/blob/main/discover/${image}.jpeg?raw=true`} />
              <div className="comment__remix__text">
                <p>{comment.substring(0, length / 2 + 1)}</p>
              </div>
            </div>
          )}
        </div>
        <div className="comment__actions">
          <div className="comment__action">
            <ImgTransform />
            Reply
          </div>
          <div className="comment__action" onClick={() => remixComment()}>
            <img src={ImgRemix} />
            Remix
          </div>
        </div>
      </div>
      <div className={`${liked ? 'comment__liked' : 'comment__like'}`}>
        {liked ? <ImgHeart /> : <ImgHeartEmpty />}
        <div>{likes}</div>
      </div>
    </div>
  );
}
