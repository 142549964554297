import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { DEFAULT_AR_GATEWAY_HOST, GATEWAYS } from 'constants/arweave';

const mapLink = new ApolloLink((operation, forward) =>
  forward(operation).map((result) => {
    if (operation.operationName === 'history') {
      const nested = result.data as any; //  { owned: ITransactions; received: ITransactions };
      const data = { owned: nested.owned.edges, received: nested.received.edges };
      const parsedResult = { ...result, data };
      return parsedResult;
    } else {
      return result;
    }
  })
);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: split(
    () => true, // default to arweave net
    from([mapLink, new HttpLink({ uri: `https://${DEFAULT_AR_GATEWAY_HOST}/graphql` })]),
    from([mapLink, new HttpLink({ uri: 'https://arweave.dev/graphql' })])
  ),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
});

export const updateApolloClient = async (gatewayId: GatewayId) => {
  const gateway = GATEWAYS[gatewayId];

  apolloClient.setLink(
    split(
      () => true, // default to arweave net
      from([mapLink, new HttpLink({ uri: `${gateway.protocol}://${gateway.host}:${gateway.port}/graphql` })]),
      from([mapLink, new HttpLink({ uri: 'https://arweave.dev/graphql' })])
    )
  );

  await apolloClient.clearStore();
};
