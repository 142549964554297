import { getSocialSdkInstance, initSocialSdk, resetSocialSdk } from 'lib/socialSdk';
import { TOP_LEVEL } from 'social-sdk/dist/common/constants';
import { createTagFilter } from 'social-sdk/dist/common/graphql';
import { profileTags } from 'social-sdk/dist/common/tags';
import {
  ICreatePostOptions,
  ICreateProfileOptions,
  IPostResult,
  IProfileResult,
  ISdkProfileResult,
} from 'social-sdk/dist/interfaces/api';
import { FIND_PROFILES_FOR_WALLET } from 'store/social/graphql';
import socialSlice from 'store/social/slice';
import { throwIf } from 'utils/error';
import { MakeObject } from 'utils/objects';

export function doInitSocialSdk() {
  return (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const { walletAddress, socialGatewayId, warpEnvironment } = state.arweave;
    throwIf(!walletAddress || !window.arweaveWallet, 'doInitSocialSdk: wallet not connected');
    initSocialSdk(walletAddress!, socialGatewayId, warpEnvironment);
  };
}

export function doResetSocialSdk() {
  return (dispatch: AppDispatch, getState: GetState) => {
    resetSocialSdk();
  };
}

export function doProfileCreate() {
  return async (dispatch: AppDispatch, getState: GetState): Promise<ISdkProfileResult> => {
    dispatch(socialSlice.actions.setFlagOn('creatingProfile'));

    try {
      const state = getState();
      const form = state.ui.profileForm;

      const { walletAddress } = state.arweave;
      throwIf(!walletAddress, 'Wallet not connected');

      const social = getSocialSdkInstance()!;
      throwIf(!social, 'Social SDK not initialized');

      const topics = (form.topics || []).filter((t) => t !== '');

      throwIf(!form.handle, 'Handle is required');
      throwIf(!form.title, 'Title is required');
      throwIf(!form.description, 'Description is required');
      throwIf(topics.length === 0, 'Topics are required');

      const result = await social.api.profile.createSocialProfile({
        handle: form.handle,
        title: form.title,
        topics: form.topics,
        description: form.description,
        customTags: [],
      } as ICreateProfileOptions);

      return result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(socialSlice.actions.setFlagOff('creatingProfile'));
    }
  };
}

export function doProfileFetchMine() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(socialSlice.actions.setFlagOn('fetchingMyProfiles'));

    try {
      const state = getState();
      const { walletAddress } = state.arweave;
      throwIf(!walletAddress, 'Wallet not connected');

      const social = getSocialSdkInstance()!;
      throwIf(!social, 'Social SDK not initialized');

      const profiles = await proposal__fetchProfilesForWallet(social, walletAddress!);
      dispatch(socialSlice.actions.profilesFetched(profiles));
    } catch (err) {
      console.error('doFetchMyProfiles:', err);
      throw err;
    } finally {
      dispatch(socialSlice.actions.setFlagOff('fetchingMyProfiles'));
    }
  };

  async function proposal__fetchProfilesForWallet(social: any, walletAddress: string) {
    const query = await social.api.apolloClient.query({
      query: FIND_PROFILES_FOR_WALLET,
      variables: {
        address: walletAddress,
        tags: profileTags.map((t) => createTagFilter(t)),
        first: 100,
      },
      fetchPolicy: 'no-cache',
    });

    const edges = query?.data?.transactions?.edges || [];

    const profilePromises = edges.map(async (edge: TxEdge) => {
      try {
        const profileState = await social.api.profile.getProfileStateByTxid(edge.node.id);
        const walletStillOwnsProfile = profileState?.owner === walletAddress;

        if (walletStillOwnsProfile) {
          return {
            [edge.node.id]: {
              txid: edge.node.id,
              state: profileState,
              txOwner: edge.node.owner.address,
              tags: edge.node.tags,
            },
          };
        }
      } catch (err: any) {
        console.warn('proposal__fetchProfilesForWallet:', err); // Just be silent?
        return {};
      }
    });

    const profileResults = await Promise.all(profilePromises);
    const profiles: { [key: TxId]: IProfileResult } = Object.assign({}, ...profileResults);
    return profiles;
  }
}

export function doPostCreate() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(socialSlice.actions.setFlagOn('creatingPost'));

    try {
      const state = getState();
      const { walletAddress } = state.arweave;
      const { postForm, activeProfileId } = state.ui;
      const activeProfile = state.social.profilesById[activeProfileId!];
      const contentTxids = (postForm.contentTxids || []).filter((txid) => txid !== '');

      throwIf(!walletAddress, 'Wallet not connected');
      throwIf(!postForm.title, 'Title is required');
      throwIf(contentTxids.length === 0, 'At least 1 atomic ass required');
      throwIf(!activeProfileId, 'Active profile not set');
      throwIf(!activeProfile, 'Active profile not found');

      const social = getSocialSdkInstance()!;
      throwIf(!social, 'Social SDK not initialized');

      const result = await social.api.post.createPost(
        MakeObject<ICreatePostOptions>({
          // -- Required
          title: postForm.title!,
          profileId: activeProfile.txid,
          contentTxids: contentTxids,
          // -- Optional
          ...(postForm.description ? { description: postForm.description } : {}),
          ...(postForm.longDescription ? { longDescription: postForm.longDescription } : {}),
          ...(postForm.thumbnailUrl ? { thumbnail: { type: 'url', value: postForm.thumbnailUrl } } : {}),
          customTags: postForm.customTags || [], // this should be optional, but SDK TS not updated yet
        })
      );

      if (result.posts) {
        dispatch(socialSlice.actions.postsFetched(result.posts));
      }

      return result.posts; // convenience
    } catch (err) {
      throw err;
    } finally {
      dispatch(socialSlice.actions.setFlagOff('creatingPost'));
    }
  };
}

export function doPostFetchMine() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(socialSlice.actions.setFlagOn('fetchingMyPosts'));

    try {
      const state = getState();

      const { walletAddress } = state.arweave;
      const { activeProfileId } = state.ui;
      throwIf(!walletAddress, 'Wallet not connected');
      throwIf(!activeProfileId, 'Active profile not set');

      const social = getSocialSdkInstance()!;
      throwIf(!social, 'Social SDK not initialized');

      type Result = TxQueryResult | undefined;
      const result: Result = await social.api.fetchPostsByProfileId(activeProfileId!);
      const edges = result?.transactions?.edges || [];

      const posts: { [key: TxId]: IPostResult } = {};

      for (let i = 0; i < edges.length; ++i) {
        const edge = edges[i];
        const postState = await social.api.post.getState(edge.node.id);
        const walletStillOwnsPost = postState?.owner === walletAddress;

        if (walletStillOwnsPost) {
          posts[edge.node.id] = {
            txid: edge.node.id,
            state: postState,
            txOwner: edge.node.owner.address,
            tags: edge.node.tags,
          };
        }
      }

      dispatch(socialSlice.actions.postsFetched(posts));
    } catch (err) {
      throw err;
    } finally {
      dispatch(socialSlice.actions.setFlagOff('fetchingMyPosts'));
    }
  };
}

export function doCommentCreate() {
  return async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(socialSlice.actions.setFlagOn('creatingComment'));

    try {
      const state = getState();
      const { walletAddress } = state.arweave;
      const form = state.ui.commentForm;

      throwIf(!walletAddress, 'Wallet not connected');
      throwIf(!form.postId, 'Post id is required');
      throwIf(!form.profileId, 'Profile id is required');
      throwIf(!form.type, 'Comment type is required');
      throwIf(!form.content, 'Comment content is required');
      throwIf(!!form.parentId && form.parentId.length !== 43, 'Invalid parent comment id');

      const social = getSocialSdkInstance()!;
      throwIf(!social, 'Social SDK not initialized');

      const result = await social.api.post.createComment(form.postId, {
        profileId: form.profileId,
        type: form.type!,
        content: form.content!,
        parentId: form.parentId,
      });

      return result;
    } catch (err) {
      throw err;
    } finally {
      dispatch(socialSlice.actions.setFlagOff('creatingComment'));
    }
  };
}

type CommentFetchArgs = {
  postId: TxId;
  parentCommentId?: TxId;
};

export function doCommentFetch(args: CommentFetchArgs) {
  return async (dispatch: AppDispatch, getState: GetState) => {
    dispatch(socialSlice.actions.setFlagOn('fetchingComments'));
    const { postId, parentCommentId } = args;

    try {
      const state = getState();

      const { walletAddress } = state.arweave;
      throwIf(!walletAddress, 'Wallet not connected');

      const social = getSocialSdkInstance()!;
      throwIf(!social, 'Social SDK not initialized');

      /*const comments =*/ await social.api.post.viewComments(postId, {
        parentId: parentCommentId || TOP_LEVEL,
      });

      // dispatch(socialSlice.actions.commentsFetched(comments));
      // ^ [] Still figuring out the comments structure in Store.
      //      Meanwhile, just select comments from the Post's state
      //      since we are still retrieving and storing that.
    } catch (err) {
      throw err;
    } finally {
      dispatch(socialSlice.actions.setFlagOff('fetchingComments'));
    }
  };
}
