import { useState, useEffect } from 'react';
import './style.scss';

const PostProgress = (props: any) => {
  const { stop = 100 } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === stop) {
          clearInterval(timer);
        }
        return oldProgress + 1;
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [stop]);

  return (
    <div className="transformPage__loading">
      <div className="bar" style={{ width: `${progress}%` }} />
      <div className="position" />
    </div>
  );
};

export default PostProgress;
