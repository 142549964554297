import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_AR_GATEWAY_HOST } from 'constants/arweave';
import { MakeObject } from 'utils/objects';

interface ArweaveState {
  walletAddress: string | null;
  walletType: WalletEnum | null;
  walletBalance: { winston?: string; winstonArson?: string; u?: string };
  arProfile: ArAccount | null;
  currencyBalances: CurrencyBalancesType | null;
  gatewayId: GatewayId;
  socialGatewayId: GatewayId;
  warpEnvironment: WarpEnv;
}

type BalanceType = keyof ArweaveState['walletBalance'];

const arweaveSlice = createSlice({
  name: 'arweave',
  initialState: MakeObject<ArweaveState>({
    walletAddress: null,
    walletType: null,
    walletBalance: {},
    arProfile: null,
    currencyBalances: null,
    gatewayId: DEFAULT_AR_GATEWAY_HOST,
    socialGatewayId: 'arlocal.zephyrdev.xyz',
    warpEnvironment: 'testnet',
  }),
  reducers: {
    setWalletInfo: (state, action: { payload: WalletInfo }) => {
      state.walletType = action.payload.type;
      state.walletAddress = action.payload.address;
    },
    setAccountInfo: (state, action: { payload: ArAccount }) => {
      state.arProfile = action.payload;
    },
    clearAccountInfo: (state) => {
      state.arProfile = null;
    },
    setGatewayId: (state, action: PayloadAction<GatewayId>) => {
      state.gatewayId = action.payload;
    },
    setSocialGatewayId: (state, action: PayloadAction<GatewayId>) => {
      state.socialGatewayId = action.payload;
    },
    balanceUpdated: (state, action: PayloadAction<{ currency: BalanceType; value: string | null }[]>) => {
      action.payload.forEach(({ currency, value }) => {
        if (value) {
          state.walletBalance[currency] = value;
        } else {
          delete state.walletBalance[currency];
        }
      });
    },
  },
  extraReducers: (builder) => {},
});

// Note: @see ./effects.ts

export default arweaveSlice;
