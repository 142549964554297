import React from 'react';
import classnames from 'classnames';
import { getMainArweaveInstance } from 'lib/arweave';
import { doWalletBalance } from 'store/arweave/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './style.scss';

interface Props {
  type?: 'main' | 'arson';
  className?: string;
}

const DATA = {
  main: {
    label: 'Main',
    stateName: 'gatewayId',
    balanceName: 'winston',
  },
  arson: {
    label: 'Arson',
    stateName: 'socialGatewayId',
    balanceName: 'winstonArson',
  },
};

function WalletBalance(props: Props) {
  const { type = 'main', className } = props;
  const { stateName, balanceName } = DATA[type];
  const dispatch = useAppDispatch();

  // @ts-ignore
  const gatewayId = useAppSelector((state) => state.arweave[stateName]);
  const isArlocal = gatewayId.startsWith('arlocal');

  const fetching = useAppSelector((state) => state.ui.fetchingBalance);
  // @ts-ignore
  const winston = useAppSelector((state) => state.arweave.walletBalance[balanceName]);
  const u = useAppSelector((state) => state.arweave.walletBalance.u);

  const arStr = isNaN(Number(winston)) ? '---' : getMainArweaveInstance().ar.winstonToAr(winston!);
  const uStr = isNaN(Number(u)) ? '---' : u;

  const mods = { 'wallet-balance--updating': fetching };

  function update() {
    dispatch(doWalletBalance());
  }

  return (
    <div className={classnames(className, 'wallet-balance', mods)} onClick={update}>
      <div className="wallet-balance__entries">
        <div>
          <span>{arStr}</span>
          <label>AR</label>
        </div>
        {!isArlocal && (
          <div>
            <span>{uStr}</span>
            <label>$U</label>
          </div>
        )}
      </div>
    </div>
  );
}

export default WalletBalance;
