import React from 'react';
import classnames from 'classnames';
import './style.scss';

interface Props {
  title?: string;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function TestRow(props: Props) {
  const { title, disabled, children, className } = props;

  return (
    <div className={classnames(className, 'testRow')}>
      <div className="testRow__title">
        {title}
      </div>
      <div className={classnames('testRow__content', { 'testRow__content--disabled': disabled })}>
        {children}
      </div>
    </div>
  );
}
