/**
 * Enhancements over <img>:
 * - Accepts arweave protocol `ar://<txid>` as `src` input.
 *     - The gateway is hardcoded for now, but can be tied to an app state.
 */

import React, { HTMLProps } from 'react';
import { useAppSelector } from 'store/hooks';
import { arToHttp } from 'utils/url';

interface Props extends HTMLProps<HTMLImageElement> {
  className?: string;
  link?: boolean;
}

const Image = React.forwardRef(function Image(props: Props, ref: React.Ref<any>) {
  const { className, link, ...imgProps } = props;
  const gatewayId = useAppSelector((state) => state.arweave.gatewayId);

  return !link ? (
    <img ref={ref} className={className} {...imgProps} src={arToHttp(imgProps.src, gatewayId)} />
  ) : (
    <a href={arToHttp(imgProps.src, gatewayId)} target="_blank" rel="noreferrer">
      <img ref={ref} className={className} {...imgProps} src={arToHttp(imgProps.src, gatewayId)} />
    </a>
  );
});

export default Image;
