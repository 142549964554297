import React, { HTMLProps } from 'react';
import { useButton, UseButtonParameters } from '@mui/base';
import classnames from 'classnames';
import './style.scss';

// [ ] Variants: text | contained
// [ ] Icons: Left and right

interface Props extends HTMLProps<HTMLButtonElement> {
  label: string;
  focusableWhenDisabled?: boolean; // If true, allows a disabled button to receive focus.
  // Should be used whenever the disabled Button needs to be read by screen readers.
  onFocusVisible?: React.FocusEventHandler;
  className?: string | undefined;
}

const Button = React.forwardRef(function Button(props: Props, ref: React.Ref<any>) {
  const { label, disabled, className } = props;
  const { active, focusVisible, getRootProps } = useButton({ ...props, rootRef: ref } as UseButtonParameters);

  const classes = {
    active: active,
    disabled: disabled,
    'focus-visible': focusVisible,
  };

  return (
    <button {...getRootProps()} className={classnames(className, 'base-button', classes)}>
      {label}
    </button>
  );
});

export default Button;
