import React from 'react';
import { Link } from 'react-router-dom';
import ImgPlay from 'assets/icons/play.svg';
import './style.scss';

export default function TilePreview(props: any) {
  const { tile, large, height } = props;

  if (!tile) return null;

  const randomNumber = (Math.random() * 10).toFixed(1);
  const blurhash =
    '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';

  return (
    <Link key={tile.id} to={`/post/${tile.id}`}>
      <div className="tilePreview">
        <img
          className={`tilePreview__tile ${large && 'tilePreview__tile--large'} ${
            height && 'tilePreview__tile--height'
          }`}
          src={`https://github.com/NewEstablishment/eo-resources/blob/main/discover/${tile.id}.jpeg?raw=true`}
          placeholder={blurhash}
        />
        <div className="tilePreview__meta">
          <img src={ImgPlay} />
          <span>{randomNumber}k</span>
        </div>
      </div>
    </Link>
  );
}
