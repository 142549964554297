import React from 'react';
import classnames from 'classnames';
import Button from 'components/base/button';
import { GATEWAYS } from 'constants/arweave';
import { createArweaveInstance } from 'lib/arweave';
import arweaveSlice from 'store/arweave/slice';
import { doWalletBalance } from 'store/arweave/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { throwIf } from 'utils/error';
import './style.scss';

interface Props {
  type: 'main' | 'arson';
  className?: string;
}

const DATA = {
  main: {
    label: 'Main Gateway',
    stateName: 'gatewayId',
    gatewayIds: Object.keys(GATEWAYS),
    action: arweaveSlice.actions.setGatewayId,
  },
  arson: {
    label: 'Arson Gateway',
    stateName: 'socialGatewayId',
    gatewayIds: ['arlocal.zephyrdev.xyz', 'arlocal'],
    action: arweaveSlice.actions.setSocialGatewayId,
  },
};

function GatewaySelect(props: Props) {
  const { type, className } = props;
  const { stateName, gatewayIds, action } = DATA[type];

  // @ts-ignore
  const gatewayId = useAppSelector((state) => state.arweave[stateName]);
  const isArlocalVariant = gatewayId.startsWith('arlocal');
  const walletAddress = useAppSelector((state) => state.arweave.walletAddress);
  const dispatch = useAppDispatch();

  const MintToken = () => {
    const handleClick = async () => {
      try {
        throwIf(!walletAddress, 'Wallet not connected');
        throwIf(!isArlocalVariant, 'Cannot mint on this gateway');

        const arlocal = createArweaveInstance(gatewayId);
        const tokens = arlocal.ar.arToWinston('1');

        const log = [];
        log.push(await arlocal.api.get(`mint/${walletAddress}/${tokens}`));
        log.push(await arlocal.api.get('mine'));
        alert(JSON.stringify(log));

        await dispatch(doWalletBalance());
      } catch (err: any) {
        alert(err.message);
      }
    };
    return <Button label="+AR" onClick={handleClick} />;
  };

  function changeGateway(gatewayId: any) {
    dispatch(action(gatewayId));
  }

  return (
    <div className={classnames(className, 'gateway')}>
      <div className="gateway__item">
        <div className="flexRow">
          <select name="gateway" value={gatewayId} onChange={(e) => changeGateway(e.target.value)}>
            {gatewayIds.map((id) => (
              <option key={id} value={id}>
                {id}
              </option>
            ))}
          </select>
          {gatewayId.startsWith('arlocal') && <MintToken />}
        </div>
      </div>
    </div>
  );
}

export default GatewaySelect;
