import { useState } from 'react';
import TilePreview from 'components/tilePreview';
import Conversation from 'components/conversation';
import './style.scss';

import { getMockEntries, shuffle } from 'constants/mockdata';

import { ReactComponent as ImgGrid } from 'assets/icons/grid.svg';
import { ReactComponent as ImgLiked } from 'assets/icons/liked.svg';
import { ReactComponent as ImgTransform } from 'assets/icons/transform.svg';

export default function UserGallery() {
  const MOCK_ENTRIES = shuffle(getMockEntries(50));
  const [activeTab, setActiveTab] = useState('posts');

  return (
    <div className="userGallery">
      <div className="userGallery__tabs">
        <div className="userGallery__tabSpacer" />
        <div
          className={`userGallery__tab ${activeTab === 'posts' ? 'userGallery__tab--active' : ''}`}
          onClick={() => setActiveTab('posts')}
        >
          <ImgGrid />
        </div>
        <div className="userGallery__tabSpacer" />
        <div
          className={`userGallery__tab ${activeTab === 'liked' ? 'userGallery__tab--active' : ''}`}
          onClick={() => setActiveTab('liked')}
        >
          <ImgLiked />
        </div>
        <div className="userGallery__tabSpacer" />
        <div
          className={`userGallery__tab ${activeTab === 'history' ? 'userGallery__tab--active' : ''}`}
          onClick={() => setActiveTab('history')}
        >
          <ImgTransform />
        </div>
        <div className="userGallery__tabSpacer" />
      </div>
      {activeTab !== 'history' ? (
        <div className="userGallery__grid">
          <div className="userGallery__grid__row">
            <TilePreview tile={MOCK_ENTRIES[13]} />
            <TilePreview tile={MOCK_ENTRIES[12]} />
            <TilePreview tile={MOCK_ENTRIES[11]} />
          </div>
          <div className="userGallery__grid__row">
            <TilePreview tile={MOCK_ENTRIES[10]} />
            <TilePreview tile={MOCK_ENTRIES[9]} />
            <TilePreview tile={MOCK_ENTRIES[8]} />
          </div>
          <div className="userGallery__grid__row">
            <TilePreview tile={MOCK_ENTRIES[7]} />
            <TilePreview tile={MOCK_ENTRIES[6]} />
            <TilePreview tile={MOCK_ENTRIES[5]} />
          </div>
          <div className="userGallery__grid__row">
            <TilePreview tile={MOCK_ENTRIES[4]} />
            <TilePreview tile={MOCK_ENTRIES[3]} />
            <TilePreview tile={MOCK_ENTRIES[2]} />
          </div>
          <div className="userGallery__grid__row">
            <TilePreview tile={MOCK_ENTRIES[1]} />
            <TilePreview tile={MOCK_ENTRIES[0]} />
            <TilePreview tile={MOCK_ENTRIES[7]} />
          </div>
        </div>
      ) : (
        <Conversation />
      )}
    </div>
  );
}
