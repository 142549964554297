import React from 'react';
import classnames from 'classnames';
import GatewaySelect from 'components/gatewaySelect';
import TestRow from 'components/testRow';

import UserMenu from 'components/userMenu';
import WalletBalance from 'components/walletBalance';
import { useAppSelector } from 'store/hooks';
import './style.scss';

import Comments from './comments';
import MyProfile from './myProfile';
import MyPosts from './myPosts';

export default function TestArson() {
  const activeProfileId = useAppSelector((state) => state.ui.activeProfileId);
  const activePostId = useAppSelector((state) => state.ui.activePostId);

  return (
    <div className={classnames('arson', {})}>
      <div className="flexRow">
        <h1>🔥Arson</h1>
        <UserMenu />
      </div>

      <TestRow title="Main">
        <GatewaySelect type="main" />
        <WalletBalance type="main" className="arson__balance" />
      </TestRow>

      <TestRow title="Arson">
        <GatewaySelect type="arson" />
        <WalletBalance type="arson" className="arson__balance" />
      </TestRow>

      <TestRow title="Profile">
        <MyProfile />
      </TestRow>

      <TestRow title="Post" disabled={!activeProfileId}>
        <MyPosts />
      </TestRow>

      <TestRow title="Comment" disabled={!activePostId}>
        <Comments />
      </TestRow>
    </div>
  );
}
