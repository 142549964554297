import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import effectors from 'store/effectors';
import reducer from 'store/reducers';
import serializableCheck from 'store/serializableCheck';

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck }).prepend(effectors.middleware),
});

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.store = store;
}

export const persistor = persistStore(store);

export type GetState = typeof store.getState;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
